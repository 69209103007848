import React, { useState } from "react";

import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";

const ModalPartner = ({ open, setOpen }) => {
  const [data, setData] = useState(
    "https://admin.falconxx.in/#/partner_registration"
  ); // replace '' with whatever data you want to copy
  const [text, setText] = useState("");
  const copyToClipboard = () => {
    navigator.clipboard.writeText(data);
    setText("Copied");
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Partner Registration Form
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>

          <div class="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-9">
                  <Form.Input
                    type="text"
                    style={{ width: "100%" }}
                    value={data}
                    onChange={(e) => setData(e.target.value)}
                  />
                </div>
                <div className="col-lg-3">
                  <Button onClick={copyToClipboard}>{text || "Copy"}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPartner;
