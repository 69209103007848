import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import { db, auth } from "../../Firebase-config";
import AdditionalBtns from "./AdditionalBtns";
import ModalFile from "../ModalFile";
import { onSnapshot } from "firebase/firestore";
import ModalApprove from "./ModalRequestEdit";
import ModalAdd from "../ModalAdd";
import ModalComment from "../ModalComment";
import ModalDelete from "./ModalDeleteRequest";
function Requests() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [requestP, setRequest] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  // getting Requests projects from firestore collection and updating the state

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("name");

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await db
        .collection("ClientsProjects")

        .get();
      const fetchedData = snapshot.docs.map((doc) => doc.data());
      setData(fetchedData);
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item[searchBy].toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});

  //Add Project Modal
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  const [openD, setOpenD] = useState(false);
  const [modaldelete, setModalDelete] = useState({});

  //Add Project Modal
  const handleDelete = (item) => {
    setOpenD(true);
    setModalDelete(item);
  };

  const [openA, setOpenA] = useState(false);
  const [modalapprove, setModalApprove] = useState({});

  //Add Project Modal
  const handleApprove = (item) => {
    setOpenA(true);
    setModalApprove(item);
  };

  //Coment Box
  //Comment Box

  ///TimeFormats
  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div>
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Project Dashboard</h1>
        </div>

        <hr></hr>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg">
              <Link to="/adminPanel">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Available
                </Button>{" "}
              </Link>
              <Link to="/adminPanel_invite">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Invitee
                </Button>
              </Link>

              <Link to="/adminPanel_applied">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Applied
                </Button>
              </Link>

              <Link to="/adminPanel_process">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  In Process
                </Button>
              </Link>

              <Link to="/adminPanel_delivered">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Delivered
                </Button>
              </Link>

              <Link to="/adminPanel_cancelled">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Cancelled
                </Button>
              </Link>

              <Link to="/adminPanel_requests">
                <Button
                  color="facebook"
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Requests
                </Button>
              </Link>

              <Button
                size="small"
                onClick={() => handleAddProject()}
                color="blue"
                style={{ fontSize: "11px", margin: "1px" }}
              >
                Add Project
              </Button>
              {openP && (
                <ModalAdd
                  open={openP}
                  setOpen={setOpenP}
                  // handleDelete={handleDelete}
                  {...modalapplyP}
                />
              )}

              <AdditionalBtns />

              <div className="row" style={{ float: "right" }}>
                <div className="col">
                  <select
                    style={{ height: "4vh" }}
                    className="form-control"
                    value={searchBy}
                    onChange={handleSearchByChange}
                  >
                    <option value="name">Search by Name</option>
                    <option value="empId">Search by Employee ID</option>
                    <option value="OrderCode">Search by Project Code</option>
                    <option value="subject">Search by Category</option>
                  </select>
                </div>
                <div className="col">
                  <div class="ui icon input">
                    <i class="search icon"></i>
                    <input
                      value={searchTerm}
                      onChange={handleSearch}
                      type="text"
                      placeholder="Search..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br></br>

          {/* Available Projects*/}

          <Table
            responsive
            striped
            bordered
            hover
            size="sm"
            style={{ fontSize: "12px" }}
          >
            <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Project Code</th>
                <th scope="col">Employee ID</th>
                <th scope="col">Employee Name</th>

                <th scope="col">Subject</th>
                <th scope="col">Project Description</th>
                <th scope="col">Project Deadline</th>
                <th scope="col">Posted On</th>
                <th scope="col">Project Cost</th>
                <th scope="col">File</th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Status
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Action
                </th>
                {/* <th scope="col">CancelledOn</th> */}
                {/* <th scope="col">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                <>
                  {filteredData.map((item, index) => (
                    <tr key={item.id}>
                      <p hidden> {item.idd}</p>
                      <th scope="row" style={{ width: "5vh" }}>
                        {index + 1}
                      </th>
                      <td
                        style={{
                          minWidth: "12vh",
                        }}
                      >
                        #{item.OrderCode}
                      </td>
                      <td
                        style={{
                          minWidth: "12vh",
                        }}
                      >
                        #{item.empId}
                      </td>
                      <td
                        style={{
                          textTransform: "uppercase",
                          minWidth: "22vh",
                        }}
                      >
                        {item.name}
                      </td>

                      <td
                        style={{
                          minWidth: "18vh",
                        }}
                      >
                        {item.subject}
                      </td>
                      <td
                        style={{
                          minWidth: "35vh",
                        }}
                      >
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                Description
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              class="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <div
                                  style={{
                                    marginBottom: "8px",
                                    fontSize: "11px",
                                  }}
                                  contentEditable="false"
                                  dangerouslySetInnerHTML={{
                                    __html: `${item.pDesc}`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td style={{ minWidth: "22vh", color: "red" }}>
                        <b>
                          {item.deadline && formatTimestamp1(item.deadline)}
                        </b>
                      </td>
                      <td style={{ minWidth: "22vh" }}>
                        {item.posted && formatTimestamp(item.posted)}
                      </td>
                      <td style={{ minWidth: "12vh" }}>₹ {item.cost}</td>
                      <td
                        style={{
                          minWidth: "5vh",
                          textAlign: "center",
                        }}
                      >
                        {item.fileURL && item.fileURL.length > 0 ? (
                          <a
                            href={item.fileURL}
                            target="_blank"
                            style={{ color: "cornflowerblue" }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: "12vh",
                          fontWeight: "bold",
                          textAlign: "center",
                          color:
                            item.status === "Confirmed"
                              ? "green"
                              : item.status === "Pending"
                              ? "orange"
                              : "red",
                        }}
                      >
                        {item.status}
                      </td>
                      <p hidden>{item.idd}</p>
                      <td style={{ textAlign: "center" }}>
                        <div class="ui buttons">
                          <Button
                            style={{ fontSize: "11px", marginRight: "2px" }}
                            type="button"
                            key={item.id}
                            color="blue"
                            onClick={() => handleApprove(item)}
                          >
                            View
                          </Button>
                          {openA && (
                            <ModalApprove
                              open={openA}
                              setOpen={setOpenA}
                              // handleDelete={handleDelete}
                              {...modalapprove}
                            />
                          )}

                          <Button
                            style={{ fontSize: "11px" }}
                            type="button"
                            key={item.id}
                            color="red"
                            onClick={() => handleDelete(item)}
                          >
                            Delete
                          </Button>
                          {openD && (
                            <ModalDelete
                              open={openD}
                              setOpen={setOpenD}
                              // handleDelete={handleDelete}
                              {...modaldelete}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td
                    colSpan="12"
                    style={{ textAlign: "center", color: "red" }}
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </main>
    </div>
  );
}

export default Requests;
