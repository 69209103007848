import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ModalInvitePartners = ({ open, setOpen }) => {
  const [deadline, setDeadline] = useState("");
  const [OrderCode, setOrderCode] = useState("");
  const [title, setTitle] = useState("");
  const [words, setWords] = useState("");
  const [pDesc, setPdesc] = useState("");

  const [cost, setCost] = useState("");
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState("");
  const [success, setsuccessLoader] = useState("");
  const [userData, setUserData] = useState(null);
  const [userOptions, setUserOptions] = useState([]);

  const today = new Date();
  const chattime =
    today
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getSeconds()
      .toString()
      .padStart(2, "0");

  const current = new Date();

  const chatdate = current
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [selectedUser, setSelectedUser] = useState("");
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    // Fetch user options from Firestore
    const fetchUserOptions = async () => {
      try {
        const usersRef = db.collection("Partners");
        const snapshot = await usersRef.get();
        const options = snapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setUserOptions(options);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserOptions();
  }, []);

  const handleUserChange = async (e) => {
    const userId = e.target.value;
    setSelectedUser(userId);
    if (userId !== "") {
      try {
        const userRef = db.collection("Partners").doc(userId);
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          setUserDetails(userDoc.data());
        } else {
          setUserDetails({});
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setUserDetails({});
    }
  };

  const handleSubmit = async () => {
    setLoader("Adding Project ! Please wait...");
    setsuccessLoader("");

    if (!file) {
      try {
        db.collection("PartnerInvites")
          .doc(OrderCode)
          .set({
            pname: userDetails.name,
            pempId: userDetails.empId,
            prole: userDetails.role,
            uid: userDetails.uid,
            pemail: userDetails.email,
            OrderCode,
            name: userData.name,
            role: userData.role,
            status: "Pending",
            pDesc,
            deadline,
            cost,
            title,
            words,
            fileURL: "",
            posted: serverTimestamp(),
          });

        // Reset form fields and close the modal
        // Reset form fields and close the modal
        setLoader("");
        setsuccessLoader("Project added successfully!");
        setOrderCode("");

        setPdesc("");
        setTitle("");
        setWords("");
        setDeadline("");
        setCost("");
        setTimeout(() => {
          setLoader("");
          setsuccessLoader("");
          setOpen(false);
          window.location.reload(true);
        }, 2000);
      } catch (error) {
        console.log("Error submitting applied project:", error);
      }
    } else {
      try {
        // Create a storage reference
        const storage = getStorage();
        const storageRef = ref(
          storage,
          "PartnersInvite/" + chattime + file.name
        );

        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, file);

        // Get the download URL
        const downloadURL = await getDownloadURL(storageRef);

        db.collection("PartnerInvites")
          .doc(OrderCode)
          .set({
            pname: userDetails.name,
            pempId: userDetails.empId,
            prole: userDetails.role,
            uid: userDetails.uid,
            pemail: userDetails.email,
            OrderCode,
            name: userData.name,
            role: userData.role,
            status: "Pending",
            pDesc,
            deadline,
            cost,
            title,
            words,
            fileURL: downloadURL,
            posted: serverTimestamp(),
          });

        // Reset form fields and close the modal
        // Reset form fields and close the modal
        setLoader("");
        setsuccessLoader("Project added successfully!");
        setOrderCode("");

        setPdesc("");
        setDeadline("");
        setCost("");
        setTimeout(() => {
          setLoader("");
          setsuccessLoader("");
          setOpen(false);
          window.location.reload(true);
        }, 2000);
      } catch (error) {
        console.log("Error submitting applied project:", error);
      }
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Invite Partner
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <Form>
                {loader && (
                  <Alert
                    variant="warning"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {loader}
                  </Alert>
                )}
                {success && (
                  <Alert
                    variant="success"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {success}
                  </Alert>
                )}
                <Form.Field
                  placeholder="Select Partner"
                  label="Select Partner"
                  required
                  control="select"
                  id="user-select"
                  value={selectedUser}
                  onChange={handleUserChange}
                >
                  <option value="">-- Select --</option>
                  {userOptions.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </Form.Field>
                {selectedUser && (
                  <>
                    <div
                      style={{ marginTop: "-12px" }}
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            <i>User Details</i>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            <div
                              class="container-fluid"
                              style={{ fontSize: "12px" }}
                            >
                              <div class="row" style={{ marginTop: "-5px" }}>
                                <div class="col-lg-6 my-2">
                                  Partner ID: <b>{userDetails.empId}</b>
                                </div>

                                <div class="col-lg-6 my-2">
                                  Role: <b>{userDetails.role}</b>
                                </div>

                                <div class="col-lg-6 my-2">
                                  Contact: <b>{userDetails.contact}</b>
                                </div>

                                <div class="col-lg-6 my-2">
                                  Email:
                                  <b>{userDetails.email}</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="row">
                  <div className="col-lg-4">
                    <br></br>
                    <Form.Input
                      placeholder="Project Code"
                      label="Project Code"
                      required
                      onChange={(e) => setOrderCode(e.target.value)}
                      value={OrderCode}
                    />
                  </div>
                  <div className="col-lg-8">
                    <br></br>
                    <Form.Input
                      placeholder="Project Title"
                      label="Project Title"
                      required
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                    />
                  </div>
                  <div className="col-lg-6">
                    <br></br>
                    <Form.Input
                      placeholder="Word Count"
                      label="Word Count"
                      type="number"
                      onChange={(e) => setWords(e.target.value)}
                      value={words}
                    />
                  </div>
                  <div className="col-lg-6">
                    <br></br>
                    <Form.Input
                      placeholder="Project Cost"
                      label="Project Cost"
                      type="number"
                      onChange={(e) => setCost(e.target.value)}
                      value={cost}
                    />
                  </div>

                  <div className="col-lg-12">
                    <br></br>
                    <label>
                      <b>Project Description:</b>
                    </label>
                    <ReactQuill
                      theme="snow"
                      style={{ height: "15vh" }}
                      value={pDesc}
                      onChange={setPdesc}
                    />
                    <br></br> <br></br> <br></br> <br></br> <br></br>
                  </div>

                  <div className="col-lg-4 my-2">
                    <Form.Input
                      name="deadline"
                      required
                      type="datetime-local"
                      value={deadline}
                      onChange={(e) => setDeadline(e.target.value)}
                      label="Projec Deadline"
                    />
                  </div>

                  <div className="col-lg-8 my-2">
                    <Form.Input
                      label="File Upload"
                      type="file"
                      onChange={handleFileChange}
                      id="file-input"
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button size="small" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              color="facebook"
              ize="small"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Invite
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalInvitePartners;

{
  /* <Button color="grey" onClick={() => setOpen(false)}>
Cancel
</Button> */
}
