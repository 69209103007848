import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../Firebase-config";
import { Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import ModalEdit from "./Requests/ModalEdit";
import ModalComment from "./ModalComment";
import Sidebar from "./Sidebar";
import ModalAdd from "./ModalAdd";
import ModalApprove from "./Requests/ModalApprove";
function Requests() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Partners").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Fetch Data from database
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("PartnerInvites");

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProjects(documents);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setProjects([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const [openE, setOpenE] = useState(false);
  const [modaledit, setModalEdit] = useState({});
  const handleEdit = (deliver) => {
    setOpenE(true);
    setModalEdit(deliver);
  };

  const [openC, setOpenC] = useState(false);
  const [modalcomment, setModalComment] = useState({});
  const handleComment = (deliver) => {
    setOpenC(true);
    setModalComment(deliver);
  };

  const [openA, setOpenA] = useState(false);
  const [modalapprove, setModalApprove] = useState({});

  const handleApprove = (item) => {
    setOpenA(true);
    setModalApprove(item);
  };

  return (
    <>
      <div>
        {/* For Mobile */}
        <div
          class="offcanvas offcanvas-start w-25"
          tabindex="-1"
          id="offcanvas"
          data-bs-keyboard="false"
          data-bs-backdrop="false"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <div className="container-fluid" id="mobileSidebar">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              style={{ float: "right" }}
              aria-label="Close"
            >
              <i class="fa fa-close"></i>
            </button>
            <br></br> <br></br>
            <ul className="sidebar-nav" id="sidebar-nav">
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/home">
                  <i className="bi bi-grid"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link " to="/projects">
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Projects</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#tables-nav"
                  data-bs-toggle="collapse"
                  href="/"
                >
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Reports</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
              </li>

              <li className="nav-item my-5"></li>
            </ul>
          </div>
        </div>

        {/* For Mobile */}

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              <i
                style={{ color: "grey" }}
                class="fa fa-bars"
                id="sidebarBtn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
              ></i>{" "}
              Partners Dashboard
            </h1>
          </div>

          <hr></hr>

          <div className="container-fluid">
            <div className="row">
              <div className="col-lg">
                <Link to="/partnerDashboard">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Projects
                  </Button>
                </Link>

                <Button
                  size="small"
                  color="instagram"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                  onClick={() => handleAddProject()}
                >
                  Add Project
                </Button>
                {openP && (
                  <ModalAdd
                    open={openP}
                    setOpen={setOpenP}
                    // handleDelete={handleDelete}
                    {...modalapplyP}
                  />
                )}

                <Button
                  size="small"
                  color="facebook"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Requests
                </Button>
              </div>
            </div>

            <br></br>

            {/* InProcess Projects */}

            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "12px" }}
            >
              <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Project Code</th>
                  <th scope="col">Project Title</th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    File
                  </th>
                  <th scope="col">Investment</th>
                  <th scope="col">Cost</th>

                  <th scope="col">Deadline</th>

                  <th scope="col">PostedOn</th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    Status
                  </th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {projects.length > 0 ? (
                  projects.map((deliver, index) => (
                    <tr key={deliver.id}>
                      <td>{index + 1}</td>
                      <td style={{ minWidth: "18vh" }}>
                        {deliver.OrderCode && deliver.OrderCode.length > 0 ? (
                          <>#{deliver.OrderCode}</>
                        ) : (
                          <span style={{ color: "red" }}>
                            <i
                              class="fa fa-times"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            ></i>{" "}
                            Not Assigned
                          </span>
                        )}
                      </td>
                      <td style={{ minWidth: "18vh" }}>{deliver.title}</td>

                      <td
                        style={{
                          minWidth: "5vh",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {deliver.fileURL && deliver.fileURL.length > 0 ? (
                          <a
                            href={deliver.fileURL}
                            target="_blank"
                            style={{ color: "cornflowerblue" }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>

                      <td style={{ minWidth: "11vh" }}>
                        {deliver.cost && deliver.cost.length > 0 ? (
                          <>
                            <b style={{ color: "green" }}>
                              ₹ {deliver.cost}.00
                            </b>
                          </>
                        ) : (
                          <>
                            <b>₹ 0.00</b>
                          </>
                        )}
                      </td>

                      <td style={{ minWidth: "11vh" }}>
                        {deliver.fcost && deliver.fcost.length > 0 ? (
                          <>
                            <b>₹ {deliver.fcost}.00</b>
                          </>
                        ) : (
                          <>
                            <b>₹ 0.00</b>
                          </>
                        )}
                      </td>

                      <td style={{ color: "red", minWidth: "22vh" }}>
                        <b>
                          {deliver.deadline &&
                            formatTimestamp1(deliver.deadline)}
                        </b>
                      </td>

                      <td style={{ color: "green", minWidth: "22vh" }}>
                        <b>
                          {deliver.posted && formatTimestamp(deliver.posted)}
                        </b>
                      </td>

                      <td
                        style={{
                          width: "15vh",
                          textAlign: "center",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <p
                          style={{
                            color: "white",
                            borderRadius: "5px",
                            padding: "4px",
                            backgroundColor:
                              deliver.status === "Approved"
                                ? "#00c434"
                                : deliver.status === "Rejected"
                                ? "#FFE7D9"
                                : deliver.status === "Cancelled"
                                ? "#FFE7D9"
                                : deliver.status === "Completed"
                                ? "green"
                                : "orange",
                          }}
                        >
                          {deliver.status}
                        </p>
                      </td>
                      <td
                        style={{
                          minWidth: "25vh",
                          maxWidth: "26vh",
                          textAlign: "center",
                        }}
                      >
                        <div class="ui buttons">
                          <Button
                            type="button"
                            size="tiny"
                            onClick={() => handleComment(deliver)}
                            // onClick={() => navigate(`/comment/${item.id}`)}
                            style={{ marginLeft: "3px", fontSize: "11px" }}
                          >
                            <i className="fa fa-comments"></i>
                          </Button>
                          {openC && (
                            <ModalComment
                              open={openC}
                              setOpen={setOpenC}
                              // handleDelete={handleDelete}
                              {...modalcomment}
                            />
                          )}

                          <Button
                            size="tiny"
                            style={{ marginLeft: "3px", fontSize: "11px" }}
                            onClick={() => handleEdit(deliver)}
                          >
                            <i className="fa fa-pencil"></i>
                          </Button>
                          {openE && (
                            <ModalEdit
                              open={openE}
                              setOpen={setOpenE}
                              // handleDelete={handleDelete}
                              {...modaledit}
                            />
                          )}

                          {deliver.status && deliver.status === "Approved" ? (
                            <>
                              <Button
                                type="button"
                                size="tiny"
                                color="red"
                                onClick={() => handleApprove(deliver)}
                                // onClick={() => navigate(`/comment/${item.id}`)}
                                style={{ marginLeft: "3px", fontSize: "11px" }}
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </Button>
                              {openA && (
                                <ModalApprove
                                  open={openA}
                                  setOpen={setOpenA}
                                  // handleDelete={handleDelete}
                                  {...modalapprove}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <Button
                                type="button"
                                size="tiny"
                                style={{ marginLeft: "3px", fontSize: "11px" }}
                                color="green"
                                onClick={() => handleApprove(deliver)}
                                // onClick={() => navigate(`/comment/${item.id}`)}
                              >
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </Button>
                              {openA && (
                                <ModalApprove
                                  open={openA}
                                  setOpen={setOpenA}
                                  // handleDelete={handleDelete}
                                  {...modalapprove}
                                />
                              )}{" "}
                            </>
                          )}
                        </div>{" "}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="13"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            {/* InProcess Projects */}
          </div>
        </main>

        {/* //ChatBox */}
      </div>
    </>
  );
}

export default Requests;
