import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Link, useNavigate } from "react-router-dom";
import { onSnapshot } from "firebase/firestore";
import { Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import ModalAdd from "../ModalAdd";

import ModalEmployeeEdit from "./ModalPartnerApproval";
import ModalPartner from "./ModalPartner";
import Sidebar from "../Sidebar";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function Employees() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const navigate = useNavigate();

  //Add Partner Modal
  const [openPr, setOpenPr] = useState(false);
  const [modalPr, setModalApplyPr] = useState({});
  const handleAddPartner = () => {
    setOpenPr(true);
    setModalApplyPr();
  };

  ///Employees fetch Data
  const [openEM, setOpenEM] = useState(false);
  const [modalemployee, setModalEmployee] = useState({});

  const handleEmployee = (item) => {
    setOpenEM(true);
    setModalEmployee(item);
  };

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("name");

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await db
        .collection("Partners")
        .orderBy("createdOn", "desc")
        .get();
      const fetchedData = snapshot.docs.map((doc) => doc.data());
      setData(fetchedData);
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item[searchBy].toLowerCase().includes(searchTerm.toLowerCase())
  );
  ///Employees fetch Data

  const formatTimestamp = (createdOn) => {
    const date = createdOn.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <>
      <div>
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Partners Dashboard</h1>
          </div>

          <hr></hr>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg">
                <div class="ui buttons" style={{ margin: "1px" }}>
                  <Button
                    color="facebook"
                    size="small"
                    style={{
                      fontSize: "11px",
                      margin: "1px",
                    }}
                  >
                    Partners
                  </Button>
                  <div class="or" data-text="&"></div>
                  <Button
                    style={{ fontSize: "11px", margin: "1px" }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Export
                  </Button>
                  <div class="or" data-text="&"></div>
                  <Button
                    size="small"
                    onClick={() => handleAddPartner()}
                    style={{
                      fontSize: "11px",
                      margin: "1px",
                    }}
                  >
                    Add Partner
                  </Button>
                  {openPr && (
                    <ModalPartner
                      open={openPr}
                      setOpen={setOpenPr}
                      // handleDelete={handleDelete}
                      {...modalPr}
                    />
                  )}
                </div>

                <Link to="/partner_projects">
                  <Button
                    size="small"
                    style={{
                      fontSize: "11px",
                      margin: "1px",
                    }}
                  >
                    Partner Project
                  </Button>
                </Link>

                <Link to="/partner_invite">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Invite Partner
                  </Button>
                </Link>

                <div className="row" style={{ float: "right" }}>
                  <div className="col">
                    <select
                      style={{ height: "4vh" }}
                      className="form-control"
                      value={searchBy}
                      onChange={handleSearchByChange}
                    >
                      <option value="name">Search by Name</option>
                      <option value="empId">Search by Partner ID</option>
                      <option value="contact">Search by Contact No.</option>
                      <option value="role">Search by Role</option>
                    </select>
                  </div>
                  <div className="col">
                    <div class="ui icon input">
                      <i class="search icon"></i>
                      <input
                        value={searchTerm}
                        onChange={handleSearch}
                        type="text"
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br></br>

            {/* <!-- Modal --> */}
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div
                    class="modal-header"
                    style={{ backgroundColor: "#3E6399", color: "white" }}
                  >
                    <h5 class="modal-title" id="exampleModalLabel">
                      Export Partners List (Excel sheet)
                    </h5>

                    <button
                      type="button"
                      style={{
                        color: "white",
                        backgroundColor: "#3E6399",
                        border: "1px solid White",
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      X
                    </button>
                  </div>
                  <div class="modal-body text-center">
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="ui button"
                      table="table-to-xls"
                      filename="EmployeesList"
                      sheet="tablexls"
                      buttonText="Download File"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Employee List*/}
            <Table
              responsive
              id="table-to-xls"
              striped
              hidden
              bordered
              hover
              size="sm"
              style={{ fontSize: "12px" }}
            >
              <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
                <tr>
                  <th scope="col">#</th>

                  <th scope="col">Partner ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact</th>
                  <th scope="col">Company Name</th>

                  <th scope="col">Created On</th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={item.id}>
                    <th scope="row" style={{ width: "5vh" }}>
                      {index + 1}
                    </th>

                    <td style={{ minWidth: "12vh" }}>{item.empId}</td>
                    <td
                      style={{ minWidth: "23vh", textTransform: "uppercase" }}
                    >
                      <b>{item.name}</b>
                    </td>
                    <td>{item.email}</td>
                    <td>{item.contact}</td>
                    <td style={{ minWidth: "20vh" }}>{item.company}</td>

                    <td style={{ minWidth: "23vh" }}>
                      {item.createdOn && formatTimestamp(item.createdOn)}
                    </td>
                    <td
                      style={{
                        minWidth: "12vh",
                        textAlign: "center",
                        color:
                          item.status === "Approved"
                            ? "green"
                            : "Rejected"
                            ? "red"
                            : "grey",
                      }}
                    >
                      <b>{item.status}</b>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "12px" }}
            >
              <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
                <tr>
                  <th scope="col">#</th>

                  <th scope="col">Partner ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact</th>
                  <th scope="col">Company Name</th>

                  <th scope="col">Created On</th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Status
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={item.id}>
                    <th scope="row" style={{ width: "5vh" }}>
                      {index + 1}
                    </th>

                    <td style={{ minWidth: "12vh" }}>{item.empId}</td>
                    <td
                      style={{ minWidth: "23vh", textTransform: "uppercase" }}
                    >
                      <b>{item.name}</b>
                    </td>
                    <td>{item.email}</td>
                    <td>{item.contact}</td>
                    <td style={{ minWidth: "20vh" }}>{item.company}</td>

                    <td style={{ minWidth: "23vh" }}>
                      {item.createdOn && formatTimestamp(item.createdOn)}
                    </td>
                    <td
                      style={{
                        minWidth: "12vh",
                        textAlign: "center",
                        color:
                          item.status === "Approved"
                            ? "green"
                            : "Rejected"
                            ? "red"
                            : "grey",
                      }}
                    >
                      <b>{item.status}</b>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div class="ui buttons">
                        <Button
                          type="button"
                          color="instagram"
                          onClick={() =>
                            navigate(`/partnerDetails_details/${item.uid}`)
                          }
                          style={{ marginLeft: "3px", fontSize: "11px" }}
                        >
                          View Profile
                        </Button>

                        <Button
                          type="button"
                          onClick={() => handleEmployee(item)}
                          style={{ marginLeft: "3px", fontSize: "11px" }}
                        >
                          <i class="fa fa-pencil"></i>
                        </Button>
                        {openEM && (
                          <ModalEmployeeEdit
                            open={openEM}
                            setOpen={setOpenEM}
                            // handleDelete={handleDelete}
                            {...modalemployee}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* Employee List*/}
          </div>
        </main>
      </div>
    </>
  );
}

export default Employees;
