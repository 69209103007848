import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";

import { serverTimestamp } from "firebase/firestore";

const ModalInvite = ({
  open,
  setOpen,
  OrderCode,
  title,
  words,
  pDesc,
  fileURL,
  id,
}) => {
  const [deadline, setDeadline] = useState("");
  const [charges, setCharge] = useState("");
  const [task, setTask] = useState("");
  const [loader, setLoader] = useState("");
  const [success, setsuccessLoader] = useState("");
  const [userData, setUserData] = useState(null);
  const [userOptions, setUserOptions] = useState([]);

  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [selectedUser, setSelectedUser] = useState("");
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    // Fetch user options from Firestore
    const fetchUserOptions = async () => {
      try {
        const usersRef = db.collection("Users");
        const snapshot = await usersRef.get();
        const options = snapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setUserOptions(options);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserOptions();
  }, []);

  const handleUserChange = async (e) => {
    const userId = e.target.value;
    setSelectedUser(userId);
    if (userId !== "") {
      try {
        const userRef = db.collection("Users").doc(userId);
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          setUserDetails(userDoc.data());
        } else {
          setUserDetails({});
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setUserDetails({});
    }
  };

  const handleSubmit = async () => {
    setLoader("Sending ! Please wait...");
    setsuccessLoader("");

    try {
      await db
        .collection("Invites")
        .doc(`${userDetails.uid}-${OrderCode}`)
        .set({
          fname: userDetails.name,
          empId: userDetails.empId,
          frole: userDetails.role,
          uid: userDetails.uid,
          task,
          OrderCode,
          subject: "",
          pDesc,
          deadline,
          title,
          words,
          fileURL,
          cost: charges,
          deadline,
          posted: serverTimestamp(),
        });

      // Reset form fields and close the modal
      // Reset form fields and close the modal
      setLoader("");
      setsuccessLoader("Invite sent successfully!");
      setDeadline("");
      setCharge("");
      setTimeout(() => {
        setLoader("");
        setsuccessLoader("");
        setOpen(false);
      }, 2000);
    } catch (error) {
      console.log("Error sending invite project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Invite Freelancer
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <Form>
                {loader && (
                  <Alert
                    variant="warning"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {loader}
                  </Alert>
                )}
                {success && (
                  <Alert
                    variant="success"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {success}
                  </Alert>
                )}
                <Form.Field
                  placeholder="Select User"
                  label="Select User"
                  required
                  control="select"
                  id="user-select"
                  value={selectedUser}
                  onChange={handleUserChange}
                >
                  <option value="">-- Select --</option>
                  {userOptions.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                      <b style={{ color: "red" }}> </b>
                    </option>
                  ))}
                </Form.Field>
                {selectedUser && (
                  <>
                    <div
                      style={{ marginTop: "-12px" }}
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            <i>User Details</i>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            <div
                              class="container-fluid"
                              style={{ fontSize: "12px" }}
                            >
                              <div class="row" style={{ marginTop: "-5px" }}>
                                <div class="col-lg-6 my-2">
                                  Employee ID: <b>{userDetails.empId}</b>
                                </div>

                                <div class="col-lg-6 my-2">
                                  Role: <b>{userDetails.role}</b>
                                </div>

                                <div class="col-lg-6 my-2">
                                  Contact: <b>{userDetails.contact}</b>
                                </div>

                                <div class="col-lg-6 my-2">
                                  Email:
                                  <b>{userDetails.email}</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Form.Input hidden defaultValue={userDetails.uid} />

                      <Form.Input hidden defaultValue={userDetails.empId} />

                      <Form.Input hidden defaultValue={userDetails.contact} />
                      <Form.Input hidden defaultValue={userDetails.role} />
                    </div>
                  </>
                )}

                <div className="col-lg">
                  <br></br>
                  <Form.TextArea
                    required
                    value={task}
                    onChange={(e) => setTask(e.target.value)}
                    label="Tasks"
                  ></Form.TextArea>
                </div>

                <div className="row" style={{ marginBottom: "10px" }}>
                  <div className="col-lg">
                    <br></br>
                    <Form.Input
                      name="Charge"
                      required
                      type="number"
                      value={charges}
                      onChange={(e) => setCharge(e.target.value)}
                      label="Freelancer Charge"
                    />
                  </div>

                  <div className="col-lg">
                    <br></br>

                    <Form.Input
                      name="deadline"
                      required
                      type="datetime-local"
                      defaultValue={deadline}
                      onChange={(e) => setDeadline(e.target.value)}
                      label="Project Deadline"
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button size="small" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              color="facebook"
              ize="small"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Invite
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalInvite;

{
  /* <Button color="grey" onClick={() => setOpen(false)}>
Cancel
</Button> */
}
