import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { serverTimestamp, collection, getDocs } from "firebase/firestore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ModalInvite = ({ open, setOpen }) => {
  const [img, setImg] = useState("");
  const [name, setName] = useState("");
  const [empId, setEmpId] = useState("");
  const [role, setRole] = useState("");
  const [uid, setUid] = useState("");
  const [email, setEmail] = useState("");

  const [OrderCode, setOrderCode] = useState("");
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [words, setWords] = useState("");
  const [pDesc, setPdesc] = useState("");
  const [deadline, setDeadline] = useState("");
  const [cost, setCost] = useState("");
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState("");
  const [success, setsuccessLoader] = useState("");
  const [userData, setUserData] = useState(null);

  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const today = new Date();
  const chattime =
    today
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getSeconds()
      .toString()
      .padStart(2, "0");

  const current = new Date();

  const chatdate = current
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "Users"));
      const usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    };
    fetchUsers();
  }, []);

  const handleUserSelect = (event) => {
    const userId = event.target.value;
    const user = users.find((user) => user.id === userId);
    setSelectedUser(user);
  };

  const handleSubmit = async () => {
    setLoader("Adding Project ! Please wait...");
    setsuccessLoader("");

    if (!file) {
      try {
        db.collection("Invites")
          .doc(OrderCode)
          .set({
            img: selectedUser.img,
            fname: selectedUser.name,
            empId: selectedUser.empId,
            frole: selectedUser.role,
            uid: selectedUser.uid,
            email: selectedUser.email,
            role: userData.role,
            name: userData.name,
            OrderCode,
            subject,
            pDesc,
            deadline,
            cost,
            words,
            title,
            fileURL: "",
            posted: serverTimestamp(),
          });

        // Reset form fields and close the modal
        // Reset form fields and close the modal
        setLoader("");
        setsuccessLoader("Project added successfully!");
        setOrderCode("");

        setSubject("");
        setPdesc("");
        setTitle("");
        setWords("");
        setDeadline("");
        setCost("");
        setTimeout(() => {
          setLoader("");
          setsuccessLoader("");
          setOpen(false);
        }, 2000);
      } catch (error) {
        console.log("Error submitting applied project:", error);
      }
    } else {
      try {
        // Create a storage reference
        const storage = getStorage();
        const storageRef = ref(storage, "Chats/" + chattime + file.name);

        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, file);

        // Get the download URL
        const downloadURL = await getDownloadURL(storageRef);

        db.collection("Invites")
          .doc(OrderCode)
          .set({
            img: selectedUser.img,
            fname: selectedUser.name,
            empId: selectedUser.empId,
            frole: selectedUser.role,
            uid: selectedUser.uid,
            email: selectedUser.email,
            OrderCode,
            name: userData.name,
            role: userData.role,
            subject,
            pDesc,
            deadline,
            cost,
            title,
            words,
            fileURL: downloadURL,
            posted: serverTimestamp(),
          });

        // Reset form fields and close the modal
        // Reset form fields and close the modal
        setLoader("");
        setsuccessLoader("Project added successfully!");
        setOrderCode("");

        setSubject("");
        setPdesc("");
        setDeadline("");
        setCost("");
        setTimeout(() => {
          setLoader("");
          setsuccessLoader("");
          setOpen(false);
        }, 2000);
      } catch (error) {
        console.log("Error submitting applied project:", error);
      }
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#2185d2", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Invite Freelancer
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#2185d2",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <Form>
                {loader && (
                  <Alert
                    variant="warning"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {loader}
                  </Alert>
                )}
                {success && (
                  <Alert
                    variant="success"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {success}
                  </Alert>
                )}
                <Form.Field
                  placeholder="Select User"
                  label="Select User"
                  required
                  control="select"
                  id="user-select"
                  value={selectedUser ? selectedUser.id : ""}
                  onChange={handleUserSelect}
                >
                  <option value="">-- Select --</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </Form.Field>
                {selectedUser && (
                  <>
                    <div
                      style={{ marginTop: "-12px" }}
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            <i>User Details</i>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            <div
                              class="container-fluid"
                              style={{ fontSize: "12px" }}
                            >
                              <div class="row" style={{ marginTop: "-5px" }}>
                                <div class="col-lg-6 my-2">
                                  Employee ID: <b>{selectedUser.empId}</b>
                                </div>

                                <div class="col-lg-6 my-2">
                                  Role: <b>{selectedUser.role}</b>
                                </div>

                                <div class="col-lg-6 my-2">
                                  Contact: <b>{selectedUser.contact}</b>
                                </div>

                                <div class="col-lg-6 my-2">
                                  Email:
                                  <b>{selectedUser.email}</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Form.Input
                        hidden
                        value={selectedUser.email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <Form.Input
                        hidden
                        value={selectedUser.uid}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <Form.Input
                        hidden
                        value={selectedUser.empId}
                        onChange={(e) => setEmpId(e.target.value)}
                      />

                      <Form.Input
                        hidden
                        value={selectedUser.role}
                        onChange={(e) => setRole(e.target.value)}
                      />
                      <Form.Input
                        hidden
                        value={selectedUser.img}
                        onChange={(e) => setImg(e.target.value)}
                      />
                    </div>
                  </>
                )}
                <div className="row">
                  <div className="col-lg-4">
                    <Form.Input
                      placeholder="Project Code"
                      label="Project Code"
                      required
                      onChange={(e) => setOrderCode(e.target.value)}
                      value={OrderCode}
                    />
                  </div>
                  <div className="col-lg-8">
                    {" "}
                    <Form.Input
                      placeholder="Project Title"
                      label="Project Title"
                      required
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <br></br>
                    <Form.Field
                      label="Category"
                      required
                      control="select"
                      placeholder="Category"
                      onChange={(e) => setSubject(e.target.value)}
                      value={subject}
                    >
                      <option value=" ">--Select Category--</option>
                      <option value="Digital Marketing">
                        Digital Marketing
                      </option>
                      <option value="Website Design">Website Design</option>
                      <option value="Website Re-design">
                        Website Re-design
                      </option>
                      <option value="ReactJs Website">ReactJs Website</option>
                      <option value="WordPress Website">
                        WordPress Website
                      </option>

                      <option value="Woo Commerce Website">
                        Woo Commerce Website
                      </option>
                      <option value="Landing Page Webiste">
                        Landing Page Website
                      </option>
                      <option value="Static Website">Static Website</option>
                      <option value="Dynamic Website">Dynamic Website</option>
                      <option value="Sourcing">Sourcing</option>
                      <option value="Data Entry Work">Data Entry Work</option>
                      <option value="Meta Data">Meta Data</option>
                      <option value="Machine Learning">Machine Learning</option>
                      <option value="AI tools Designing">
                        AI tools Designing
                      </option>
                      <option value="Dashboard Development Project">
                        Dashboard Development Project
                      </option>
                      <option value="Python Projects">Python Projects</option>

                      <option value="ERP">ERP</option>
                      <option value="SAP">SAP</option>
                      <option value="Web Scrapping">Web Scrapping</option>
                      <option value="Software Testing">Software Testing</option>
                      <option value="Accounting and Finance">
                        Accounting and Finance
                      </option>
                      <option value="Medical Sciences">Medical Sciences</option>
                      <option value="Design">Design</option>
                      <option value="Video & Audio Editing">
                        Video & Audio Editing
                      </option>
                      <option value="Lifestyle">Lifestyle</option>
                      <option value="Writing & Transalation">
                        Writing & Transalation
                      </option>
                      <option value="Consulting & HR">Consulting & HR</option>

                      <option value="IT Computer Science">
                        IT Computer Science
                      </option>

                      <option value="Engineering">Engineering</option>
                      <option value="Science and Maths">
                        Science and Maths
                      </option>
                      <option value="Law">Law</option>
                      <option value="Statistics">Statistics</option>
                      <option value="General Subjects">General Subjects</option>
                      <option value="Arts and Humanities">
                        Arts and Humanities
                      </option>
                      <option value="Economics">Economics</option>
                      <option value="Management">Management</option>
                      <option value="Graphic Designing">
                        Graphic Designing
                      </option>
                      <option value="Mechanical Design">
                        Mechanical Design
                      </option>
                      <option value="Architectural Design">
                        Architectural Design
                      </option>
                      <option value="Simulation">Simulation</option>
                      <option value="Mechatronics">Mechatronics</option>
                      <option value="Aeronautical Design">
                        Aeronautical Design
                      </option>
                      <option value="ChatGPT/GPT Works">
                        ChatGPT/GPT Works
                      </option>

                      <option value="Music">Music</option>
                    </Form.Field>
                  </div>
                  <div className="col-lg-4 ">
                    <br></br>
                    <Form.Input
                      placeholder="Word Count"
                      label="Word Count"
                      type="number"
                      onChange={(e) => setWords(e.target.value)}
                      value={words}
                    />
                  </div>
                </div>
                <br></br>
                <label>
                  <b>Project Description:</b>
                </label>
                <ReactQuill
                  theme="snow"
                  style={{ height: "15vh" }}
                  value={pDesc}
                  onChange={setPdesc}
                />
                <br></br> <br></br> <br></br> <br></br> <br></br>
                <div className="row" style={{ marginBottom: "10px" }}>
                  <div className="col-lg">
                    <br></br>
                    <Form.Input
                      name="deadline"
                      required
                      type="datetime-local"
                      value={deadline}
                      onChange={(e) => setDeadline(e.target.value)}
                      label="Projec Deadline"
                    />
                  </div>

                  <div className="col-lg">
                    <br></br>
                    <Form.Input
                      placeholder="Project Cost"
                      label="Project Cost"
                      onChange={(e) => setCost(e.target.value)}
                      value={cost}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <br></br>
                    <Form.Input
                      label="File Upload"
                      type="file"
                      onChange={handleFileChange}
                      id="file-input"
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button size="small" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              color="blue"
              ize="small"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Invite
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalInvite;

{
  /* <Button color="grey" onClick={() => setOpen(false)}>
Cancel
</Button> */
}
