import React from "react";

import { Button, Modal } from "semantic-ui-react";

const ModalTask = ({ open, setOpen, OrderCode, task }) => {
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3b5998", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              OrderCode: #{OrderCode}
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3b5998",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <b>Project Details: </b>
            <p style={{ marginTop: "10px" }}>{task}</p>
          </div>
          <div class="modal-footer">
            <Button onClick={() => setOpen(false)}>Close</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalTask;
