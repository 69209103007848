import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db, auth } from "../Firebase-config";
const Sidebar = () => {
  const [docCount, setDocCount] = useState(null);
  const [docHelp, setDocHelp] = useState(null);
  const [docHelpP, setDocHelpP] = useState(null);
  const [error, setError] = useState(null);
  //Available Project
  useEffect(() => {
    const collectionRef = db
      .collection("ChatBox")
      .where("sender", "==", "Anew");

    collectionRef
      .get()
      .then((querySnapshot) => {
        setDocCount(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      });
  });

  useEffect(() => {
    const collectionRef = db
      .collection("HelpNeed")
      .where("status", "==", "InProcess");

    collectionRef
      .get()
      .then((querySnapshot) => {
        setDocHelp(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      });
  });

  useEffect(() => {
    const collectionRef = db
      .collection("PartnersHelpNeed")
      .where("status", "==", "InProcess");

    collectionRef
      .get()
      .then((querySnapshot) => {
        setDocHelpP(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      });
  });

  function refreshPage(){ 
    window.location.reload(); 
}

  return (
    <div>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/home_admin">
              <i class="fa fa-home" aria-hidden="true"></i>
              <span>Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/adminPanel">
              <i class="fa fa-briefcase" aria-hidden="true"></i>
              <span>Projects</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/partners_list">
              <i class="fa fa-users" aria-hidden="true"></i>
              <span>Partners</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/reports_details_">
              <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
              <span>Reports</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/help_support">
              <i class="fa fa-comments"></i> <span>Help Support</span>
              {!docHelp ? (
                <></>
              ) : (
                <>
                  <div
                    class="ui orange label"
                    style={{
                      marginTop: "-18px",
                      borderRadius: "50%",
                      fontSize: "10px",
                    }}
                  >
                    {docHelp}
                  </div>
                </>
              )}
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/partner_support">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
              <span>Partner's Support</span>
              {!docHelpP ? (
                <></>
              ) : (
                <>
                  <div
                    class="ui yellow label"
                    style={{
                      marginTop: "-18px",
                      borderRadius: "50%",
                      fontSize: "10px",
                    }}
                  >
                    {docHelpP}
                  </div>
                </>
              )}
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed item" to="/adminPanel_chats">
              <i class="fa fa-comments"></i> <span>Chats</span>
              {!docCount ? (
                <></>
              ) : (
                <>
                  <div
                    class="ui green label"
                    style={{
                      marginTop: "-18px",
                      borderRadius: "50%",
                      fontSize: "10px",
                    }}
                  >
                    {docCount}
                  </div>
                </>
              )}
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed item" >
            <a size="tiny" color="grey" type="button" onClick={ refreshPage }><i class="fa fa-refresh" aria-hidden="true"></i> <span>Reload</span> </a> 
            </Link>
          </li>

          <li
            style={{ bottom: "20px", position: "absolute", fontSize: "12.5px" }}
          >
            <span style={{ color: "grey", fontSize: "11px" }}>VERSION:</span>{" "}
            <b>FXCTS-ADS-v1.01.02</b>
            <br></br>
            <span style={{ fontSize: "11px" }}>
              Copyright <i class="fa fa-copyright" aria-hidden="true"></i> FXCTS
              2023
            </span>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
