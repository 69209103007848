import React, { useState, useEffect } from "react";
import { db, auth } from "../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const ModalAdd = ({ open, setOpen }) => {
  const [title, setTitle] = useState("");
  const [words, setWords] = useState("");

  const [pDesc, setPdesc] = useState("");
  const [fdeadline, setfdeadline] = useState("");
  const [budget, setCost] = useState("");
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState("");
  const [success, setsuccessLoader] = useState("");
  const [userData, setUserData] = useState(null);

  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Partners").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const today = new Date();
  const chattime =
    today
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getSeconds()
      .toString()
      .padStart(2, "0");

  const current = new Date();

  const chatdate = current
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  function generateUniqueId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uniqueId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters.charAt(randomIndex);
    }

    return uniqueId;
  }

  const uniqueId = generateUniqueId(5);

  const handleSubmit = async () => {
    setLoader("Adding Project ! Please wait...");
    setsuccessLoader("");

    if (!file) {
      try {
        db.collection("PartnersProjects")
          .doc(`${chatdate}-${chattime}`)
          .set({
            pempId: userData.empId,
            pname: userData.name,
            pemail: userData.email,
            pcontact: userData.contact,
            puid: userData.uid,
            prole: userData.role,
            title,
            OrderCode: "",
            words,
            cost: "",
            status: "Pending",
            pDesc,
            fdeadline,
            budget,
            fileURL: "",
            posted: serverTimestamp(),
            uniqueID: uniqueId,
          })
          .then(() => {
            db.collection("PartnersChats")
              .doc(uniqueId)
              .collection("ChatsCollections")
              .doc(`${chatdate}-${chattime}`)
              .set({
                pempId: userData.empId,
                pname: userData.name,
                pemail: userData.email,
                pcontact: userData.contact,
                puid: userData.uid,
                prole: userData.role,

                chattime: serverTimestamp(),

                title,
                fdeadline,
                pDesc: `Project posted on ${chatdate}-${chattime}`,
                title,
                words,
              });
          });

        // Reset form fields and close the modal
        // Reset form fields and close the modal
        setLoader("");
        setsuccessLoader("Project added successfully!");
        setTitle("");
        setWords("");

        setPdesc("");
        setfdeadline("");
        setCost("");
        setTimeout(() => {
          setLoader("");
          setsuccessLoader("");
          setOpen(false);
        }, 2000);
      } catch (error) {
        console.log("Error submitting applied project:", error);
      }
    } else {
      try {
        // Create a storage reference
        const storage = getStorage();
        const storageRef = ref(
          storage,
          "PartnersProjects/" + file.name + chattime
        );

        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, file);

        // Get the download URL
        const downloadURL = await getDownloadURL(storageRef);

        db.collection("PartnersProjects")
          .doc(`${chatdate}-${chattime}`)
          .set({
            empId: userData.empId,
            name: userData.name,
            email: userData.email,
            contact: userData.contact,
            uid: userId,
            role: userData.role,
            title,
            OrderCode: "",
            words,
            status: "Pending",
            cost: "",
            pDesc,
            fdeadline,
            budget,
            fileURL: downloadURL,
            posted: serverTimestamp(),
            uniqueID: uniqueId,
          })
          .then(() => {
            db.collection("PartnersChats")
              .doc(uniqueId)
              .collection("ChatsCollections")
              .doc(`${chatdate}-${chattime}`)
              .set({
                pempId: userData.empId,
                pname: userData.name,
                pemail: userData.email,
                pcontact: userData.contact,
                puid: userData.uid,
                prole: userData.role,

                chattime: serverTimestamp(),

                title,
                fdeadline,
                pDesc: `Project posted on ${chatdate}-${chattime}`,
                title,
                words,
              });
          });

        // Reset form fields and close the modal
        // Reset form fields and close the modal
        setLoader("");
        setsuccessLoader("Project added successfully!");
        setTitle("");
        setWords("");

        setPdesc("");
        setfdeadline("");
        setCost("");
        setTimeout(() => {
          setLoader("");
          setsuccessLoader("");
          setOpen(false);
        }, 2000);
      } catch (error) {
        console.log("Error submitting applied project:", error);
      }
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3b5998", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Post a Project
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3b5998",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <Form>
                {loader && (
                  <Alert
                    variant="warning"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {loader}
                  </Alert>
                )}
                {success && (
                  <Alert
                    variant="success"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {success}
                  </Alert>
                )}
                <div className="row">
                  <div className="col-lg-8">
                    <br></br>
                    <Form.Input
                      placeholder="Project Title"
                      label="Project Title"
                      required
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                    />
                  </div>
                  <div className="col-lg-4">
                    <br></br>
                    <Form.Input
                      placeholder="Word Count"
                      label="Word Count"
                      type="number"
                      onChange={(e) => setWords(e.target.value)}
                      value={words}
                    />
                  </div>
                </div>
                <br></br>
                <label>
                  <b>Project Description:</b>
                </label>
                <ReactQuill
                  theme="snow"
                  style={{ height: "15vh" }}
                  value={pDesc}
                  onChange={setPdesc}
                />
                <br></br> <br></br> <br></br> <br></br> <br></br>
                <div className="row" style={{ marginBottom: "10px" }}>
                  <div className="col-lg">
                    <br></br>
                    <Form.Input
                      name="deadline"
                      required
                      type="datetime-local"
                      value={fdeadline}
                      onChange={(e) => setfdeadline(e.target.value)}
                      label="Project deadline"
                    />
                  </div>

                  <div className="col-lg">
                    <br></br>
                    <Form.Input
                      placeholder="Project Budget"
                      label="Project Budget"
                      onChange={(e) => setCost(e.target.value)}
                      value={budget}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <br></br>
                    <Form.Input
                      label="File Upload"
                      type="file"
                      onChange={handleFileChange}
                      id="file-input"
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button size="small" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              color="facebook"
              size="small"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Add Project
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAdd;
