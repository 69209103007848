import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { db, auth } from "../../Firebase-config";
import { Button } from "semantic-ui-react";
import { Alert, Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import ModalAdd from "../ModalAdd";
import ModalReport from "../ModalReport";
function Reports() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [reports, setReport] = useState([]);
  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("Reports").where("uid", "==", userId);

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setReport(documents);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setReport([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  const [openR, setOpenR] = useState(false);
  const [modalapplyR, setModalApplyR] = useState({});
  const handleReport = (user) => {
    setOpenR(true);
    setModalApplyR(user);
  };

  const formatTimestamp = (updateOn) => {
    const date = updateOn.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <>
      <div>
        {/* For Mobile */}
        <div
          class="offcanvas offcanvas-start w-25"
          tabindex="-1"
          id="offcanvas"
          data-bs-keyboard="false"
          data-bs-backdrop="false"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <div className="container-fluid" id="mobileSidebar">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              style={{ float: "right" }}
              aria-label="Close"
            >
              <i class="fa fa-close"></i>
            </button>
            <br></br> <br></br>
            <ul className="sidebar-nav" id="sidebar-nav">
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/home">
                  <i className="bi bi-grid"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link " to="/projects">
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Projects</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#tables-nav"
                  data-bs-toggle="collapse"
                  href="/"
                >
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Reports</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                {/* <ul
              id="tables-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <a href="tables-general.html">
                  <i className="bi bi-circle"></i>
                  <span>General Tables</span>
                </a>
              </li>
              <li>
                <a href="tables-data.html">
                  <i className="bi bi-circle"></i>
                  <span>Data Tables</span>
                </a>
              </li>
            </ul> */}
              </li>

              <li className="nav-item my-5">
                <Button
                  color="facebook"
                  onClick={() => handleAddProject()}
                  style={{ marginLeft: "18px" }}
                  className="collapsed"
                >
                  <span>Create Project</span>
                </Button>
                {openP && (
                  <ModalAdd
                    open={openP}
                    setOpen={setOpenP}
                    // handleDelete={handleDelete}
                    {...modalapplyP}
                  />
                )}
              </li>
            </ul>
          </div>
        </div>

        {/* For Mobile */}

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              <i
                style={{ color: "grey" }}
                class="fa fa-bars"
                id="sidebarBtn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
              ></i>{" "}
              Reports
            </h1>
          </div>

          <hr></hr>

          {userData && userData.role === "Freelancer" && (
            <div
              className="search-bar"
              id="freealert"
              style={{ marginTop: "10px" }}
            >
              <Alert variant="danger">
                Hello buddy! To work as a freelancer, you must contact us @{" "}
                <a href="mailto: hr@falconxx.com">hr@falconxx.com</a> |{" "}
                <a href="tel: 8838173949">+91 8838173949</a>/
                <a href="tel: 8248533577">+91 8248533577</a>
              </Alert>
            </div>
          )}

          <div className="container-fluid">
            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "12px" }}
            >
              <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Invoice</th>
                  <th scope="col">Purpose</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Updated On</th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    Freelancer
                  </th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    Uploaded Invoice
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Upload
                  </th>
                </tr>
              </thead>

              <tbody>
                {reports.length > 0 ? (
                  reports.map((user, index) => (
                    <tr key={user.id}>
                      <td style={{ width: "5vh" }}>{index + 1}</td>
                      <td style={{ minWidth: "13vh" }}>#{user.invoice}</td>
                      <td
                        style={{
                          minWidth: "20vh",
                        }}
                      >
                        {user.purpose}
                      </td>

                      <td style={{ minWidth: "12vh" }}>
                        {user.amount && user.amount.length > 1 ? (
                          <>₹ {user.amount}.00</>
                        ) : (
                          <></>
                        )}
                      </td>

                      <td
                        style={{
                          minWidth: "20vh",
                          color: "green",
                        }}
                      >
                        <b>{user.updateOn && formatTimestamp(user.updateOn)}</b>
                      </td>

                      <td
                        style={{
                          color: "green",
                          minWidth: "10vh",
                          textAlign: "center",
                        }}
                      >
                        {user.adminInvoice && user.adminInvoice.length > 0 ? (
                          <a
                            href={user.adminInvoice}
                            target="_blank"
                            style={{ color: "cornflowerblue" }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: "10vh",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {user.Invoice && user.Invoice.length > 0 ? (
                          <a
                            href={user.Invoice}
                            target="_blank"
                            style={{
                              color: "cornflowerblue",
                              marginRight: "10px",
                            }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red", marginRight: "10px" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>

                      <td style={{ textAlign: "center" }}>
                        <i
                          style={{ cursor: "pointer", color: "green" }}
                          onClick={() => handleReport(user)}
                          className="fa fa-upload"
                        ></i>{" "}
                        {openR && (
                          <ModalReport
                            open={openR}
                            setOpen={setOpenR}
                            // handleDelete={handleDelete}
                            {...modalapplyR}
                          />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      No Invoice Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </main>
      </div>
    </>
  );
}

export default Reports;
