import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import { db, auth } from "../../Firebase-config";
import ModalPartner from "./ModalPartner";
import { onSnapshot } from "firebase/firestore";
import ModalDelete from "./ModalPInviteDelete";
import ModalAdd from "./ModalInvitePartners";
import ModalComment from "../ModalComment";
function Invite() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [availP, setAvail] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const [openD, setOpenD] = useState(false);
  const [modaldelete, setModalDelete] = useState({});

  const handleDelete = (item) => {
    setOpenD(true);
    setModalDelete(item);
  };

  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("PartnerInvites"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setAvail(list);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  //Add Project Modal
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  const [openPr, setOpenPr] = useState(false);
  const [modalPr, setModalApplyPr] = useState({});
  const handleAddPartner = () => {
    setOpenPr(true);
    setModalApplyPr();
  };

  //Coment Box
  //Comment Box

  const [openC, setOpenC] = useState(false);
  const [modalcomment, setModalComment] = useState({});

  const handleComment = (item) => {
    setOpenC(true);
    setModalComment(item);
  };

  ///TimeFormats
  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div>
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Project Dashboard</h1>
        </div>

        <hr></hr>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg">
              <Link to="/partners_list">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Partners
                </Button>
              </Link>

              <div class="ui buttons" style={{ margin: "1px" }}>
                <Button
                  size="small"
                  onClick={() => handleAddPartner()}
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Add Partner
                </Button>
                {openPr && (
                  <ModalPartner
                    open={openPr}
                    setOpen={setOpenPr}
                    // handleDelete={handleDelete}
                    {...modalPr}
                  />
                )}
                <div class="or" data-text="&"></div>
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Partner Project
                </Button>
              </div>

              <Link to="/partner_projects">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Partner Project
                </Button>
              </Link>
              <Button
                size="small"
                color="facebook"
                style={{
                  margin: "1px",
                  fontSize: "11px",
                }}
              >
                Invite Partner
              </Button>
            </div>
          </div>

          <br></br>

          {/* Available Projects*/}

          <Table
            responsive
            striped
            bordered
            hover
            size="sm"
            style={{ fontSize: "12px" }}
          >
            <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Order Code</th>
                <th scope="col">Project Title</th>
                <th scope="col">Partner</th>
                <th scope="col">Posted On</th>
                <th scope="col">Project Deadline</th>
                <th scope="col">Investment Cost</th>
                <th scope="col">Project Cost</th>
                <th scope="col" style={{ textAlign: "center" }}>
                  File
                </th>

                <th scope="col" style={{ textAlign: "center" }}>
                  Status
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {availP.length > 0 ? (
                <>
                  {availP.map((item, index) => (
                    <tr key={item.id}>
                      <th scope="row" style={{ width: "5vh" }}>
                        {index + 1}
                      </th>
                      <td
                        style={{
                          minWidth: "11vh",
                          textTransform: "uppercase",
                        }}
                      >
                        #{item.OrderCode}
                      </td>
                      <td style={{ minWidth: "13vh" }}>{item.title}</td>
                      <td style={{ minWidth: "19vh" }}>
                        <b>[{item.pempId}]</b> {item.pname}
                      </td>

                      <td style={{ minWidth: "21vh" }}>
                        {item.posted && formatTimestamp(item.posted)}
                      </td>
                      <td style={{ minWidth: "21vh", color: "red" }}>
                        <b>
                          {item.deadline && formatTimestamp1(item.deadline)}
                        </b>
                      </td>
                      <td style={{ minWidth: "12vh" }}>
                        {item.cost && item.cost.length > 1 ? (
                          <>₹ {item.cost}.00</>
                        ) : (
                          <></>
                        )}
                      </td>

                      <td style={{ minWidth: "12vh" }}>
                        {item.fcost && item.fcost.length > 1 ? (
                          <>₹ {item.fcost}.00</>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: "5vh",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        <a
                          href={item.fileURL}
                          target="_blank"
                          style={{ color: "cornflowerblue" }}
                        >
                          <i class="fa fa-download"></i>
                        </a>
                      </td>

                      <td
                        style={{
                          minWidth: "10vh",
                          textAlign: "center",
                          color:
                            item.status === "Completed"
                              ? "green"
                              : item.status === "Approved"
                              ? "green"
                              : "orange",
                        }}
                      >
                        <b>{item.status}</b>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <div class="ui buttons">
                          <Button
                            type="button"
                            onClick={() => handleComment(item)}
                            style={{ marginLeft: "3px", fontSize: "11px" }}
                          >
                            Comment
                          </Button>
                          {openC && (
                            <ModalComment
                              open={openC}
                              setOpen={setOpenC}
                              // handleDelete={handleDelete}
                              {...modalcomment}
                            />
                          )}

                          <Button
                            type="button"
                            color="red"
                            onClick={() => handleDelete(item)}
                            style={{ marginLeft: "3px", fontSize: "11px" }}
                          >
                            <i class="fa fa-trash"></i>
                          </Button>
                          {openD && (
                            <ModalDelete
                              open={openD}
                              setOpen={setOpenD}
                              // handleDelete={handleDelete}
                              {...modaldelete}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td
                    colSpan="11"
                    style={{ textAlign: "center", color: "red" }}
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="text-center">
            <Button
              size="small"
              onClick={() => handleAddProject()}
              color="blue"
            >
              Invite Partners
            </Button>
            {openP && (
              <ModalAdd
                open={openP}
                setOpen={setOpenP}
                // handleDelete={handleDelete}
                {...modalapplyP}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Invite;
