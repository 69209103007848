import React, { useState, useEffect } from "react";
import { Button, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { db, auth } from "../Firebase-config";
import { useParams, useNavigate } from "react-router-dom";

import Sidebar from "../Components/Sidebar";
// import GoToTop from "../../GoToTop";
import { doc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
const initialState = {};

const DeliverProject = () => {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [data, setData] = useState(initialState);
  const {
    title,
    words,
    OrderCode,
    subject,
    cost,
    pDesc,
    deadline,
    fileURL,
    applied,
    posted,
  } = data;

  const [fileP, setFileP] = useState(null);
  const [fileF, setFileF] = useState(null);
  const [loaderP, setLoaderP] = useState("");
  const [loaderF, setLoaderF] = useState("");
  const [userDesc, setMsg] = useState("");
  const [userCompleteDesc, setCompleteMsg] = useState("");

  const isPartialDisabled = !fileP;
  const isFinalDisabled = !fileF;

  const [isSubmittedP, setIsSubmittedP] = useState(false);
  const [isSubmittedF, setIsSubmittedF] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    id && getSinglePhoto();
  }, [id]);

  const getSinglePhoto = async () => {
    const docRef = doc(db, "Processing", id);
    const snapshot = await getDoc(docRef, "Processing");
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const today = new Date();
  const chattime =
    today
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getSeconds()
      .toString()
      .padStart(2, "0");
  const current = new Date();

  const chatdate = current
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const handleSubmitPartial = async () => {
    setIsSubmittedP("");
    setLoaderP("Uploading! Please wait...");
    try {
      // Create a storage reference
      const storage = getStorage();
      const storageRef = ref(
        storage,
        "PartialDelivered/" + chattime + fileP.name
      );

      // Upload the file to Firebase Storage
      await uploadBytes(storageRef, fileP);

      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      db.collection("Chats")
        .doc(id)
        .collection("ChatsCollections")
        .doc(`${chatdate}-${chattime}`)
        .set({
          img: userData.img,
          name: userData.name,
          empId: userData.empId,
          contact: userData.contact,
          title,
          words,
          fileURL,
          OrderCode,
          subject,
          cost,
          pDesc,
          delivered: serverTimestamp(),
          chattime: serverTimestamp(),
          userDesc,
          role: userData.role,
          type: "Partial Delivery",

          uid: userId,
          deliverURL: downloadURL,
        });

      // Reset form fields and close the modal
      setFileP(null);
      setLoaderP("");
      setMsg("");
      setIsSubmittedP("Submitted successfully!");
      setTimeout(() => {
        setIsSubmittedP("");
        navigate(-1);
      }, 2000);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  const handleFileChangeP = (e) => {
    const selectedFile = e.target.files[0];
    setFileP(selectedFile);
  };

  const handleSubmitComplete = async () => {
    setIsSubmittedF("");
    setLoaderF("Uploading! Please wait...");
    try {
      // Create a storage reference
      const storage = getStorage();
      const storageRef = ref(storage, "Delivered/" + chattime + fileF.name);

      // Upload the file to Firebase Storage
      await uploadBytes(storageRef, fileF);

      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      db.collection("Chats")
        .doc(id)
        .collection("ChatsCollections")
        .doc(`${chatdate}-${chattime}`)
        .set({
          img: userData.img,
          name: userData.name,
          empId: userData.empId,
          contact: userData.contact,
          OrderCode,
          title,
          words,
          fileURL,
          subject,
          cost,
          pDesc: userCompleteDesc,
          delivered: serverTimestamp(),
          chattime: serverTimestamp(),

          role: userData.role,
          type: "Final Delivery",

          uid: userId,
          deliverURL: downloadURL,
        });

      db.collection("Delivered")
        .doc(id)
        .set({
          img: userData.img,
          name: userData.name,
          empId: userData.empId,
          contact: userData.contact,
          OrderCode,
          title,
          words,
          fileURL,
          subject,
          cost,
          pDesc,
          chattime: serverTimestamp(),
          deadline,
          delivered: serverTimestamp(),
          applied,
          posted,
          userCompleteDesc,
          type: "Complete Delivery",
          paymentStatus: "Pending",

          uid: userId,
          deliverURL: downloadURL,
        });

      db.collection("Processing")
        .doc(id)
        .delete({
          img: userData.img,
          name: userData.name,
          empId: userData.empId,
          contact: userData.contact,
          OrderCode,
          title,
          words,
          fileURL,
          subject,
          cost,
          pDesc,
          applied,
          posted,
          delivered: serverTimestamp(),
          chattime: serverTimestamp(),
          deadline,

          type: "Complete Delivery",
          userCompleteDesc,
          uid: userId,
          deliverURL: downloadURL,
        });

      // Reset form fields and close the modal
      setFileF(null);
      setLoaderF("");
      setCompleteMsg("");
      setIsSubmittedF("Submitted successfully!");
      setTimeout(() => {
        setIsSubmittedF("");
        navigate("/delivered_");
      }, 2000);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  const handleFileChangeF = (e) => {
    const selectedFile = e.target.files[0];
    setFileF(selectedFile);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <div>
      <Sidebar />

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Order Details</h1>
          <hr style={{ width: "22vh", height: "2px" }}></hr>
        </div>

        <div className="container-fluid">
          <div className="row" style={{ fontSize: "12.3px" }}>
            <div className="col-lg-2 my-2">
              <p>
                Project Code: <b>#{OrderCode}</b>
              </p>
            </div>

            <div className="col-lg-3 my-2">
              <p>
                Project Title: <b>{title}</b>
              </p>
            </div>
            <div className="col-lg-3 my-2">
              <p>
                Category: <b>{subject}</b>
              </p>
            </div>

            <div className="col-lg-2 my-2">
              <p>
                Project Cost: <b>₹ {cost}.00</b>
              </p>
            </div>

            <div className="col-lg-2 my-2">
              <p>
                Deadline: <b>{deadline && formatTimestamp1(deadline)}</b>
              </p>
            </div>
            <div className="col-lg-1 my-2">
              <p>Description:</p>
            </div>
            <div className="col-lg-11 my-2">
              <div
                style={{
                  marginBottom: "8px",
                }}
                contentEditable="false"
                dangerouslySetInnerHTML={{
                  __html: `<b>${pDesc}</b>`,
                }}
              >
                {/* <p>Order Code: </p> */}
              </div>
            </div>
          </div>
        </div>

        <hr></hr>

        <div className="container-fluid my-5">
          <div className="row">
            <div className="col-lg-6" style={{ borderRight: "1px solid grey" }}>
              <div className="pagetitle">
                <h3>Partial Solution</h3>
                <hr style={{ width: "22vh", height: "1px" }}></hr>

                <div>
                  <Form>
                    {userData && (
                      <>
                        <Form.Input hidden value={userData.name} />
                        <Form.Input hidden value={userData.empId} />
                        <Form.Input hidden value={userData.img} />
                      </>
                    )}

                    <label style={{ color: "red", fontSize: "10px" }}>
                      Maximum file size should not exceed 10MB
                    </label>
                    <Form.Input
                      type="file"
                      onChange={handleFileChangeP}
                      required={true}
                    />

                    <Form.TextArea
                      name="userDesc"
                      placeholder="Type your comment here..."
                      onChange={(e) => setMsg(e.target.value)}
                      required
                      rows={7}
                      value={userDesc}
                    ></Form.TextArea>

                    {loaderP && (
                      <Alert variant="warning" style={{ textAlign: "center" }}>
                        {loaderP}
                      </Alert>
                    )}

                    {isSubmittedP && (
                      <Alert variant="success" style={{ textAlign: "center" }}>
                        {isSubmittedP}
                      </Alert>
                    )}

                    <Button
                      size="small"
                      disabled={isPartialDisabled}
                      color="facebook"
                      type="submit"
                      onClick={handleSubmitPartial}
                    >
                      Submit
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pagetitle">
                <h3>Complete Solution</h3>
                <hr style={{ width: "22vh", height: "1px" }}></hr>
              </div>
              <Form>
                {userData && (
                  <>
                    <Form.Input hidden value={userData.name} />
                    <Form.Input hidden value={userData.empId} />
                    <Form.Input hidden value={userData.img} />
                  </>
                )}

                <label style={{ color: "red", fontSize: "10px" }}>
                  Maximum file size should not exceed 10MB
                </label>
                <Form.Input
                  type="file"
                  onChange={handleFileChangeF}
                  required={true}
                />

                <Form.TextArea
                  name="userDesc"
                  placeholder="Type your comment here..."
                  onChange={(e) => setCompleteMsg(e.target.value)}
                  required
                  rows={7}
                  value={userCompleteDesc}
                ></Form.TextArea>

                {loaderF && (
                  <Alert variant="warning" style={{ textAlign: "center" }}>
                    {loaderF}
                  </Alert>
                )}

                {isSubmittedF && (
                  <Alert variant="success" style={{ textAlign: "center" }}>
                    {isSubmittedF}
                  </Alert>
                )}

                <Button
                  size="small"
                  color="facebook"
                  disabled={isFinalDisabled}
                  type="submit"
                  onClick={handleSubmitComplete}
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DeliverProject;
