import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import { db, auth } from "../../Firebase-config";
import AdditionalBtns from "./AdditionalBtns";
import ModalDelete from "./ModalDelete";
import ModalAdd from "../ModalAdd";
import ModalComment from "../ModalComment";
function Process() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const [openD, setOpenD] = useState(false);
  const [modaldelete, setModalDelete] = useState({});

  const handleDelete = (item) => {
    setOpenD(true);
    setModalDelete(item);
  };

  // getting Processing projects from firestore collection and updating the state

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("OrderCode");

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await db
        .collection("Processing")
        .orderBy("applied", "desc")
        .get();
      const fetchedData = snapshot.docs.map((doc) => doc.data());
      setData(fetchedData);
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item[searchBy].toLowerCase().includes(searchTerm.toLowerCase())
  );

  //Add Project Modal
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  //Coment Box
  //Comment Box

  const [openC, setOpenC] = useState(false);
  const [modalcomment, setModalComment] = useState({});

  const handleComment = (item) => {
    setOpenC(true);
    setModalComment(item);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setData((prevDeadlines) => {
        return prevDeadlines.map((deadline) => {
          const { days, hours, minutes, seconds } = calculateTimeDifference(
            deadline
          );
          return {
            ...deadline,
            days,
            hours,
            minutes,
            seconds,
          };
        });
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const calculateTimeDifference = (deadline) => {
    const deadlineTime = new Date(deadline.deadline);
    const currentTime = new Date();
    const timeDifference = deadlineTime.getTime() - currentTime.getTime();

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  ///TimeFormats
  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp2 = (chattime) => {
    const date = chattime.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp3 = (applied) => {
    const date = applied.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp5 = (ndeadline) => {
    const date = new Date(ndeadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <div>
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Project Dashboard</h1>
        </div>

        <hr></hr>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg">
              <Link to="/adminPanel">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Available
                </Button>
              </Link>
              <Link to="/adminPanel_invite">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Invitee
                </Button>
              </Link>

              <Link to="/adminPanel_applied">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Applied
                </Button>
              </Link>

              <Button
                color="facebook"
                size="small"
                style={{
                  margin: "1px",
                  fontSize: "11px",
                }}
              >
                In Process
              </Button>
              <Link to="/adminPanel_delivered">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Delivered
                </Button>
              </Link>

              <Link to="/adminPanel_cancelled">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Cancelled
                </Button>
              </Link>

              <Link to="/adminPanel_requests">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Requests
                </Button>
              </Link>

              <Button
                size="small"
                onClick={() => handleAddProject()}
                color="blue"
                style={{ fontSize: "11px", margin: "1px" }}
              >
                Add Project
              </Button>
              {openP && (
                <ModalAdd
                  open={openP}
                  setOpen={setOpenP}
                  // handleDelete={handleDelete}
                  {...modalapplyP}
                />
              )}
              <AdditionalBtns />

              <div className="row" style={{ float: "right" }}>
                <div className="col">
                  <select
                    style={{ height: "4vh" }}
                    className="form-control"
                    value={searchBy}
                    onChange={handleSearchByChange}
                  >
                    <option value="OrderCode">Search by Project Code</option>

                    <option value="subject">Search by Category</option>
                    <option value="name">Search by Name</option>
                  </select>
                </div>
                <div className="col">
                  <div class="ui icon input">
                    <i class="search icon"></i>
                    <input
                      value={searchTerm}
                      onChange={handleSearch}
                      type="text"
                      placeholder="Search..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br></br>

          {/* Available Projects*/}

          <Table
            responsive
            striped
            bordered
            hover
            size="sm"
            style={{ fontSize: "12px" }}
          >
            <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
              <tr>
                <th scope="col">#</th>

                <th scope="col">Order Code</th>

                <th scope="col">Subject</th>

                <th scope="col" style={{ textAlign: "center" }}>
                  File
                </th>
                <th scope="col">Name</th>
                <th scope="col">Contact</th>
                <th scope="col">PostedOn</th>
                <th scope="col">AppliedOn</th>
                <th scope="col">Project Deadline</th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Progress
                </th>

                <th scope="col" style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {data.length > 0 ? (
                <>
                  {filteredData.map((item, index) => (
                    <tr key={item.id}>
                      <th scope="row" style={{ width: "5vh" }}>
                        {index + 1}
                      </th>

                      <td
                        style={{
                          minWidth: "11vh",
                          textTransform: "uppercase",
                        }}
                      >
                        #{item.OrderCode}
                      </td>
                      <td
                        style={{
                          minWidth: "20vh",
                        }}
                      >
                        {item.subject}
                      </td>
                      <td
                        style={{
                          minWidth: "5vh",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {item.fileURL && item.fileURL.length > 0 ? (
                          <a
                            href={item.fileURL}
                            target="_blank"
                            style={{ color: "cornflowerblue" }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: "20vh",
                          textTransform: "uppercase",
                        }}
                      >
                        <b>{item.name}</b>
                      </td>
                      <td
                        style={{
                          width: "12vh",
                          textTransform: "uppercase",
                        }}
                      >
                        {item.contact}
                      </td>
                      <td style={{ minWidth: "22vh" }}>
                        {item.posted && formatTimestamp(item.posted)}
                      </td>
                      <td style={{ minWidth: "22vh" }}>
                        <b>{item.applied && formatTimestamp3(item.applied)}</b>
                      </td>
                      <td style={{ color: "red", minWidth: "22vh" }}>
                        <b>
                          {item.deadline && formatTimestamp1(item.deadline)}
                        </b>
                        <br></br>
                        <b>
                          {item.ndeadline && formatTimestamp5(item.ndeadline)}
                        </b>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          minWidth: "20vh",
                          fontStyle: "italic",
                        }}
                      >
                        <b
                          style={{
                            color:
                              item.days <= 0 &&
                              item.hours <= 0 &&
                              item.minutes <= 30
                                ? "red"
                                : item.days <= 0 &&
                                  item.hours <= 0 &&
                                  item.minutes <= 50
                                ? "orange"
                                : "green",
                          }}
                        >
                          {item.days} Days, {item.hours}h {item.minutes}m{" "}
                          {item.seconds}s
                        </b>
                      </td>

                      <td style={{ textAlign: "center" }}>
                      <div class="ui buttons">
                        <Button
                          type="button"
                          onClick={() => handleComment(item)}
                          style={{ marginLeft: "3px", fontSize: "11px" }}
                        >
                          Comment
                        </Button>
                        {openC && (
                          <ModalComment
                            open={openC}
                            setOpen={setOpenC}
                            // handleDelete={handleDelete}
                            {...modalcomment}
                          />
                        )}


<Button
                            type="button"
                            color="red"
                            onClick={() => handleDelete(item)}
                            style={{ marginLeft: "3px", fontSize: "11px" }}
                          >
                            <i class="fa fa-trash"></i>
                          </Button>
                          {openD && (
                            <ModalDelete
                              open={openD}
                              setOpen={setOpenD}
                              // handleDelete={handleDelete}
                              {...modaldelete}
                            />
                          )}
                            </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td
                    colSpan="11"
                    style={{ textAlign: "center", color: "red" }}
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </main>
    </div>
  );
}

export default Process;
