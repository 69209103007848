import React, { useState } from "react";
import { db } from "../../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { serverTimestamp } from "firebase/firestore";

const ModalRequestEdit = ({
  open,
  setOpen,
  subject,
  pDesc,
  OrderCode,
  deadline,
  title,
  words,
  cost,
  uid,
  idd,
}) => {
  const [updateTitle, setUpdatedTitle] = useState(title);
  const [updateWords, setUpdatedWords] = useState(words);
  const [updateSubject, setUpdatedSubject] = useState(subject);
  const [updatePdesc, setUpdatedPDesc] = useState(pDesc);
  const [updateOrderCode, setUpdatedOrderCode] = useState(OrderCode);
  const [updateDeadline, setUpdatedDeadline] = useState(deadline);
  const [updateCost, setUpdatedCost] = useState(cost);

  const handleSubmit = async () => {
    try {
      // Create a new document in Firestore collection
      await db
        .collection("Available")
        .doc(updateOrderCode)
        .set({
          title: updateTitle,
          words: updateWords,
          OrderCode: updateOrderCode,
          subject: updateSubject,
          pDesc: updatePdesc,
          deadline: updateDeadline,
          cost: updateCost,
          posted: serverTimestamp(),
          uid,
        })
        .then(() => {
          db.collection("ClientsProjects")
            .doc(idd)
            .update({
              status: "Confirmed",
              OrderCode: updateOrderCode,
              pDesc: updatePdesc,
              uid,
              deadline: updateDeadline,
              title: updateTitle,
              words: updateWords,
              cost: updateCost,
            });
        });

      // Reset form fields and close the modal

      setOpen(false);
    } catch (error) {
      console.log("Error updating applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Edit Project
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <Form>
                <Form.Input
                  placeholder="Project Code"
                  label="Project Title"
                  required
                  onChange={(e) => setUpdatedTitle(e.target.value)}
                  defaultValue={title}
                />
                <div className="row">
                  <div className="col-lg">
                    <br></br>
                    <Form.Input
                      placeholder="Project Code"
                      label="Project Code"
                      onChange={(e) => setUpdatedOrderCode(e.target.value)}
                      defaultValue={OrderCode}
                    />
                  </div>
                  <div className="col-lg ">
                    <br></br>
                    <Form.Input
                      placeholder="Word Count"
                      label="Word Count"
                      type="number"
                      onChange={(e) => setUpdatedWords(e.target.value)}
                      defaultValue={words}
                    />
                  </div>
                </div>
                <br></br>
                <Form.Field
                  label="Category"
                  required
                  control="select"
                  placeholder="Category"
                  onChange={(e) => setUpdatedSubject(e.target.value)}
                  value={subject}
                >
                  <option value=" ">--Select Category--</option>

                  <option value="Website Design">Website Design</option>
                  <option value="Website Re-design">Website Re-design</option>
                  <option value="ReactJs Website">ReactJs Website</option>
                  <option value="WordPress Website">WordPress Website</option>

                  <option value="Woo Commerce Website">
                    Woo Commerce Website
                  </option>
                  <option value="Landing Page Webiste">
                    Landing Page Website
                  </option>
                  <option value="Static Website">Static Website</option>
                  <option value="Dynamic Website">Dynamic Website</option>
                  <option value="Sourcing">Sourcing</option>
                  <option value="Data Entry Work">Data Entry Work</option>
                  <option value="Meta Data">Meta Data</option>
                  <option value="Machine Learning">Machine Learning</option>
                  <option value="AI tools Designing">AI tools Designing</option>
                  <option value="Dashboard Development Project">
                    Dashboard Development Project
                  </option>
                  <option value="Python Projects">Python Projects</option>

                  <option value="ERP">ERP</option>
                  <option value="SAP">SAP</option>
                  <option value="Web Scrapping">Web Scrapping</option>
                  <option value="Software Testing">Software Testing</option>
                  <option value="Accounting and Finance">
                    Accounting and Finance
                  </option>
                  <option value="Medical Sciences">Medical Sciences</option>
                  <option value="Design">Design</option>
                  <option value="Video & Audio Editing">
                    Video & Audio Editing
                  </option>
                  <option value="Lifestyle">Lifestyle</option>
                  <option value="Writing & Transalation">
                    Writing & Transalation
                  </option>
                  <option value="Consulting & HR">Consulting & HR</option>

                  <option value="IT Computer Science">
                    IT Computer Science
                  </option>

                  <option value="Engineering">Engineering</option>
                  <option value="Science and Maths">Science and Maths</option>
                  <option value="Law">Law</option>
                  <option value="Statistics">Statistics</option>
                  <option value="General Subjects">General Subjects</option>
                  <option value="Arts and Humanities">
                    Arts and Humanities
                  </option>
                  <option value="Economics">Economics</option>
                  <option value="Management">Management</option>
                  <option value="Graphic Designing">Graphic Designing</option>
                  <option value="Mechanical Design">Mechanical Design</option>
                  <option value="Architectural Design">
                    Architectural Design
                  </option>
                  <option value="Simulation">Simulation</option>
                  <option value="Mechatronics">Mechatronics</option>
                  <option value="Aeronautical Design">
                    Aeronautical Design
                  </option>
                  <option value="ChatGPT/GPT Works">ChatGPT/GPT Works</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Music">Music</option>
                </Form.Field>
                <label>
                  <b>Project Description:</b>
                </label>
                <ReactQuill
                  theme="snow"
                  style={{ height: "15vh" }}
                  defaultValue={pDesc}
                  onChange={setUpdatedPDesc}
                />
                <br></br> <br></br> <br></br> <br></br> <br></br>
                <div className="row" style={{ marginBottom: "10px" }}>
                  <div className="col-lg">
                    <Form.Input
                      name="deadline"
                      required
                      type="datetime-local"
                      defaultValue={deadline}
                      onChange={(e) => setUpdatedDeadline(e.target.value)}
                      label="Projec Deadline"
                    />
                  </div>
                  <div className="col-lg">
                    <Form.Input
                      label="Project Cost"
                      defaultValue={cost}
                      onChange={(e) => setUpdatedCost(e.target.value)}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              color="facebook"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRequestEdit;
