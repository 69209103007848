import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Form, Button, Icon} from "semantic-ui-react";
import ModalAdd from "./ModalAdd";
import Sidebar from "./Sidebar";
function Wallet() {
  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };
  return (
    <>
      {/* For Mobile */}
      <div
        class="offcanvas offcanvas-start w-25"
        tabindex="-1"
        id="offcanvas"
        data-bs-keyboard="false"
        data-bs-backdrop="false"
        style={{backgroundColor: "transparent", border: "none"}}
      >
        <div className="container-fluid" id="mobileSidebar">
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            style={{float: "right"}}
            aria-label="Close"
          >
            <i class="fa fa-close"></i>
          </button>
          <br></br> <br></br>
          <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-item">
              <Link className="nav-link " to="/home">
                <i className="bi bi-grid"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link collapsed" to="/projects">
                <i className="bi bi-layout-text-window-reverse"></i>
                <span>Projects</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </Link>
            </li>

            <li className="nav-item">
              <a
                className="nav-link collapsed"
                data-bs-target="#tables-nav"
                data-bs-toggle="collapse"
              >
                <i className="bi bi-layout-text-window-reverse"></i>
                <span>Reports</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </a>
              {/* <ul
                id="tables-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <a href="tables-general.html">
                    <i className="bi bi-circle"></i>
                    <span>General Tables</span>
                  </a>
                </li>
                <li>
                  <a href="tables-data.html">
                    <i className="bi bi-circle"></i>
                    <span>Data Tables</span>
                  </a>
                </li>
              </ul> */}
            </li>

            <li className="nav-item my-5">
              <Button
                color="blue"
                onClick={() => handleAddProject()}
                style={{marginLeft: "18px"}}
                className="collapsed"
              >
                <span>Create Project</span>
              </Button>
              {openP && (
                <ModalAdd
                  open={openP}
                  setOpen={setOpenP}
                  // handleDelete={handleDelete}
                  {...modalapplyP}
                />
              )}
            </li>
          </ul>
        </div>
      </div>
      {/* For Mobile */}

      <Sidebar />

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>
            <i
              style={{color: "grey"}}
              class="fa fa-bars"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas"
            ></i>{" "}
            My Wallet
          </h1>
        </div>

        <section class="section profile">
          <div className="container-fluid">
            <div className="row section dashboard">
              <div className="col-lg">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-money"></i>
                      </div>
                      <div className="ps-3">
                        <h6>₹ 0.00</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Deposit Amount
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg">
                <div className="card info-card customers-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-hourglass-o"></i>
                      </div>
                      <div className="ps-3">
                        <h6>₹ 0.00</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Withdrawal Amount
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container-fluid">
          <div class="pagetitle my-4">
            <h3>Bank Details</h3>
          </div>

          <div className="container-fluid">
            <div className="card info-card customers-card">
              <div className="card-body">
                <br></br>
                <div className="row">
                  <div className="col-lg my-2">
                    Bank Name: <b>INDIAN BANK</b>
                  </div>
                  <div className="col-lg my-2">
                    Beneficiary Name:{" "}
                    <b>Falcon-X Consulting & Technology Services</b>
                  </div>
                  <div className="col-lg my-2">
                    Account No.: <b>6942020024</b>
                  </div>
                  <div className="col-lg my-2">
                    IFSC Code.: <b>IDIB000V086</b>
                  </div>

                  <div className="col-lg my-2">
                    Swift Code.: <b>IDIBINBB</b>
                  </div>
                </div>
                <hr></hr>

                <div className="row">
                  <div>
                    UPI ID: <b>sat.swm@okaxis</b>
                  </div>
                </div>
                {/* <div className="row">
                    <div className="col-lg">
                      Bank Name: <b>INDIAN BANK</b>
                    </div>
                    <div className="col">
                      Beneficiary Name:{" "}
                      <b>Falcon-X Consulting & Technology Services</b>
                    </div>
                    <div className="col">
                      Account No.: <b>6942020024</b>
                    </div>
                    <div className="col">
                      IFSC Code.: <b>IDIB000V086</b>
                    </div>

                    <div className="col">
                      Swift Code.: <b>IDIBINBB</b>
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg">
              <div class="pagetitle my-4">
                <h3>Add Money</h3>
              </div>
              <div className="container-fluid">
                <Form>
                  <div className="row">
                    <div className="col-lg-9 my-2">
                      <Form.Input type="number" placeholder="₹ 1000.00" />
                    </div>
                    <div className="col-lg-3 my-2">
                      <Button icon labelPosition="left" color="blue">
                        <Icon name="plus" /> Add
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-lg">
              <div class="pagetitle my-4">
                <h3>Withdrawal Money</h3>
              </div>
              <div className="container-fluid">
                <Form>
                  <div className="row">
                    <div className="col-lg-9 my-2">
                      <Form.Field
                        control="select"
                        type="number"
                        placeholder="₹ 1000.00"
                      >
                        <option value="1000">₹ 1000</option>
                        <option value="3000">₹ 3000</option>
                        <option value="5000">₹ 5000</option>
                        <option value="7000">₹ 7000</option>
                        <option value="9000">₹ 9000</option>
                        <option value="11000">₹ 11,000</option>
                        <option value="Full Amount">Full Amount</option>
                      </Form.Field>
                    </div>
                    <div className="col-lg-3 my-2">
                      <Button color="orange">Withdraw</Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Wallet;
