import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Button, Modal } from "semantic-ui-react";

const ModalDeleteInvoice = ({
  open,
  setOpen,
  invoice,
  purpose,
  amount,
  updateOn,
  InvoiceF,
  adminInvoice,
  uid,
  id,
}) => {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const handleSubmitDelete = async () => {
    try {
      // Create a new document in Firestore collection
      await db
        .collection("PartnersReport")
        .doc(id)
        .delete({
          invoice,
          purpose,
          amount,
          updateOn,
          InvoiceF,
          adminInvoice,
          uid,
        });

      // Reset form fields and close the modal

      setOpen(false);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#db2828", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              #{invoice}
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#db2828",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <h5>Are you sure you want to delete invoice</h5>
            </div>
          </div>
          <div class="modal-footer">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button color="red" type="submit" onClick={handleSubmitDelete}>
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeleteInvoice;
