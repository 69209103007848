import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import ModalAdd from "./ModalAdd";
const Sidebar = () => {
  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  return (
    <div>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/home">
              <i class="fa fa-home" aria-hidden="true"></i>
              <span>Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/projects">
              <i class="fa fa-briefcase" aria-hidden="true"></i>
              <span>Projects</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/reports_">
              <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
              <span>Reports</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
          </li>

          <li className="nav-item my-5">
            <Button
              color="facebook"
              style={{
                marginLeft: "18px",

                color: "white",
              }}
              className="collapsed"
              onClick={() => handleAddProject()}
            >
              <span>Post Project</span>
            </Button>
            {openP && (
              <ModalAdd
                open={openP}
                setOpen={setOpenP}
                // handleDelete={handleDelete}
                {...modalapplyP}
              />
            )}
          </li>
          <li
            style={{ bottom: "20px", position: "absolute", fontSize: "12.5px" }}
          >
            <span style={{ color: "grey", fontSize: "11px" }}>VERSION:</span>{" "}
            <b>FXCTS-ADS-v1.01.10</b>
            <br></br>
            <span style={{ fontSize: "11px" }}>
              Copyright <i class="fa fa-copyright" aria-hidden="true"></i> FXCTS
              2023
            </span>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
