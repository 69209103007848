import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";

const ModalStatusHelp = ({ open, setOpen, id, token, adminMsg }) => {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [updateMsg, setUpdatedMsg] = useState(adminMsg);

  const handleSubmit = async () => {
    try {
      await db
        .collection("HelpNeed")
        .doc(id)
        .update({
          adminMsg: updateMsg,
        });

      // Reset form fields and close the modal

      setOpen(false);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Token no: #{token}
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid" style={{ fontSize: "12px" }}>
              <Form>
                <Form.TextArea
                  onChange={(e) => setUpdatedMsg(e.target.value)}
                  label="Enter Message"
                  placeholder="Enter your message..."
                  defaultValue={adminMsg}
                  required={true}
                  rows={6}
                ></Form.TextArea>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button color="facebook" type="submit" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalStatusHelp;
