import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useUserAuth } from "../context/UserAuthContext";
import logoa from "../assets/img/logoa.png";
import { db } from "../Firebase-config";
import bg from "../assets/img/bg.jpg";
import { Button } from "semantic-ui-react";
import { serverTimestamp } from "firebase/firestore";
function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");

  const [img, setImg] = useState("");
  const [empId, setEmployee] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState("");
  const [successmsg, setSuccessMsg] = useState("");
  const [password, setPassword] = useState("");
  const { signUp } = useUserAuth();
  let navigate = useNavigate();

  const today = new Date();
  const chattime =
    today
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getSeconds()
      .toString()
      .padStart(2, "0");

  const current = new Date();

  const chatdate = current
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const handleSubmit = async (e) => {
    setLoader("Creating account....");
    e.preventDefault();
    setError("");
    signUp(email, password)
      .then((credentials) => {
        db.collection("Users")
          .doc(credentials.user.uid)
          .set({
            name: name,
            email: email,
            contact: contact,
            password: password,
            role: "Freelancer",
            empId: "ID not generated!",
            uid: credentials.user.uid,
            createdOn: serverTimestamp(),
            img:
              "https://firebasestorage.googleapis.com/v0/b/falconxdatabase.appspot.com/o/icon.png?alt=media&token=69b2c4b4-caff-4b25-aa31-75179d42a537",

            country: "",
            designation: "",
            address: "",
          })
          .then(() => {
            db.collection("ChatBox")
              .doc(credentials.user.uid)
              .collection("Chats")
              .doc(`${chatdate}-${chattime}`)
              .set({
                message: `Hii ${name}, Welcome to falconx`,
                img:
                  "https://st3.depositphotos.com/8950810/17657/v/450/depositphotos_176577870-stock-illustration-cute-smiling-funny-robot-chat.jpg",

                chattime: serverTimestamp(),
                role: "Bot",
                uid: credentials.user.uid,
              });
          })

          .then(() => {
            setSuccessMsg("Hurray ! Account has been created successfully");
            setLoader("");

            setName("");
            setEmail("");
            setContact("");
            setPassword("");

            setTimeout(() => {
              navigate("/my_profile");
            }, 3000);
          })
          .catch((error) => {
            setError("Oops ! " + error.message);
            setLoader("");
          });
      })
      .catch((error) => {
        setError("Oops !" + error.message);
        setLoader("");
      });
  };
  return (
    <>
      <div
        className="container-fluid"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundImage: `url(${bg})`,
        }}
      >
        <section
          className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4"
          id="bg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <div className="d-flex align-items-center w-auto">
                    <img
                      src={logoa}
                      alt="logo"
                      style={{ height: "12vh", width: "auto" }}
                    />
                    {/* <span className="d-lg-block" style={{ fontSize: "38px" }}>
                      FXCTS
                    </span> */}
                  </div>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <div className=" pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Create an Account
                      </h5>
                      <p className="text-center">
                        <b>Freelancers</b>
                      </p>
                    </div>

                    {successmsg && (
                      <Alert
                        variant="success"
                        style={{ fontSize: "13.5px", textAlign: "center" }}
                      >
                        {successmsg}
                      </Alert>
                    )}
                    {error && (
                      <Alert
                        variant="danger"
                        style={{ fontSize: "13.5px", textAlign: "center" }}
                      >
                        {error}
                      </Alert>
                    )}
                    <form
                      className="row g-3 needs-validation"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-12">
                        <label for="yourName" className="form-label">
                          Your Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="col-12">
                        <label for="yourName" className="form-label">
                          Your Contact
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                          required
                        />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={img}
                        hidden
                        onChange={(e) => setImg(e.target.value)}
                      />

                      <input
                        type="text"
                        hidden
                        className="form-control"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      />

                      <input
                        type="text"
                        hidden
                        className="form-control"
                        value={empId}
                        onChange={(e) => setEmployee(e.target.value)}
                      />
                      <div className="col-12">
                        <label for="yourEmail" className="form-label">
                          Your Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="col-12">
                        <label for="yourPassword" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>

                      <div className="col-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            name="terms"
                            type="checkbox"
                            value=""
                            id="acceptTerms"
                            required
                          />
                          <label className="form-check-label" for="acceptTerms">
                            I agree and accept the{" "}
                            <a href="">terms and conditions</a>
                          </label>
                          <div className="invalid-feedback">
                            You must agree before submitting.
                          </div>
                        </div>
                      </div>

                      {loader && (
                        <p style={{ color: "orange", textAlign: "center" }}>
                          {loader}
                        </p>
                      )}
                      <div className="col-12">
                        <Button
                          color="facebook"
                          className="w-100"
                          type="submit"
                        >
                          Create Account
                        </Button>
                      </div>
                      <div className="col-12" style={{ textAlign: "center" }}>
                        <p className="small mb-0">
                          Already have an account? <Link to="/">Log in</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Register;
