import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

function AdditionalBtns() {
  return (
    <>
      <Link to="/adminPanel_chats">
        <Button
          color="grey"
          size="small"
          style={{
            fontSize: "11px",
            margin: "1px",
          }}
        >
          Chats
        </Button>
      </Link>
      <Link to="/employee_list">
        <Button
          size="small"
          style={{
            fontSize: "11px",
            margin: "1px",
          }}
        >
          Employees
        </Button>
      </Link>
      <Link to="/partners_list">
        <Button
          size="small"
          style={{
            fontSize: "11px",
            margin: "1px",
          }}
        >
          Partners
        </Button>
      </Link>
    </>
  );
}

export default AdditionalBtns;
