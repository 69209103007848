import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../Firebase-config";
import { Button, Form } from "semantic-ui-react";
import Sidebar from "./Sidebar";
import { Alert, Table } from "react-bootstrap";
import { serverTimestamp } from "firebase/firestore";
function NeedHelp() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState("");
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  function generateUniqueId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uniqueId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters.charAt(randomIndex);
    }

    return uniqueId;
  }

  const uniqueId = generateUniqueId(5);

  const [type, setType] = useState("");
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState("");
  const [success, setsuccessLoader] = useState("");

  const current = new Date();

  const chatdate = current
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");
  const handleSubmit = async () => {
    setLoader("Sending Help Request ! Please wait...");
    setsuccessLoader("");

    try {
      db.collection("HelpNeed")
        .doc(`FX${uniqueId}`)
        .set({
          empId: userData.empId,
          name: userData.name,
          email: userData.email,
          contact: userData.contact,
          uid: userId,
          role: userData.role,
          type,
          query,
          sort: chatdate,
          status: "InProcess",
          token: `FX${uniqueId}`,
          posted: serverTimestamp(),
        });

      // Reset form fields and close the modal
      // Reset form fields and close the modal
      setLoader("");
      setsuccessLoader("Help request sent successfully!");
      setType("");
      setQuery("");
      setTimeout(() => {
        setLoader("");
        setsuccessLoader("");
      }, 4000);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  const [tokenn, setToken] = useState([]);
  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db
        .collection("HelpNeed")
        .where("uid", "==", uid)
        .where("status", "==", "InProcess");

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setToken(documents);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setToken([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <>
      <Fragment>
        {/* For Mobile */}
        <div
          class="offcanvas offcanvas-start w-25"
          tabindex="-1"
          id="offcanvas"
          data-bs-keyboard="false"
          data-bs-backdrop="false"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <div className="container-fluid" id="mobileSidebar">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              style={{ float: "right" }}
              aria-label="Close"
            >
              <i class="fa fa-close"></i>
            </button>
            <br></br> <br></br>
            <ul className="sidebar-nav" id="sidebar-nav">
              <li className="nav-item">
                <Link className="nav-link " to="/home">
                  <i className="bi bi-grid"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link collapsed" to="/projects">
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Projects</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#tables-nav"
                  data-bs-toggle="collapse"
                >
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Reports</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* For Mobile */}
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              <i
                style={{ color: "grey" }}
                class="fa fa-bars"
                id="sidebarBtn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
              ></i>
              Need Help ?
            </h1>
          </div>
          {/* <!-- End Page Title --> */}

          <section className="section dashboard">
            {/* <!-- Left side columns --> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg my-2">
                  <h5>
                    Name:{" "}
                    <span style={{ color: "#2185d0" }}>{userData.name}</span>
                  </h5>
                </div>

                <div className="col-lg my-2">
                  <h5>
                    Employee ID:{" "}
                    <span style={{ color: "#2185d0" }}>{userData.empId}</span>
                  </h5>
                </div>

                <div className="col-lg my-2">
                  <h5>
                    Designation:{" "}
                    <span style={{ color: "#2185d0" }}>
                      {userData.designation}
                    </span>
                  </h5>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-lg-5 my-4">
                  <Form>
                    <Form.Field
                      control="select"
                      onChange={(e) => setType(e.target.value)}
                      value={type}
                      label="Help Type"
                    >
                      <option value=" ">--Select--</option>
                      <option value="Dashboard Issue">Dashboard Issue</option>
                      <option value="Project Related">Project Related</option>
                      <option value="Invoice Related">Invoice Related</option>
                      <option value="Penalty Related">Penalty Related</option>
                      <option value="Refund Related">Refund Related</option>
                      <option value="Profile Related">Profile Related</option>
                      <option value="Bank Details Related">
                        Bank Details Related
                      </option>
                      <option value="Technical Support">
                        Technical Support
                      </option>
                      <option value="Skill Test Related Issue">
                        Skill Test Related Issue
                      </option>
                      <option value="Others">Others</option>
                    </Form.Field>

                    <Form.TextArea
                      label="How can we help you?"
                      placeholder="Comment your query..."
                      onChange={(e) => setQuery(e.target.value)}
                      value={query}
                      rows={6}
                    ></Form.TextArea>
                    {loader && (
                      <Alert
                        variant="warning"
                        style={{ fontSize: "11px", textAlign: "center" }}
                      >
                        {loader}
                      </Alert>
                    )}
                    {success && (
                      <Alert
                        variant="success"
                        style={{ fontSize: "11px", textAlign: "center" }}
                      >
                        {success}
                      </Alert>
                    )}

                    {type.length > 1 && query.length > 3 ? (
                      <>
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          size="small"
                          color="facebook"
                        >
                          Ask Help
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          disabled
                          size="small"
                          color="facebook"
                        >
                          Ask Help
                        </Button>
                      </>
                    )}
                  </Form>
                </div>
                <div className="col-lg-7 my-4">
                  <b>Active Help Requests</b>

                  <div className="row my-3">
                    {tokenn.length > 0 ? (
                      tokenn.map((user) => (
                        <div className="col-lg-6" key={user.id}>
                          <div class="card">
                            <div class="card-body">
                              <a
                                style={{
                                  position: "absolute",
                                  right: "0",
                                  color: "white",
                                  backgroundColor:
                                    user.status === "InProcess"
                                      ? "orange"
                                      : user.status === "Completed"
                                      ? "green"
                                      : "red",
                                }}
                                class="ui tag label"
                              >
                                {user.status}
                              </a>
                              <h5
                                style={{ marginTop: "-5px" }}
                                class="card-title"
                              >
                                #{user.token}
                              </h5>

                              <p
                                class="card-text"
                                style={{ marginTop: "-15px" }}
                              >
                                <b>{user.type}</b>
                                <br></br>
                                {user.query}
                              </p>

                              <a
                                data-bs-toggle="collapse"
                                href="#collapseExample"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                              >
                                Read Message
                              </a>

                              <div class="collapse" id="collapseExample">
                                {user.adminMsg}
                              </div>
                            </div>
                            <div class="card-footer">
                              <small class="text-muted">
                                Posted on:{" "}
                                {user.posted && formatTimestamp(user.posted)}
                              </small>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div style={{ color: "red" }}>No Data Found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Fragment>
    </>
  );
}

export default NeedHelp;
