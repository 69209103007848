import React, { useState, useEffect } from "react";
import { Button, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { db, auth } from "../Firebase-config";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import GoToTop from "../../GoToTop";
import { doc, getDoc } from "firebase/firestore";

const initialState = {};

const DeliverProject = () => {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [data, setData] = useState(initialState);
  const {
    OrderCode,
    subject,
    cost,
    pDesc,
    fileURL,
    deadline,
    deadlineTime,
    paymentStatus,
    chatdate,
    chattime,
    empId,
    userCompleteDesc,
    name,
  } = data;
  const [progressP, setProgressP] = useState(null);
  const [errors, setErrors] = useState({});
  const [loaderP, setLoaderP] = useState("");
  const [loaderF, setLoaderF] = useState("");
  const [userDesc, setMsg] = useState("");

  const [isSubmittedP, setIsSubmittedP] = useState(false);
  const [isSubmittedF, setIsSubmittedF] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    id && getSinglePhoto();
  }, [id]);

  const getSinglePhoto = async () => {
    const docRef = doc(db, "Delivered", id);
    const snapshot = await getDoc(docRef, "Delivered");
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const handleSubmitPartial = async () => {
    setIsSubmittedP("");
    setLoaderP("Uploading! Please wait...");
    try {
      db.collection("Chats").doc(id).set({
        uid: userId,
      });

      // Reset form fields and close the modal
      setLoaderP("");
      setMsg("");
      setIsSubmittedP("Submitted successfully!");
      setTimeout(() => {
        setIsSubmittedP("");
      }, 4000);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  return (
    <div>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/home">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link " to="/projects">
              <i className="bi bi-layout-text-window-reverse"></i>
              <span>Orders</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#tables-nav"
              data-bs-toggle="collapse"
              href="/"
            >
              <i className="bi bi-layout-text-window-reverse"></i>
              <span>Reports</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="tables-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <a href="tables-general.html">
                  <i className="bi bi-circle"></i>
                  <span>General Tables</span>
                </a>
              </li>
              <li>
                <a href="tables-data.html">
                  <i className="bi bi-circle"></i>
                  <span>Data Tables</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </aside>

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Delivered Project</h1>
          <hr style={{ width: "22vh", height: "2px" }}></hr>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 my-2">
              <p>
                Project Code: <b>#{OrderCode}</b>{" "}
              </p>
            </div>
            <div className="col-lg-3 my-2">
              <p>
                Subject: <b>{subject}</b>{" "}
              </p>
            </div>

            <div className="col-lg-3 my-2">
              <p>
                Deadline:{" "}
                <b style={{ color: "red" }}>
                  {/* {format(new Date(deadline), "MMM dd, yyyy")};  */}
                  {deadline}; {deadlineTime} IST
                </b>
              </p>
            </div>

            <div className="col-lg-3 my-2">
              <p>
                Project Cost: <b>₹ {cost}</b>
              </p>
            </div>
            <div className="col-lg-3 my-2">
              <p>
                Delivered Date:{" "}
                <b style={{ color: "green" }}>
                  {chatdate}; {chattime} IST
                </b>
              </p>
            </div>
            <div className="col-lg-3 my-2">
              <p>
                Payment Status:{" "}
                <b
                  style={{
                    color:
                      paymentStatus === "Pending"
                        ? "orange"
                        : "Completed"
                        ? "green"
                        : "red",
                  }}
                >
                  {paymentStatus}
                </b>{" "}
              </p>
            </div>
            <div className="col-lg-3 my-2">
              <p>
                Emp ID: <b>{empId}</b>
              </p>
            </div>

            <div className="col-lg-3 my-2">
              <p>
                Name: <b>{name}</b>
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid my-5">
          <div className="row">
            <div className="col-lg-6" style={{ borderRight: "1px solid grey" }}>
              <div className="pagetitle">
                <h3>Delivery Details</h3>
                <hr style={{ width: "22vh", height: "1px" }}></hr>

                <div>
                  <h5>
                    Delivery Description:{" "}
                    <span style={{ color: "grey", fontWeight: "normal" }}>
                      {userCompleteDesc}
                    </span>
                  </h5>

                  <h5>
                    Delivery Document:{" "}
                    <a href={fileURL} target="_blank_">
                      <Button color="orange">
                        Download File <i class="fa fa-download"></i>
                      </Button>
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pagetitle">
                <h3>Status Panel</h3>
                <hr style={{ width: "22vh", height: "1px" }}></hr>
              </div>
              <Form>
                {loaderF && (
                  <Alert variant="warning" style={{ textAlign: "center" }}>
                    {loaderF}
                  </Alert>
                )}

                {isSubmittedF && (
                  <Alert variant="success" style={{ textAlign: "center" }}>
                    {isSubmittedF}
                  </Alert>
                )}

                <Form.Field control="select" label="Payment Status">
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                  <option value="On Hold">On Hold</option>
                </Form.Field>

                {userCompleteDesc && userCompleteDesc.length >= 5 ? (
                  <Button
                    size="small"
                    color="blue"
                    type="submit"
                    onClick={handleSubmitPartial}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button size="small" disabled color="blue">
                    Submit
                  </Button>
                )}

                <Button
                  size="small"
                  color="red"
                  type="submit"
                  onClick={handleSubmitPartial}
                >
                  Re-open
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DeliverProject;
