import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import { db, auth } from "../../Firebase-config";
import AdditionalBtns from "./AdditionalBtns";
import ModalAdd from "../ModalAdd";
import ModalChat from "../ModalChat";
function Chats() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const navigate = useNavigate();
  const [openP, setOpenP] = useState(false);
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  //Chats
  const [openChat, setOpenChat] = useState(false);
  const [modalchat, setModalChat] = useState({});

  const handleChat = (item) => {
    setOpenChat(true);
    setModalChat(item);
  };

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("name");

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await db
        .collection("ChatBox")
        .orderBy("chattime", "desc")
        .get();
      const fetchedData = snapshot.docs.map((doc) => doc.data());
      setData(fetchedData);
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const filteredData = data.filter((chat) =>
    chat[searchBy].toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatTimestamp2 = (chattime) => {
    const date = chattime.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };



  return (
    <div>
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Project Dashboard</h1>
        </div>

        <hr></hr>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg">
              <Link to="/adminPanel">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Available
                </Button>
              </Link>

              <Link to="/adminPanel_invite">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Invitee
                </Button>
              </Link>

              <Link to="/adminPanel_applied">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Applied
                </Button>
              </Link>

              <Link to="/adminPanel_process">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  In Process
                </Button>
              </Link>

              <Link to="/adminPanel_delivered">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Delivered
                </Button>
              </Link>

              <Button
                size="small"
                style={{
                  fontSize: "11px",
                  margin: "1px",
                }}
              >
                Cancelled
              </Button>

              <Link to="/adminPanel_requests">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Requests
                </Button>
              </Link>

              <Button
                size="small"
                onClick={() => handleAddProject()}
                color="blue"
                style={{ fontSize: "11px", margin: "1px" }}
              >
                Add Project
              </Button>
              {openP && (
                <ModalAdd
                  open={openP}
                  setOpen={setOpenP}
                  // handleDelete={handleDelete}
                  {...modalapplyP}
                />
              )}

              <AdditionalBtns />

              <div className="row" style={{ float: "right" }}>
                <div className="col">
                  <select
                    style={{ height: "4vh" }}
                    className="form-control"
                    value={searchBy}
                    onChange={handleSearchByChange}
                  >
                    <option value="name">Search by Name</option>
                    <option value="empId">Search by Employee ID</option>
                  </select>
                </div>
                <div className="col">
                  <div class="ui icon input">
                    <i class="search icon"></i>
                    <input
                      value={searchTerm}
                      onChange={handleSearch}
                      type="text"
                      placeholder="Search..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br></br>

          {/* Applied Projects*/}
        
          <Table
            responsive
            striped
            bordered
            hover
            size="sm"
            style={{ fontSize: "12px" }}
          >
            <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
              <tr>
                <th scope="col">#</th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Profile
                </th>
                <th scope="col">Emp ID</th>
                <th scope="col">Name</th>
                <th scope="col">Role</th>

                <th scope="col">Last Message</th>
                <th scope="col">Message Time</th>

                <th scope="col" style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((chat, index) => (
                <tr key={chat.id}>
                  <th scope="row" style={{ width: "5vh" }}>
                    {index + 1}
                  </th>
                  <td style={{ minWidth: "12px", textAlign: "center" }}>
                    <img
                      alt="img"
                      className="rounded-circle"
                      src={chat.img}
                      style={{
                        width: "45px",
                        height: "45px",

                        objectFit: "cover",
                        opacity: chat.sender === "Anew" ? "100%" : "30%",
                      }}
                    />
                  </td>
                  <td
                    style={{
                      minWidth: "11vh",
                      color: chat.sender === "Anew" ? "#000" : "#b8b8b8",
                    }}
                  >
                    {chat.empId}
                  </td>
                  <td
                    style={{
                      minWidth: "21vh",
                      textTransform: "uppercase",
                      color: chat.sender === "Anew" ? "#000" : "#b8b8b8",
                    }}
                  >
                    <b>{chat.name}</b>
                  </td>
                  <td
                    style={{
                      minWidth: "12vh",

                      color: chat.sender === "Anew" ? "#000" : "#b8b8b8",
                    }}
                  >
                    {chat.role}
                  </td>

                  <td
                    style={{
                      minWidth: "20vh",
                      color: chat.sender === "Anew" ? "#000" : "#b8b8b8",
                    }}
                  >
                    <b>{chat.message}</b>
                  </td>
                  <td
                    style={{
                      minWidth: "22vh",
                      color: chat.sender === "Anew" ? "#000" : "#b8b8b8",
                    }}
                  >
                    <b>{chat.chattime && formatTimestamp2(chat.chattime)}</b>
                  </td>

                  <td style={{ textAlign: "center" }}>
                    <Button
                      type="button"
                      onClick={() => handleChat(chat)}
                      style={{
                        marginLeft: "3px",
                        fontSize: "11px",
                        color: "white",
                        backgroundColor:
                          chat.sender === "Anew" ? "#00d419" : "#b8b8b8",
                      }}
                    >
                      <i class="fa fa-comment"></i> Chat
                    </Button>
                    {openChat && (
                      <ModalChat
                        open={openChat}
                        setOpen={setOpenChat}
                        // handleDelete={handleDelete}
                        {...modalchat}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </main>
    </div>
  );
}

export default Chats;
