import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../Firebase-config";
import { Button } from "semantic-ui-react";
import Sidebar from "./Sidebar";
import ModalAdd from "./ModalAdd";
function Home() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [availP, setAvail] = useState([]);

  // getting Available projects from firestore collection and updating the state
  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("CollectApplied").where("uid", "==", uid);

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAvail(documents);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setAvail([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const [docCount, setDocCount] = useState(null);
  const [error, setError] = useState(null);

  //Available Project
  useEffect(() => {
    const collectionRef = db.collection("Available");

    collectionRef
      .get()
      .then((querySnapshot) => {
        setDocCount(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      });
  });

  //Delivered Project
  const [deliveredCount, setDeliveredCount] = useState(null);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("Delivered").where("uid", "==", uid);

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const count = querySnapshot.size; // Get the count of documents
          setDeliveredCount(count);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setDeliveredCount(0); // Set count to 0 when there is no user
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  ///Earning
  const [earning, setEarning] = useState("");
  let totalPrice = 0;
  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("Payments").where("uid", "==", uid);

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          totalPrice = 0;

          querySnapshot.forEach((doc) => {
            const price = doc.data().cost;
            totalPrice -= price * -1;
          });

          setEarning(totalPrice);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setEarning(0); // Set earning to 0 when there is no user
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  //Applied Project
  const [appliedCount, setAppliedCount] = useState(null);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("Applied").where("uid", "==", uid);

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const count = querySnapshot.size; // Get the count of documents
          setAppliedCount(count);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setAppliedCount(0); // Set count to 0 when there is no user
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const TotalProjects = appliedCount + deliveredCount;

  const [payments, setPay] = useState([]);

  // getting Available projects from firestore collection and updating the state
  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("Payments").where("uid", "==", uid);

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPay(documents);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setPay([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  const formatTimestamp = (posted) => {
    const date = posted.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp3 = (paymentDate) => {
    const date = paymentDate.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <>
      <Fragment>
        {/* For Mobile */}
        <div
          class="offcanvas offcanvas-start w-25"
          tabindex="-1"
          id="offcanvas"
          data-bs-keyboard="false"
          data-bs-backdrop="false"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <div className="container-fluid" id="mobileSidebar">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              style={{ float: "right" }}
              aria-label="Close"
            >
              <i class="fa fa-close"></i>
            </button>
            <br></br> <br></br>
            <ul className="sidebar-nav" id="sidebar-nav">
              <li className="nav-item">
                <Link className="nav-link " to="/home">
                  <i className="bi bi-grid"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link collapsed" to="/projects">
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Projects</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#tables-nav"
                  data-bs-toggle="collapse"
                >
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Reports</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                {/* <ul
                id="tables-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <a href="tables-general.html">
                    <i className="bi bi-circle"></i>
                    <span>General Tables</span>
                  </a>
                </li>
                <li>
                  <a href="tables-data.html">
                    <i className="bi bi-circle"></i>
                    <span>Data Tables</span>
                  </a>
                </li>
              </ul> */}
              </li>

              <li className="nav-item my-5">
                <Button
                  color="facebook"
                  onClick={() => handleAddProject()}
                  style={{ marginLeft: "18px" }}
                  className="collapsed"
                >
                  <span>Create Project</span>
                </Button>
                {openP && (
                  <ModalAdd
                    open={openP}
                    setOpen={setOpenP}
                    // handleDelete={handleDelete}
                    {...modalapplyP}
                  />
                )}
              </li>
            </ul>
          </div>
        </div>

        {/* For Mobile */}
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              {" "}
              <i
                style={{ color: "grey" }}
                class="fa fa-bars"
                id="sidebarBtn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
              ></i>{" "}
              Dashboard
            </h1>
          </div>
          {/* <!-- End Page Title --> */}

          <section className="section dashboard">
            {/* <!-- Left side columns --> */}

            <div className="row">
              {/* <!-- Sales Card --> */}
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Sales <span>| Today</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-briefcase"></i>
                      </div>
                      <div className="ps-3">
                        <h6> {docCount}</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Available Projects
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Sales Card -->

        <!-- Revenue Card --> */}
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Revenue <span>| This Month</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-rocket"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{deliveredCount}</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Delivered Orders
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Revenue Card -->

        <!-- Customers Card --> */}
              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card customers-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-lock"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{appliedCount}</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Applied Projects
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-sort-alpha-asc"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{TotalProjects}</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Total Projects
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-credit-card"></i>
                      </div>
                      <div className="ps-3">
                        <h6>₹ {earning}.00</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Project Earnings
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-credit-card"></i>
                      </div>
                      <div className="ps-3">
                        <h6>₹ 0.00</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Invoice Earnings
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card customers-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-money"></i>
                      </div>
                      <div className="ps-3">
                        <h6>₹ 0.00</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Penalty Charges
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-hourglass"></i>
                      </div>
                      <div className="ps-3">
                        <h6>₹ 0.00</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Wallet Amount
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="card recent-sales overflow-auto">
                  <div className="card-body">
                    <h5 className="card-title">Projects</h5>

                    <table className="table table-borderless datatable my-2">
                      <thead style={{ fontSize: "12px" }}>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Project Code</th>
                          <th scope="col">Subject</th>
                          <th scope="col">Project Cost</th>
                          <th scope="col">Deadline</th>
                          <th scope="col">Posted On</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {availP.length > 0 ? (
                          <>
                            {availP.map((user, index) => (
                              <tr key={user.id}>
                                <th scope="row" style={{ width: "5vh" }}>
                                  {index + 1}
                                </th>
                                <td
                                  style={{
                                    minWidth: "12vh",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  #{user.OrderCode}
                                </td>
                                <td
                                  style={{
                                    minWidth: "19vh",
                                  }}
                                >
                                  {user.subject}
                                </td>
                                <td style={{ minWidth: "14vh" }}>
                                  ₹ {user.cost}.00
                                </td>

                                <td style={{ minWidth: "30vh", color: "red" }}>
                                  <b>
                                    {user.deadline &&
                                      formatTimestamp1(user.deadline)}
                                  </b>
                                </td>

                                <td style={{ minWidth: "30vh" }}>
                                  {user.posted && formatTimestamp(user.posted)}
                                </td>

                                <td style={{ width: "14vh" }}>
                                  <p
                                    style={{
                                      color: "white",
                                      fontWeight: "bold",
                                      borderRadius: "5px",
                                      textAlign: "center",
                                      padding: "5px",
                                      backgroundColor:
                                        user.Appliedstatus === "Approved"
                                          ? "#54D62C"
                                          : user.Appliedstatus === "Rejected"
                                          ? "red"
                                          : user.Appliedstatus === "Cancelled"
                                          ? "orange"
                                          : user.Appliedstatus === "Delivered"
                                          ? "green"
                                          : user.Appliedstatus ===
                                            "Cancelled (Penalty)"
                                          ? "red"
                                          : "#ffca1c",
                                    }}
                                  >
                                    {user.Appliedstatus}
                                  </p>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td
                              colSpan="8"
                              style={{
                                textAlign: "center",
                                color: "red",
                              }}
                            >
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!-- End Recent Sales --> */}
              <div className="col-12">
                <div className="card recent-sales overflow-auto">
                  <div className="card-body">
                    <h5 className="card-title">Payment History</h5>

                    <table className="table table-borderless datatable my-2">
                      <thead style={{ fontSize: "12px" }}>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Project Code</th>
                          <th scope="col">Subject</th>
                          <th scope="col">Project Cost</th>

                          <th scope="col">Payment Date</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {payments.length > 0 ? (
                          <>
                            {payments.map((user, index) => (
                              <tr key={user.id}>
                                <th scope="row" style={{ width: "5vh" }}>
                                  {index + 1}
                                </th>
                                <td
                                  style={{
                                    minWidth: "12vh",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  #{user.OrderCode}
                                </td>
                                <td
                                  style={{
                                    minWidth: "19vh",
                                  }}
                                >
                                  {user.subject}
                                </td>
                                <td style={{ minWidth: "14vh" }}>
                                  ₹ {user.cost}.00
                                </td>

                                <td style={{ minWidth: "30vh" }}>
                                  {user.paymentDate &&
                                    formatTimestamp3(user.paymentDate)}
                                </td>

                                <td style={{ width: "14vh" }}>
                                  <p
                                    style={{
                                      color: "white",
                                      fontWeight: "bold",
                                      borderRadius: "5px",
                                      textAlign: "center",
                                      padding: "5px",
                                      backgroundColor:
                                        user.paymentStatus === "On Hold"
                                          ? "#ff841f"
                                          : user.paymentStatus === "Cancelled"
                                          ? "#FF4842"
                                          : user.paymentStatus === "Paid"
                                          ? "green"
                                          : "orange",
                                    }}
                                  >
                                    {user.paymentStatus}
                                  </p>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td
                              colSpan="8"
                              style={{
                                textAlign: "center",
                                color: "red",
                              }}
                            >
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Fragment>
    </>
  );
}

export default Home;
