import React, { useState, useEffect } from "react";
import { db, auth } from "../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { serverTimestamp } from "firebase/firestore";
const ModalCancelProcess = ({
  open,
  setOpen,
  subject,
  title,
  words,
  fileURL,
  pDesc,
  OrderCode,
  deadline,
  cost,
  posted,
  applied,
  id,
}) => {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [userDesc, setUserDesc] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const handleSubmit = async () => {
    try {
      // Create a new document in Firestore collection
      await db
        .collection("Cancelled")
        .doc(id)
        .set({
          OrderCode,
          subject,
          pDesc,
          deadline,
          title,
          words,
          fileURL,
          cost,
          name: userData.name,
          email: userData.email,
          contact: userData.contact,
          designation: userData.designation,
          userDesc,
          applied,
          posted,
          uid: userId,
          cancelled: serverTimestamp(),
        })
        .then(() => {
          db.collection("Processing")
            .doc(id)
            .delete({
              OrderCode,
              subject,
              pDesc,
              deadline,
              title,
              words,
              fileURL,
              cost,
              name: userData.name,
              email: userData.email,
              contact: userData.contact,
              designation: userData.designation,
              userDesc,
              applied,
              posted,
              uid: userId,
            })
            .then(() => {
              db.collection("Available")
                .doc(id)
                .set({
                  title,
                  words,
                  fileURL,
                  OrderCode,
                  subject,
                  pDesc,
                  deadline,
                  cost,
                  posted,
                })
                .then(() => {
                  db.collection("CollectApplied")
                    .doc(id)
                    .set({
                      OrderCode,
                      title,
                      words,
                      fileURL,
                      subject,
                      pDesc,
                      deadline,
                      cost,
                      name: userData.name,
                      email: userData.email,
                      contact: userData.contact,
                      designation: userData.designation,
                      userDesc,
                      applied,
                      posted,
                      cancelled: serverTimestamp(),
                      uid: userId,
                      Appliedstatus: "Cancelled (Penalty)",
                    });
                });
            });
        });

      // Reset form fields and close the modal
      setUserDesc("");
      setOpen(false);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "red", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Confirm Cancellation
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "red",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <div class="container-fluid" style={{ fontSize: "12px" }}>
                <div class="row">
                  <div class="col-lg-5 my-2">
                    Project Code: <b style={{ color: "black" }}>#{OrderCode}</b>
                  </div>
                  <div class="col-lg-7 my-2">
                    Project Deadline:{" "}
                    <b style={{ color: "black" }}>
                      {deadline && formatTimestamp1(deadline)}
                    </b>
                  </div>
                  <div class="col-lg-5 my-2">
                    Project Amount:{" "}
                    <b style={{ color: "black" }}>
                      {" "}
                      {cost && cost.length > 1 ? <>₹ {cost}.00</> : <></>}
                    </b>
                  </div>
                  <div class="col-lg-7 my-2">
                    Subject: <b>{subject}</b>
                  </div>
                  <div class="col-lg-2 my-2">Description: </div>
                  <div class="col-lg-10">
                    <b>
                      <div
                        style={{
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: `${pDesc}`,
                        }}
                      ></div>
                    </b>
                  </div>
                </div>
              </div>
              <br></br> <br></br>
              <span style={{ color: "red", fontSize: "12px" }}>
                Note:{" "}
                <b>Canceling this project will result in a penalty charge.</b>
              </span>
              <Form>
                <Form.Input hidden value={OrderCode} />
                <Form.Input hidden value={subject} />
                <Form.Input hidden value={pDesc} />
                <Form.Input hidden value={deadline} />

                <Form.Input hidden value={cost} />
                <Form.Input hidden value={userId} />
                {userData && (
                  <>
                    <Form.Input hidden value={userData.name} />
                    <Form.Input hidden value={userData.contact} />
                    <Form.Input hidden value={userData.email} />
                    <Form.Input hidden value={userData.designation} />
                  </>
                )}
                <Form.TextArea
                  name="userDesc"
                  hidden
                  placeholder="Brief Description"
                  onChange={(e) => setUserDesc(e.target.value)}
                  required
                ></Form.TextArea>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button size="small" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              size="small"
              color="red"
              type="submit"
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCancelProcess;
