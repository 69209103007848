import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { Button } from "semantic-ui-react";
import logoa from "../assets/img/logoa.png";
import { useUserAuth } from "../context/UserAuthContext";
import { Link } from "react-router-dom";
import { auth, db } from "../Firebase-config";
import bg from "../assets/img/bg.jpg";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [successmsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  //Users Data
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Partners").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Freelancer Login
  const handleSubmitPartner = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    logIn(email, password)
      .then(() => {
        if (userData && userData.status === "Approved") {
          setSuccessMsg("Hurray ! Credentials verified. Redirecting...");

          setTimeout(() => {
            setSuccessMsg("");
            setLoading(false);
            navigate("/partnerDashboard");
          }, 3000);
        } else {
          setError("");
          // Show alert for admins and moderators
          setError("Alert! You are not authorized");

          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError("Oops ! " + error.message);
      });
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundImage: `url(${bg})`,
        }}
      >
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <div className="d-flex align-items-center w-auto">
                    <img
                      src={logoa}
                      alt="logo"
                      style={{ height: "12vh", width: "auto" }}
                    />
                    {/* <span className="d-lg-block" style={{ fontSize: "38px" }}>
                      FXCTS
                    </span> */}
                  </div>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <div
                      className="row my-2"
                      style={{
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      <div
                        className="col"
                        style={{
                          backgroundColor: "#3b5998",
                          padding: "10px",
                          cursor: "pointer",

                          margin: "3px",
                        }}
                      >
                        <Link to="/">
                          <div style={{ color: "white" }}>Freelancer</div>
                        </Link>
                      </div>
                      <div
                        className="col"
                        style={{
                          backgroundColor: "rgb(59 89 152 / 52%)",
                          padding: "10px",
                          cursor: "pointer",
                          margin: "3px",
                        }}
                      >
                        Partner
                      </div>
                    </div>

                    <div className="container-fluid">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Login to Partner Portal
                      </h5>
                      <br></br>
                    </div>
                    {successmsg && (
                      <Alert
                        variant="success"
                        style={{ fontSize: "13.5px", textAlign: "center" }}
                      >
                        {successmsg}
                      </Alert>
                    )}
                    {error && (
                      <Alert
                        variant="danger"
                        style={{ fontSize: "13.5px", textAlign: "center" }}
                      >
                        {error}
                      </Alert>
                    )}

                    <form
                      onSubmit={handleSubmitPartner}
                      className="row g-3 needs-validation"
                      // onSubmit={handleSubmit}
                    >
                      <div className="col-12">
                        <label for="yourUsername" className="form-label">
                          Email
                        </label>
                        <div className="input-group has-validation">
                          <input
                            type="email"
                            placeholder="Enter your email"
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <label for="yourPassword" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          placeholder="Enter your password"
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          required
                        />
                      </div>

                      <div className="col-12">
                        <Link to="/fpassword_auth">Forgot Password</Link>
                      </div>

                      <div className="col-12">
                        {loading === true ? (
                          <>
                            <button class="ui loading button w-100">
                              Loading
                            </button>
                          </>
                        ) : (
                          <>
                            <Button
                              color="facebook"
                              className="w-100"
                              type="submit"
                            >
                              Authenticate
                            </Button>
                          </>
                        )}
                      </div>
                    </form>
                    {/* 
                    <br></br>
                    <div className="col-12" style={{textAlign: "center"}}>
                     
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
