import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../Firebase-config";
import { Button } from "semantic-ui-react";
import Sidebar from "./Sidebar";
import ModalAdd from "./ModalAdd";
function PartnerHome() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Partners").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [docCount, setDocCount] = useState(null);
  const [error, setError] = useState(null);

  //Available Project
  useEffect(() => {
    const collectionRef = db.collection("PartnersProjects");
    collectionRef
      .get()
      .then((querySnapshot) => {
        setDocCount(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      });
  });

  //Pending Project
  const [pending, setPendingCount] = useState(null);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db
        .collection("PartnersProjects")
        .where("puid", "==", uid)
        .where("status", "==", "Pending");

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const count = querySnapshot.size; // Get the count of documents
          setPendingCount(count);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setPendingCount(0); // Set count to 0 when there is no user
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  //Pending Project
  const [approved, setApprovedCount] = useState(null);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db
        .collection("PartnersProjects")
        .where("puid", "==", uid)
        .where("status", "==", "Completed");

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const count = querySnapshot.size; // Get the count of documents
          setApprovedCount(count);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setApprovedCount(0); // Set count to 0 when there is no user
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  //Cancelled Project
  const [cancelled, setCancelledCount] = useState(null);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db
        .collection("PartnersProjects")
        .where("puid", "==", uid)
        .where("status", "==", "Cancelled");

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const count = querySnapshot.size; // Get the count of documents
          setCancelledCount(count);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setCancelledCount(0); // Set count to 0 when there is no user
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  //Applied Project
  const [appliedCount, setAppliedCount] = useState(null);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("CollectApplied").where("uid", "==", uid);

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const count = querySnapshot.size; // Get the count of documents
          setAppliedCount(count);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setAppliedCount(0); // Set count to 0 when there is no user
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const TotalProjects = appliedCount + cancelled;

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  return (
    <>
      <Fragment>
        {/* For Mobile */}
        <div
          class="offcanvas offcanvas-start w-25"
          tabindex="-1"
          id="offcanvas"
          data-bs-keyboard="false"
          data-bs-backdrop="false"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <div className="container-fluid" id="mobileSidebar">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              style={{ float: "right" }}
              aria-label="Close"
            >
              <i class="fa fa-close"></i>
            </button>
            <br></br> <br></br>
            <ul className="sidebar-nav" id="sidebar-nav">
              <li className="nav-item">
                <Link className="nav-link " to="/home">
                  <i className="bi bi-grid"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link collapsed" to="/projects">
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Projects</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#tables-nav"
                  data-bs-toggle="collapse"
                >
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Reports</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                {/* <ul
                id="tables-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <a href="tables-general.html">
                    <i className="bi bi-circle"></i>
                    <span>General Tables</span>
                  </a>
                </li>
                <li>
                  <a href="tables-data.html">
                    <i className="bi bi-circle"></i>
                    <span>Data Tables</span>
                  </a>
                </li>
              </ul> */}
              </li>

              <li className="nav-item my-5">
                <Button
                  color="blue"
                  onClick={() => handleAddProject()}
                  style={{ marginLeft: "18px" }}
                  className="collapsed"
                >
                  <span>Create Project</span>
                </Button>
                {openP && (
                  <ModalAdd
                    open={openP}
                    setOpen={setOpenP}
                    // handleDelete={handleDelete}
                    {...modalapplyP}
                  />
                )}
              </li>
            </ul>
          </div>
        </div>

        {/* For Mobile */}
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              {" "}
              <i
                style={{ color: "grey" }}
                class="fa fa-bars"
                id="sidebarBtn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
              ></i>{" "}
              Dashboard
            </h1>
          </div>
          {/* <!-- End Page Title --> */}

          <section className="section dashboard">
            {/* <!-- Left side columns --> */}

            <div className="row">
              {/* <!-- Sales Card --> */}
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Sales <span>| Today</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-briefcase"></i>
                      </div>
                      <div className="ps-3">
                        <h6> {docCount}</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Applied Projects
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Sales Card -->

        <!-- Revenue Card --> */}
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Revenue <span>| This Month</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-spinner"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{pending}</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Pending Projects
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Revenue Card -->

        <!-- Customers Card --> */}
              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-check"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{approved}</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Completed Projects
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card customers-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-times"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{cancelled}</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Cancelled Projects
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-credit-card"></i>
                      </div>
                      <div className="ps-3">
                        <h6>₹ 0.00</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Total Revenue
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                    <br></br>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa fa-hourglass"></i>
                      </div>
                      <div className="ps-3">
                        <h6>₹ 0.00</h6>

                        <span className="text-muted small pt-2 ps-1">
                          Wallet Amount
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="card recent-sales overflow-auto">
                  <div className="filter">
                    <a className="icon" href="/" data-bs-toggle="dropdown">
                      <i className="bi bi-three-dots"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <li className="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>

                      <li>
                        <a className="dropdown-item" href="/">
                          Today
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          This Month
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          This Year
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!-- End Recent Sales --> */}
            </div>
          </section>
        </main>
      </Fragment>
    </>
  );
}

export default PartnerHome;
