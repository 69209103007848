import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";

const ModalApprove = ({
  open,
  setOpen,
  title,
  OrderCode,
  cost,
  status,

  id,
}) => {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [UpdateStatus, setUserDesc] = useState(status);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Partners").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const handleSubmit = async () => {
    try {
      // Create a new document in Firestore collection
      await db
        .collection("PartnerInvites")
        .doc(id)
        .update({
          status: UpdateStatus,
        });

      // Reset form fields and close the modal
      setUserDesc("");
      setOpen(false);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Project Approval
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid" style={{ fontSize: "12px" }}>
              <div class="row">
                <div class="col-lg-5 my-2">
                  Project Code: <b style={{ color: "black" }}>#{OrderCode}</b>
                </div>
                <div class="col-lg-7 my-2">
                  Project Title: <b style={{ color: "black" }}>{title}</b>
                </div>
                <div class="col-lg-5 my-2">
                  Project Amount:{" "}
                  <b style={{ color: "black" }}>
                    {" "}
                    {cost && cost.length > 0 ? <>₹ {cost}.00</> : <></>}
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <Form>
              <Form.Field
                control="select"
                required
                name="UpdateStatus"
                defaultValue={UpdateStatus}
                onChange={(e) => setUserDesc(e.target.value)}
                label="Project Status"
              >
                <option value="Pending">--Select--</option>
                <option value="Approved">Approve</option>
                <option value="Rejected">Reject</option>
                <option value="Cancelled">Cancel</option>
                <option value="Completed">Completed</option>
              </Form.Field>
              <br></br>
            </Form>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button color="facebook" type="submit" onClick={handleSubmit}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalApprove;
