import React, { useState } from "react";
import { db } from "../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";

const ModalFile = ({ open, setOpen, deliverURL, fileURL, id }) => {
  // function handleDownloadClick(fileURL) {
  //   const link = document.createElement("a");
  //   link.href = fileURL;
  //   link.style.display = "none";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  function handleDownloadClick(fileURL) {
    if (fileURL.endsWith(".pdf")) {
      // If the file URL ends with .pdf, exit without downloading
      console.log("PDF files are not allowed.");
      return;
    }

    const link = document.createElement("a");
    link.href = fileURL;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#2185d2", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              File Download
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#2185d2",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              {fileURL || deliverURL ? (
                <div className="text-center">
                  <Button color="orange">
                    <a
                      style={{ textAlign: "center", color: "white" }}
                      href={fileURL ? fileURL : deliverURL}
                      target="_blank"
                      download
                    >
                      <i class="fa fa-download"></i> Download File
                    </a>
                  </Button>
                </div>
              ) : (
                <div>
                  <h5 style={{ color: "red", textAlign: "center" }}>
                    No file uploaded
                  </h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFile;
