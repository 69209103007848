import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../../Firebase-config";
import { Button, Form } from "semantic-ui-react";
import { Alert, Table } from "react-bootstrap";
import { onSnapshot } from "firebase/firestore";
import ModalComp from "../ModalApply";
import ModalComment from "../ModalComment";
import Sidebar from "../Sidebar";
import ModalAdd from "../ModalAdd";
import "../Table.css";
import ChatBox from "../ChatBox";
function Available() {
  const [isChat, setIsChatDiv] = useState(false);

  const toggleDiv = () => {
    setIsChatDiv(!isChat);
  };

  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Fetch Data from database

  const [open, setOpen] = useState(false);
  const [modalapply, setModalApply] = useState({});
  const handleModal = (item) => {
    setOpen(true);
    setModalApply(item);
  };

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  //Comment Box

  const [openE, setOpenE] = useState(false);
  const [modaledit, setModalEdit] = useState({});

  const handleEdit = (item) => {
    setOpenE(true);
    setModalEdit(item);
  };

  // getting Available projects from firestore collection and updating the state

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("OrderCode");
  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await db
        .collection("Available")
        .orderBy("posted", "desc")
        .get();
      const fetchedData = snapshot.docs.map((doc) => doc.data());
      setData(fetchedData);
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item[searchBy].toLowerCase().includes(searchTerm.toLowerCase())
  );

  ///TimeFormats
  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <>
      <div>
        {/* For Mobile */}
        <div
          class="offcanvas offcanvas-start w-25"
          tabindex="-1"
          id="offcanvas"
          data-bs-keyboard="false"
          data-bs-backdrop="false"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <div className="container-fluid" id="mobileSidebar">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              style={{ float: "right" }}
              aria-label="Close"
            >
              <i class="fa fa-close"></i>
            </button>
            <br></br> <br></br>
            <ul className="sidebar-nav" id="sidebar-nav">
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/home">
                  <i className="bi bi-grid"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link " to="/projects">
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Projects</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#tables-nav"
                  data-bs-toggle="collapse"
                  href="/"
                >
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Reports</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                {/* <ul
                id="tables-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <a href="tables-general.html">
                    <i className="bi bi-circle"></i>
                    <span>General Tables</span>
                  </a>
                </li>
                <li>
                  <a href="tables-data.html">
                    <i className="bi bi-circle"></i>
                    <span>Data Tables</span>
                  </a>
                </li>
              </ul> */}
              </li>

              <li className="nav-item my-5">
                <Button
                  color="facebook"
                  onClick={() => handleAddProject()}
                  style={{ marginLeft: "18px" }}
                  className="collapsed"
                >
                  <span>Create Project</span>
                </Button>
                {openP && (
                  <ModalAdd
                    open={openP}
                    setOpen={setOpenP}
                    // handleDelete={handleDelete}
                    {...modalapplyP}
                  />
                )}
              </li>
            </ul>
          </div>
        </div>

        {/* For Mobile */}

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              <i
                style={{ color: "grey" }}
                class="fa fa-bars"
                id="sidebarBtn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
              ></i>{" "}
              Project Dashboard
            </h1>
          </div>

          <hr></hr>

          {userData && userData.role === "Freelancer" && (
            <div
              className="search-bar"
              id="freealert"
              style={{ marginTop: "10px" }}
            >
              <Alert variant="danger">
                Hello buddy! To work as a freelancer, you must contact us @{" "}
                <a href="mailto: hr@falconxx.com">hr@falconxx.com</a> |{" "}
                <a href="tel: 8838173949">+91 8838173949</a>/
                <a href="tel: 8248533577">+91 8248533577</a>
              </Alert>
            </div>
          )}

          <div className="container-fluid">
            <div className="row">
              <div className="col-lg">
                <Button
                  size="small"
                  color="facebook"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Available
                </Button>

                <Link to="/invite_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Invited
                  </Button>
                </Link>
                <Link to="/applied_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Applied
                  </Button>
                </Link>

                <Link to="/in_process_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    In Process
                  </Button>
                </Link>

                <Link to="/delivered_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Delivered
                  </Button>
                </Link>

                <Link to="/cancelled_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Cancelled
                  </Button>
                </Link>

                <div className="row" style={{ float: "right" }}>
                  <div className="col">
                    <select
                      style={{ height: "4vh" }}
                      className="form-control"
                      value={searchBy}
                      onChange={handleSearchByChange}
                    >
                      <option value="OrderCode">Search by Project Code</option>
                      <option value="subject">Search Category</option>
                    </select>
                  </div>
                  <div className="col">
                    <div class="ui icon input">
                      <i class="search icon"></i>
                      <input
                        value={searchTerm}
                        onChange={handleSearch}
                        type="text"
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br></br>

            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "12px" }}
            >
              <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Project Code</th>
                  <th scope="col">Project Title</th>
                  <th scope="col">Category</th>

                  <th scope="col">Posted Date</th>
                  <th scope="col">Project Deadline</th>
                  <th scope="col">Project Cost</th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    File
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  <>
                    {filteredData.map((item, index) => (
                      <tr key={item.id}>
                        <th scope="row" style={{ width: "5vh" }}>
                          {index + 1}
                        </th>
                        <td
                          style={{
                            minWidth: "13vh",
                            textTransform: "uppercase",
                          }}
                        >
                          #{item.OrderCode}
                        </td>

                        <td
                          style={{
                            minWidth: "20vh",
                          }}
                        >
                          {item.title}
                        </td>
                        <td
                          style={{
                            minWidth: "20vh",
                          }}
                        >
                          {item.subject}
                        </td>

                        <td style={{ minWidth: "22vh" }}>
                          {item.posted && formatTimestamp(item.posted)}
                        </td>
                        <td style={{ minWidth: "22vh", color: "red" }}>
                          <b>
                            {item.deadline && formatTimestamp1(item.deadline)}
                          </b>
                        </td>
                        <td style={{ minWidth: "12vh" }}>
                          {item.uid === userId ? (
                            <h5>--</h5>
                          ) : (
                            <>
                              {item.cost && item.cost.length > 1 ? (
                                <>₹ {item.cost}.00</>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </td>
                        <td
                          style={{
                            minWidth: "5vh",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          {item.fileURL && item.fileURL.length > 0 ? (
                            <a
                              href={item.fileURL}
                              target="_blank"
                              style={{ color: "cornflowerblue" }}
                            >
                              <i class="fa fa-download"></i>
                            </a>
                          ) : (
                            <>
                              <i
                                class="fa fa-times"
                                style={{ color: "red" }}
                                aria-hidden="true"
                              ></i>
                            </>
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div class="ui buttons">
                            <Button
                              type="button"
                              onClick={() => handleEdit(item)}
                              // onClick={() => navigate(`/comment/${item.id}`)}
                              style={{
                                marginLeft: "3px",
                                fontSize: "11px",
                              }}
                            >
                              Comment
                            </Button>
                            {openE && (
                              <ModalComment
                                open={openE}
                                setOpen={setOpenE}
                                // handleDelete={handleDelete}
                                {...modaledit}
                              />
                            )}
                            <Button
                              style={{
                                fontSize: "11px",
                              }}
                              type="button"
                              color="facebook"
                              key={item.id}
                              onClick={() => handleModal(item)}
                            >
                              Apply
                            </Button>
                            {open && (
                              <ModalComp
                                open={open}
                                setOpen={setOpen}
                                // handleDelete={handleDelete}
                                {...modalapply}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan="9"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </main>

        {/* //ChatBox */}
        {isChat && <ChatBox />}
        <div id="mybutton">
          <button onClick={toggleDiv} class="feedback">
            <i class="fa fa-comments"></i>
          </button>
        </div>
      </div>
    </>
  );
}

export default Available;
