import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { db } from "../Firebase-config";
import { useNavigate } from "react-router-dom";
import imagebg from "./partner.jpg";
import { useUserAuth } from "../context/UserAuthContext";
import "react-quill/dist/quill.snow.css";
import { serverTimestamp } from "firebase/firestore";

const AddPartner = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState("");
  const [company, setCompany] = useState("");
  const [compCate, setCompCate] = useState("");
  const [loader, setLoader] = useState("");

  const [error, setError] = useState("");
  const [successmsg, setSuccessMsg] = useState("");
  const { signUp } = useUserAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoader("Please wait....");
    e.preventDefault();
    setError("");
    signUp(email, password)
      .then((credentials) => {
        db.collection("Partners")
          .doc(credentials.user.uid)
          .set({
            name: name,
            email: email,
            contact: contact,
            password: password,
            company,
            compCate,
            role: "Partner",
            empId: "ID not generated!",
            status: "Pending",
            uid: credentials.user.uid,
            createdOn: serverTimestamp(),
            img:
              "https://firebasestorage.googleapis.com/v0/b/falconxdatabase.appspot.com/o/userImage.jpg?alt=media&token=8cb1c5bb-7a95-47b8-bcba-4a51c9558e2c",
          })

          .then(() => {
            setSuccessMsg("Hurray ! Details submitted successfully");
            setLoader("");

            setName("");
            setEmail("");
            setContact("");
            setPassword("");
            setCompany("");
            setCompCate("");
            setTimeout(() => {
              setSuccessMsg("");
            }, 3000);
          })
          .catch((error) => {
            setError("Oops ! " + error.message);
            setLoader("");
          });
      })
      .catch((error) => {
        setError("Oops !" + error.message);
        setLoader("");
      });
  };

  return (
    <>
      <img
        id="addprojectImg"
        src={imagebg}
        alt=""
        style={{ width: "100%", objectFit: "cover" }}
      />
      <div className="container my-2">
        <div className="row my-3">
          <h1
            className="animation a1"
            style={{
              backgroundColor: "#3E6399",
              color: "white",
              margin: "10px",
              maxWidth: "45vh",
              padding: "5px 6px 6px 12px",
              borderRadius: "5px",
            }}
          >
            Register as a Partner
          </h1>
          <br></br> <br></br> <br></br>
          <Form className="form">
            <div className="row">
              <div className="col-lg-4 my-2">
                <Form.Input
                  label="Full Name"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  name="name"
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="col-lg-4 my-2">
                <Form.Input
                  type="email"
                  label="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  name="email"
                  placeholder="Enter your email address"
                  required
                />
              </div>
              <div className="col-lg-4 my-2">
                <Form.Input
                  label="Password"
                  required
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder="Enter a password"
                />
              </div>
              <div className="col-lg-4 my-2">
                <Form.Input
                  type="tel"
                  label="Phone Number"
                  onChange={(e) => setContact(e.target.value)}
                  value={contact}
                  name="phone"
                  placeholder="Enter your phone number"
                  pattern="[0-9]{10}"
                  required
                />
              </div>

              <div className="col-lg-4 my-2">
                <Form.Input
                  type="text"
                  label="Company Name"
                  onChange={(e) => setCompany(e.target.value)}
                  value={company}
                  name="company"
                  placeholder="Enter your company name"
                  pattern="[0-9]{10}"
                  required
                />
              </div>

              <div className="col-lg-4 my-2">
                <Form.Field
                  required
                  onChange={(e) => setCompCate(e.target.value)}
                  value={compCate}
                  control="select"
                  label="Company type"
                >
                  <option>--Select--</option>
                  <optgroup label="Technology">
                    <option value="Hardware Manufacturers">
                      Hardware Manufacturers
                    </option>
                    <option value="Software Company">Software Company</option>
                    <option value="E-commerce Company">
                      E-commerce Company
                    </option>
                  </optgroup>
                  <optgroup label="Health Care">
                    <option value="Pharmaceuticals">
                      Pharmaceuticals (e.g. Pfizer, Merck & Co.)
                    </option>
                    <option option="Biotech">
                      Biotech (e.g. Gilead Sciences, Amgen)
                    </option>
                    <option value="Medical Equipment and Supplies">
                      Medical Equipment and Supplies (e.g. Johnson & Johnson,
                      Medtronic)
                    </option>
                    <option value="Health Care Providers">
                      Health Care Providers (e.g. UnitedHealth Group, Anthem)
                    </option>
                  </optgroup>
                  <optgroup label="Financials">
                    <option value="Banks">
                      Banks (e.g. JPMorgan Chase, Bank of America)
                    </option>
                    <option value="Investment Firms ">
                      Investment Firms (e.g. Berkshire Hathaway, BlackRock)
                    </option>
                    <option value="Insurance Companies">
                      Insurance Companies (e.g. AIG, Prudential)
                    </option>
                  </optgroup>
                  <optgroup label="Consumer Goods">
                    <option value="Food and Beverage">
                      Food and Beverage (e.g. Coca-Cola, PepsiCo)
                    </option>
                    <option>Apparel (e.g. Nike, Adidas)</option>
                    <option value="Personal Care Products">
                      Personal Care Products (e.g. Procter & Gamble, Unilever)
                    </option>
                    <option value="Luxury Brands">
                      Luxury Brands (e.g. LVMH, Richemont)
                    </option>
                  </optgroup>
                </Form.Field>
              </div>

              {loader && (
                <Alert
                  variant="warning"
                  style={{ fontSize: "11px", textAlign: "center" }}
                >
                  {loader}
                </Alert>
              )}
              {successmsg && (
                <Alert
                  variant="success"
                  style={{ fontSize: "11px", textAlign: "center" }}
                >
                  {successmsg}
                </Alert>
              )}

              <div className="text-center my-3">
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  type="button"
                  color="facebook"
                >
                  Submit Details
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddPartner;
