import React, { useState, useEffect } from "react";
import { db, auth } from "../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { serverTimestamp } from "firebase/firestore";
const ModalCancel = ({
  open,
  setOpen,
  title,
  words,

  pDesc,
  OrderCode,
  fdeadline,
  cost,
  budget,
  posted,

  fileURL,
  id,
}) => {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [userDesc, setUserDesc] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Partners").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const handleSubmit = async () => {
    try {
      // Create a new document in Firestore collection
      await db
        .collection("PartnersProjects")
        .doc(id)
        .update({
          status: "Cancelled",

          cancelled: serverTimestamp(),
        });

      // Reset form fields and close the modal
      setUserDesc("");
      setOpen(false);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "red", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "red",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <div class="container-fluid" style={{ fontSize: "12px" }}>
                <h5>Are you sure you want to cancel the project?</h5>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <Form>
              <Form.Input hidden value={title} />
              <Form.Input hidden value={words} />
              <Form.Input hidden value={OrderCode} />

              <Form.Input hidden value={pDesc} />
              <Form.Input hidden value={budget} />
              <Form.Input hidden value={cost} />
              <Form.Input hidden value={userId} />
              {userData && (
                <>
                  <Form.Input hidden value={userData.name} />
                  <Form.Input hidden value={userData.contact} />
                  <Form.Input hidden value={userData.email} />
                  <Form.Input hidden value={userData.designation} />
                </>
              )}
              <Form.TextArea
                name="userDesc"
                hidden
                placeholder="Brief Description"
                onChange={(e) => setUserDesc(e.target.value)}
                required
              ></Form.TextArea>
            </Form>
            <Button size="small" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              size="small"
              color="red"
              type="submit"
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCancel;
