import React, { useState, useEffect } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { Alert } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { db, auth } from "../Firebase-config";

import Logo from "../assets/img/logoa.png";

function Navbar() {
  function refreshPage() {
    window.location.reload(false);
  }
  const { logOut, user } = useUserAuth("");
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
      refreshPage();
      return false;
    } catch (error) {
      console.log(error.message);
    }
  };

  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    const fetchOtherData = async () => {
      // Add your additional data fetching logic here
      // Example:
      if (userId) {
        try {
          const otherDocRef = db.collection("Users").doc(userId);
          const otherDoc = await otherDocRef.get();

          if (otherDoc.exists) {
            const otherData = otherDoc.data();
            // Process and store other data as needed
          } else {
            console.log("No other document found!");
          }
        } catch (error) {
          console.log("Error getting other document:", error);
        }
      }
    };

    // Call the functions to fetch the data
    fetchUserData();
    fetchOtherData();
  }, [userId]);

  if (!userData) {
    return <div></div>;
  }

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <a className="logo d-flex align-items-center">
            <img src={Logo} alt="" />
            {/* <span className="d-none d-lg-block">FalconX</span> */}
          </a>
          <i className="bi bi-list toggle-sidebar-btn"></i>
        </div>

        {userData.role === "Freelancer" && (
          <div className="search-bar" style={{ marginTop: "10px" }}>
            <Alert variant="danger">
              Hello buddy! To work as a freelancer, you must first register with
              us.
            </Alert>
          </div>
        )}

        {/* <div className="search-bar">
          <form
            className="search-form d-flex align-items-center"
            method="POST"
            action="#"
          >
            <input
              type="text"
              name="query"
              placeholder="Search"
              title="Enter search keyword"
            />
            <button type="submit" title="Search">
              <i className="bi bi-search"></i>
            </button>
          </form>
        </div> */}

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="/"
                data-bs-toggle="dropdown"
              >
                <img
                  src={userData.img}
                  alt="Profile"
                  style={{ width: "30px", height: "30px", objectFit: "cover" }}
                  className="rounded-circle"
                />
                <span className=" d-md-block dropdown-toggle ps-2">
                  {userData.name}
                </span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6>{userData.name}</h6>
                  <span>Role: {userData.role}</span>
                  <br></br>
                  <span>
                    Emp ID: <b>{userData.empId}</b>
                  </span>
                  <br></br>
                  <span style={{ fontSize: "13px" }}>
                    {userData.designation}
                  </span>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to="/profile"
                  >
                    <i className="fa fa-user"></i>
                    <span>My Profile</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                {/* <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="users-profile.html"
                  >
                    <i className="bi bi-gear"></i>
                    <span>Account Settings</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="pages-faq.html"
                  >
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                  </a>
                </li> */}

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    style={{ cursor: "pointer", color: "red" }}
                    className="dropdown-item d-flex align-items-center"
                    onClick={handleLogout}
                  >
                    <i className="fa fa-sign-out"></i>
                    <span>Sign Out</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Navbar;
