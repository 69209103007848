import React, { useState } from "react";
import { db } from "../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";

import "./chatbox.css";

const EmployeeProfile = ({
  open,
  setOpen,
  name,

  contact,
  designation,
uid,
  empId,
  role,
  id,
}) => {
  const [empIDset, setEmpId] = useState(empId);
  const [roleset, setRole] = useState(role);
  const [loader, setLoader] = useState("");

  const handleSubmitData = async () => {
    setLoader("");
    try {
      db.collection("Users")
        .doc(uid)
        .update({
          empId: empIDset,
          role: roleset,
        });
      setLoader("Success! Data Updated");
      setTimeout(() => {
        setOpen(false);
      }, 4000);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              User Profile 
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h5>
                    <span style={{ color: "grey", fontWeight: "normal" }}>
                      Name:
                    </span>{" "}
                    {name}
                  </h5>
                </div>
                <div className="col">
                  <h5>
                    <span style={{ color: "grey", fontWeight: "normal" }}>
                      Contact:
                    </span>{" "}
                    {contact}
                  </h5>
                </div>
              </div>
              <div className="row my-2">
                <div className="col">
                  <h5>
                    <span style={{ color: "grey", fontWeight: "normal" }}>
                      Designation:
                    </span>{" "}
                    {designation}
                  </h5>
                </div>
              </div>
            </div>
            <div>
              <hr></hr>
              <Form>
                <Form.Input
                  label="Employee ID"
                  name="empId"
                  placeholder="EmpId"
                  onChange={(e) => setEmpId(e.target.value)}
                  required
                  value={empIDset}
                />

                <Form.Field
                  label="Role"
                  control="select"
                  name="role"
                  placeholder="role"
                  onChange={(e) => setRole(e.target.value)}
                  value={roleset}
                >
                  <option value="External Expert">External Expert</option>
                  <option value="Internal Expert">Internal Expert</option>
                  <option value="Freelancer">Freelancer</option>
         
                  <option style={{ color: "red" }} value="Block">
                    Block
                  </option>
                  {/* <option value="Admin">Admin</option> */}
                </Form.Field>
                {loader && (
                  <Alert
                    variant="success"
                    style={{ fontSize: "13px", textAlign: "center" }}
                  >
                    {loader}
                  </Alert>
                )}
                <Button
                  size="small"
                  color="facebook"
                  type="submit"
                  onClick={handleSubmitData}
                >
                  Update
                </Button>
              </Form>

              {/* <Button size="small" onClick={() => setOpen(false)}>
                Cancel
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeProfile;
