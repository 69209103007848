import React, { useState } from "react";
import { db } from "../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { serverTimestamp } from "firebase/firestore";

const ModalPayment = ({
  open,
  setOpen,
  name,
  empId,
  uid,
  subject,
  pDesc,
  OrderCode,
  deadline,
  title,
  words,
  cost,
  paymentStatus,
  id,
}) => {
  const [updatePayment, setUpdatedPayment] = useState(paymentStatus);

  const handleSubmit = async () => {
    try {
      // Create a new document in Firestore collection
      await db
        .collection("Delivered")
        .doc(OrderCode)
        .update({
          paymentStatus: updatePayment,
        })
        .then(() => {
          db.collection("Payments")
            .doc(OrderCode)
            .set({
              subject,
              pDesc,
              OrderCode,
              deadline,
              title,
              words,
              cost,
              name,
              empId,
              paymentDate: serverTimestamp(),
              paymentStatus: updatePayment,
              uid,
            });
        });
      window.location.reload(true);
      // Reset form fields and close the modal

      setOpen(false);
    } catch (error) {
      console.log("Error updating applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Payment Status
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <p style={{ fontSize: "12px" }}>
                Project Code: <b style={{ color: "red" }}>#{OrderCode}</b> |
                Project Title: <b style={{ color: "red" }}>{title}</b>| Project
                Amount: <b style={{ color: "red" }}>₹{cost}.00</b>
              </p>
              <Form>
                <Form.Field
                  placeholder="Payment Status"
                  required
                  control="select"
                  onChange={(e) => setUpdatedPayment(e.target.value)}
                  defaultValue={updatePayment}
                >
                  <option value="">--Select--</option>
                  <option value="Paid">Paid</option>
                  <option value="On Hold">On Hold</option>
                  <option value="Rejected">Reject</option>
                </Form.Field>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              color="facebook"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPayment;
