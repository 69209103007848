import React, { useState } from "react";
import { db } from "../../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ModalEdit = ({
  open,
  setOpen,
  pDesc,
  fdeadline,
  title,
  words,

  cost,
  OrderCode,
  id,
}) => {
  const [updateTitle, setUpdatedTitle] = useState(title);
  const [updateWords, setUpdatedWords] = useState(words);
  const [updateCode, setUpdatedCode] = useState(OrderCode);
  const [updateCost, setUpdatedCost] = useState(cost);
  const [updatePdesc, setUpdatedPDesc] = useState(pDesc);

  const [updateDeadline, setUpdatedDeadline] = useState(fdeadline);

  const [loader, setLoader] = useState("");
  const [success, setsuccessLoader] = useState("");

  const handleSubmit = async () => {
    try {
      // Create a new document in Firestore collection
      await db
        .collection("PartnersProjects")
        .doc(OrderCode)
        .update({
          title: updateTitle,
          words: updateWords,
          OrderCode: updateCode,
          cost: updateCost,
          pDesc: updatePdesc,
          fdeadline: updateDeadline,
        });

      // Reset form fields and close the modal
      window.location.reload(true);
      setOpen(false);
    } catch (error) {
      console.log("Error updating applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Edit Project
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <Form>
                {loader && (
                  <Alert
                    variant="warning"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {loader}
                  </Alert>
                )}
                {success && (
                  <Alert
                    variant="success"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {success}
                  </Alert>
                )}
                <div className="row">
                  <div className="col-lg-6">
                    <br></br>
                    <Form.Input
                      placeholder="Project Code"
                      label="Project Code"
                      required
                      onChange={(e) => setUpdatedCode(e.target.value)}
                      defaultValue={updateCode}
                    />
                  </div>

                  <div className="col-lg-6">
                    <br></br>
                    <Form.Input
                      placeholder="Project Cost"
                      label="Project Cost"
                      required
                      onChange={(e) => setUpdatedCost(e.target.value)}
                      defaultValue={updateCost}
                    />
                  </div>

                  <div className="col-lg-8">
                    <br></br>
                    <Form.Input
                      placeholder="Project Title"
                      label="Project Title"
                      required
                      onChange={(e) => setUpdatedTitle(e.target.value)}
                      defaultValue={title}
                    />
                  </div>
                  <div className="col-lg-4">
                    <br></br>
                    <Form.Input
                      placeholder="Word Count"
                      label="Word Count"
                      type="number"
                      onChange={(e) => setUpdatedWords(e.target.value)}
                      defaultValue={words}
                    />
                  </div>
                </div>
                <br></br>
                <label>
                  <b>Project Description:</b>
                </label>
                <ReactQuill
                  theme="snow"
                  style={{ height: "20vh" }}
                  defaultValue={pDesc}
                  onChange={setUpdatedPDesc}
                />
                <br></br> <br></br> <br></br>
                <div className="row" style={{ marginBottom: "10px" }}>
                  <div className="col-lg">
                    <br></br>
                    <Form.Input
                      name="deadline"
                      required
                      type="datetime-local"
                      defaultValue={fdeadline}
                      onChange={(e) => setUpdatedDeadline(e.target.value)}
                      label="Project Deadline"
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button size="small" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              color="facebook"
              size="small"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEdit;
