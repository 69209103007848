import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { Button } from "semantic-ui-react";
import logoa from "../assets/img/logoa.png";
import { useUserAuth } from "../context/UserAuthContext";
import { Link } from "react-router-dom";
import { auth, db } from "../Firebase-config";
import bg from "../assets/img/bg.jpg";
import { serverTimestamp } from "firebase/firestore";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const [successmsg, setSuccessMsg] = useState("");
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("");
  const [div0Visible, setDiv0Visible] = useState(true);
  const [div1Visible, setDiv1Visible] = useState(false);
  const [div2Visible, setDiv2Visible] = useState(false);
  const [div3Visible, setDiv3Visible] = useState(false);
  const [div4Visible, setDiv4Visible] = useState(false);

  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // Show or hide the corresponding div based on the selected option
    setDiv0Visible(selectedValue === "option0");
    setDiv1Visible(selectedValue === "option1");
    setDiv2Visible(selectedValue === "option2");
    setDiv3Visible(selectedValue === "option3");
    setDiv4Visible(selectedValue === "option4");
  };

  //Users Data
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Freelancer Login
  const handleSubmitFreelancer = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    logIn(email, password)
      .then(() => {
        if (
          (userData && userData.role === "Freelancer") ||
          userData.role === "External Expert"
        ) {
          setSuccessMsg("Hurray ! Credentials verified. Redirecting...");

          setTimeout(() => {
            setSuccessMsg("");
            setLoading(false);
            navigate("/projects", { replace: true });
          }, 4000);
        } else {
          setError("");
          // Show alert for admins and moderators
          setError("Alert! You are not authorized");

          setTimeout(() => {
            setError("");
            setLoading(false);
          }, 2000);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  //Admin Login
  const handleSubmitAdmin = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    logIn(email, password)
      .then(() => {
        if (userData && userData.role === "Admin") {
          setSuccessMsg("Hurray ! Credentials verified. Redirecting...");

          setTimeout(() => {
            setSuccessMsg("");
            setLoading(false);
            navigate("/adminPanel", { replace: true });
          }, 4000);
        } else {
          setError("");
          // Show alert for admins and moderators
          setError("Alert! You are not authorized");
          setLoading(false);
          setTimeout(() => {
            setError("");
            setLoading(false);
          }, 4000);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  //Internal Login
  const handleSubmitInternal = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    logIn(email, password)
      .then(() => {
        if (userData && userData.role === "Internal Expert") {
          setSuccessMsg("Hurray ! Credentials verified. Redirecting...");

          setTimeout(() => {
            setSuccessMsg("");
            setLoading(false);
            navigate("/adminPanel", { replace: true });
          }, 4000);
        } else {
          setError("");
          // Show alert for admins and moderators
          setError("Alert! You are not authorized");
          setLoading(false);
          setTimeout(() => {
            setError("");
            setLoading(false);
          }, 4000);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundImage: `url(${bg})`,
        }}
      >
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-3">
                  <div className="d-flex align-items-center w-auto">
                    <img
                      src={logoa}
                      alt="logo"
                      style={{ height: "12vh", width: "auto" }}
                    />
                    {/* <span className="d-lg-block" style={{ fontSize: "38px" }}>
                      FXCTS
                    </span> */}
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div
                      className="row my-2"
                      style={{
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      <div
                        className="col"
                        style={{
                          backgroundColor: "rgb(59 89 152 / 52%)",
                          padding: "10px",
                          margin: "3px",
                        }}
                      >
                        Freelancer
                      </div>

                      <div
                        className="col"
                        style={{
                          backgroundColor: "#3b5998",
                          padding: "10px",
                          cursor: "pointer",

                          margin: "3px",
                        }}
                      >
                        <Link to="/partnerLogin">
                          <div style={{ color: "white" }}>Partner</div>
                        </Link>
                      </div>
                    </div>

                    <div className="container-fluid">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Login to Your Account
                      </h5>
                      <br></br>
                    </div>
                    {successmsg && (
                      <Alert
                        variant="success"
                        style={{ fontSize: "13.5px", textAlign: "center" }}
                      >
                        {successmsg}
                      </Alert>
                    )}
                    {error && (
                      <Alert
                        variant="danger"
                        style={{ fontSize: "13.5px", textAlign: "center" }}
                      >
                        {error}
                      </Alert>
                    )}

                    <div className="col-12">
                      <div className="input-group has-validation">
                        <label for="yourUsername" className="form-label">
                          Select role
                        </label>
                        <div className="input-group has-validation">
                          <select
                            className="form-control"
                            required
                            value={selectedOption}
                            onChange={handleSelect}
                          >
                            <option value="option0" selected>
                              --Select your role--
                            </option>
                            <option value="option1">Freelancer</option>
                            <option value="option2">Admin</option>
                            <option value="option3">Internal Expert</option>

                            {/* <option value="option5">Internal Expert</option> */}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* Sample */}
                    {div0Visible && (
                      <>
                        <form
                          style={{ marginTop: "2px" }}
                          className="row g-3 needs-validation"
                        >
                          <div className="col-12">
                            <label for="yourUsername" className="form-label">
                              Email
                            </label>

                            <div className="input-group has-validation">
                              <input
                                type="email"
                                placeholder="Enter your email"
                                className="form-control"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <label for="yourPassword" className="form-label">
                              Password
                            </label>
                            <input
                              type="password"
                              placeholder="Enter your password"
                              className="form-control"
                              required
                            />
                          </div>

                          <div className="col-12">
                            <Link to="/fpassword_auth">Forgot Password</Link>
                          </div>

                          <div className="col-12">
                            <Button
                              color="facebook"
                              className="w-100"
                              type="submit"
                            >
                              Authenticate
                            </Button>
                          </div>
                        </form>
                      </>
                    )}
                    {/* Sample */}

                    {/* Freelancers */}
                    {div1Visible && (
                      <>
                        <form
                          style={{ marginTop: "2px" }}
                          className="row g-3 needs-validation"
                          onSubmit={handleSubmitFreelancer}
                        >
                          <div className="col-12">
                            <label for="yourUsername" className="form-label">
                              Email
                            </label>

                            <div className="input-group has-validation">
                              <input
                                type="email"
                                placeholder="Enter your email"
                                onChange={(e) => setEmail(e.target.value)}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <label for="yourPassword" className="form-label">
                              Password
                            </label>
                            <input
                              type="password"
                              placeholder="Enter your password"
                              onChange={(e) => setPassword(e.target.value)}
                              className="form-control"
                              required
                            />
                          </div>

                          <div className="col-12">
                            <Link to="/fpassword_auth">Forgot Password</Link>
                          </div>

                          <div className="col-12">
                            {loading === true ? (
                              <>
                                <button class="ui loading button w-100">
                                  Loading
                                </button>
                              </>
                            ) : (
                              <>
                                <Button
                                  color="facebook"
                                  className="w-100"
                                  type="submit"
                                >
                                  Authenticate
                                </Button>
                              </>
                            )}
                          </div>
                        </form>
                      </>
                    )}
                    {/* Freelancers */}

                    {/* Admin */}
                    {div2Visible && (
                      <>
                        <form
                          onSubmit={handleSubmitAdmin}
                          style={{ marginTop: "2px" }}
                          className="row g-3 needs-validation"
                          // onSubmit={handleSubmit}
                        >
                          <div className="col-12">
                            <label for="yourUsername" className="form-label">
                              Email
                            </label>
                            <div className="input-group has-validation">
                              <input
                                type="email"
                                placeholder="Enter your email"
                                onChange={(e) => setEmail(e.target.value)}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <label for="yourPassword" className="form-label">
                              Password
                            </label>
                            <input
                              type="password"
                              placeholder="Enter your password"
                              onChange={(e) => setPassword(e.target.value)}
                              className="form-control"
                              required
                            />
                          </div>

                          <div className="col-12">
                            <Link to="/fpassword_auth">Forgot Password</Link>
                          </div>

                          <div className="col-12">
                            {loading === true ? (
                              <>
                                <button class="ui loading button w-100">
                                  Loading
                                </button>
                              </>
                            ) : (
                              <>
                                <Button
                                  color="facebook"
                                  className="w-100"
                                  type="submit"
                                >
                                  Authenticate
                                </Button>
                              </>
                            )}
                          </div>
                        </form>
                      </>
                    )}
                    {/* Admin */}

                    {/* Internal */}
                    {div3Visible && (
                      <>
                        <form
                          onSubmit={handleSubmitInternal}
                          style={{ marginTop: "2px" }}
                          className="row g-3 needs-validation"
                          // onSubmit={handleSubmit}
                        >
                          <div className="col-12">
                            <label for="yourUsername" className="form-label">
                              Email
                            </label>
                            <div className="input-group has-validation">
                              <input
                                type="email"
                                placeholder="Enter your email"
                                onChange={(e) => setEmail(e.target.value)}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <label for="yourPassword" className="form-label">
                              Password
                            </label>
                            <input
                              type="password"
                              placeholder="Enter your password"
                              onChange={(e) => setPassword(e.target.value)}
                              className="form-control"
                              required
                            />
                          </div>

                          <div className="col-12">
                            <Link to="/fpassword_auth">Forgot Password</Link>
                          </div>

                          <div className="col-12">
                            {loading === true ? (
                              <>
                                <button class="ui loading button w-100">
                                  Loading
                                </button>
                              </>
                            ) : (
                              <>
                                <Button
                                  color="facebook"
                                  className="w-100"
                                  type="submit"
                                >
                                  Authenticate
                                </Button>
                              </>
                            )}
                          </div>
                        </form>
                      </>
                    )}
                    {/* Internal */}

                    <br></br>
                    <div className="col-12" style={{ textAlign: "center" }}>
                      <p className="small mb-0">
                        Don't have account?{" "}
                        <Link to="/register_auth">Create an account</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
