import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, Link } from "react-router-dom";
import { db, auth } from "../Firebase-config";

import Sidebar from "./Sidebar";
function Home() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [docCount, setDocCount] = useState(null);
  const [error, setError] = useState(null);

  //Available Project
  useEffect(() => {
    const collectionRef = db.collection("Available");

    collectionRef
      .get()
      .then((querySnapshot) => {
        setDocCount(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      });
  });

  const [docPProject, setPProjectCount] = useState(null);

  //Available Project
  useEffect(() => {
    const collectionRef = db.collection("PartnersProjects");

    collectionRef
      .get()
      .then((querySnapshot) => {
        setPProjectCount(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      });
  });
  //Delivered Project
  const [deliveredCount, setDeliveredCount] = useState(null);

  useEffect(() => {
    const collectionRef = db.collection("Delivered");

    collectionRef
      .get()
      .then((querySnapshot) => {
        setDeliveredCount(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      });
  });

  ///Requests Counts

  const [requests, setRe] = useState(null);
  useEffect(() => {
    const collectionRef = db.collection("ClientsProjects");

    collectionRef
      .get()
      .then((querySnapshot) => {
        setRe(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      });
  });

  //////PartnersCount
  const [partnersCount, setPartnerCount] = useState(null);
  useEffect(() => {
    const collectionRef = db.collection("Partners");

    collectionRef
      .get()
      .then((querySnapshot) => {
        setPartnerCount(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      });
  });

  //Invited Partners
  const [partnersCountInvites, setPartnerCountInvites] = useState(null);
  useEffect(() => {
    const collectionRef = db.collection("PartnerInvites");

    collectionRef
      .get()
      .then((querySnapshot) => {
        setPartnerCountInvites(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      });
  });

  //Applied Project
  const [freelancer, setFreelancer] = useState(null);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("Users");

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const count = querySnapshot.size; // Get the count of documents
          setFreelancer(count);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setFreelancer(0); // Set count to 0 when there is no user
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  ///Total Earnings
  const [earning, setEarning] = useState(null);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("Reports");

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          let totalPrice = "";

          querySnapshot.forEach((doc) => {
            const price = doc.data().amount;
            totalPrice -= price * -1;
          });

          setEarning(totalPrice);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setEarning(0); // Set earning to 0 when there is no user
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  ///Project Total Earnings
  const [pearning, setPEarning] = useState(null);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("Payments");

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          let totalPrice = "";

          querySnapshot.forEach((doc) => {
            const price = doc.data().cost;
            totalPrice -= price * -1;
          });

          setPEarning(totalPrice);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setPEarning(0); // Set earning to 0 when there is no user
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  return (
    <>
      <Fragment>
        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Dashboard</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </nav>
          </div>
          {/* <!-- End Page Title --> */}

          <section className="section dashboard">
            {/* <!-- Left side columns --> */}

            <div className="row">
              {/* <!-- Sales Card --> */}
              <div className="col-xxl-3 col-md-6">
                <Link to="/adminPanel">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                      Sales <span>| Today</span>
                    </h5> */}
                      <br></br>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa fa-briefcase"></i>
                        </div>
                        <div className="ps-3">
                          <h6> {docCount}</h6>

                          <span className="text-muted small pt-2 ps-1">
                            Available Projects
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              {/* <!-- End Sales Card -->

        <!-- Revenue Card --> */}
              <div className="col-xxl-3 col-md-6">
                <Link to="/adminPanel_delivered">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                      Revenue <span>| This Month</span>
                    </h5> */}
                      <br></br>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa fa-rocket"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{deliveredCount}</h6>

                          <span className="text-muted small pt-2 ps-1">
                            Delivered Projects
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xxl-3 col-md-6">
                <Link to="/adminPanel_requests">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                      Revenue <span>| This Month</span>
                    </h5> */}
                      <br></br>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa fa-rocket"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{requests}</h6>

                          <span className="text-muted small pt-2 ps-1">
                            Project Requests
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              {/* <!-- End Revenue Card -->

                  <div className="col-xxl-3 col-md-6">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    {/* <h5 className="card-title">
                      Revenue <span>| This Month</span>
                    </h5> */}
              <div className="col-xxl-3 col-md-6">
                <Link to="/employee_list">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                      Revenue <span>| This Month</span>
                    </h5> */}
                      <br></br>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa fa-rocket"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{freelancer}</h6>

                          <span className="text-muted small pt-2 ps-1">
                            Freelancers
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xxl-3 col-xl-12">
                <Link to="/partner_projects">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                      <br></br>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa fa-briefcase"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{docPProject}</h6>

                          <span className="text-muted small pt-2 ps-1">
                            Partner Projects
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xxl-3 col-md-6">
                <Link to="/partner_invite">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                      Revenue <span>| This Month</span>
                    </h5> */}
                      <br></br>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa fa-rocket"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{partnersCountInvites}</h6>

                          <span className="text-muted small pt-2 ps-1">
                            Invited Partners
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xxl-3 col-md-6">
                <Link to="/partners_list">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                      Revenue <span>| This Month</span>
                    </h5> */}
                      <br></br>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa fa-rocket"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{partnersCount}</h6>

                          <span className="text-muted small pt-2 ps-1">
                            Partners
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="row">
                <div className="col-xxl-3 col-xl-12">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                      <br></br>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa fa-credit-card"></i>
                        </div>
                        <div className="ps-3">
                          <h6>₹ {earning}.00</h6>

                          <span className="text-muted small pt-2 ps-1">
                            Total Invoice Revenue
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-3 col-xl-12">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                      <br></br>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa fa-credit-card"></i>
                        </div>
                        <div className="ps-3">
                          <h6>₹ {pearning}.00</h6>

                          <span className="text-muted small pt-2 ps-1">
                            Total Project Revenue
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-3 col-xl-12">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                      <br></br>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa fa-money"></i>
                        </div>
                        <div className="ps-3">
                          <h6>₹ 0.00</h6>

                          <span className="text-muted small pt-2 ps-1">
                            Penalty Charges
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-3 col-xl-12">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                      Customers <span>| This Year</span>
                    </h5> */}
                      <br></br>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa fa-hourglass"></i>
                        </div>
                        <div className="ps-3">
                          <h6>₹ 0.00</h6>

                          <span className="text-muted small pt-2 ps-1">
                            Wallet Amount
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- End Recent Sales --> */}
            </div>
          </section>
        </main>
      </Fragment>
    </>
  );
}

export default Home;
