import React, { useState, useEffect } from "react";
import { db, auth } from "../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { serverTimestamp } from "firebase/firestore";

const ModalApprove = ({
  open,
  setOpen,
  title,
  words,
  fileURL,
  subject,
  pDesc,
  OrderCode,
  deadline,
  cost,
  name,
  email,
  contact,
  designation,
  applied,
  posted,
  uid,
  id,
}) => {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [userDesc, setUserDesc] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const handleSubmitApprove = async () => {
    try {
      // Create a new document in Firestore collection
      await db
        .collection("Processing")
        .doc(OrderCode)
        .set({
          title,
          words,
          fileURL,
          OrderCode,
          subject,
          pDesc,
          deadline,
          cost,
          name,
          email,
          contact,
          designation,
          userDesc,
          applied,
          posted,
          uid,
        })
        .then(() => {
          db.collection("CollectApplied")
            .doc(OrderCode)
            .set({
              title,
              words,
              fileURL,
              OrderCode,
              subject,
              pDesc,
              deadline,
              cost,
              Appliedstatus: "Approved",
              name,
              email,
              contact,
              designation,
              userDesc,
              applied,
              posted,
              uid,
            });
        })
        .then(() => {
          db.collection("Applied")
            .doc(OrderCode)
            .delete({
              title,
              words,
              fileURL,
              OrderCode,
              subject,
              pDesc,
              deadline,
              cost,
              name,
              email,
              contact,
              designation,
              userDesc,
              applied,
              posted,
              uid,
            });
        });

      // Reset form fields and close the modal
      setUserDesc("");
      setOpen(false);
      window.location.reload(true);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  const handleSubmitReject = async () => {
    try {
      // Create a new document in Firestore collection
      await db
        .collection("CollectApplied")
        .doc(OrderCode)
        .set({
          title,
          words,
          fileURL,
          OrderCode,
          subject,
          pDesc,
          deadline,
          cost,
          name,
          email,
          contact,
          designation,
          userDesc,
          Appliedstatus: "Rejected",
          applied,
          posted,
          uid,
        })
        .then(() => {
          db.collection("Available")
            .doc(OrderCode)
            .set({
              title,
              words,
              fileURL,
              OrderCode,
              subject,
              posted,
              pDesc,
              deadline,
              cost,
              userDesc,
            });
        })
        .then(() => {
          db.collection("Applied")
            .doc(OrderCode)
            .delete({
              title,
              words,
              fileURL,
              OrderCode,
              subject,
              pDesc,
              deadline,
              cost,
              name,
              email,
              contact,
              designation,
              userDesc,
              applied,
              posted,
            });
        });

      // Reset form fields and close the modal
      setUserDesc("");
      setOpen(false);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Project Approval
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid" style={{ fontSize: "12px" }}>
              <div class="row">
                <div class="col-lg-5 my-2">
                  Project Code: <b style={{ color: "black" }}>{OrderCode}</b>
                </div>
                <div class="col-lg-7 my-2">
                  Project Deadline:{" "}
                  <b style={{ color: "black" }}>
                    {deadline && formatTimestamp1(deadline)}
                  </b>
                </div>
                <div class="col-lg-5 my-2">
                  Project Amount:{" "}
                  <b style={{ color: "black" }}>
                    {" "}
                    {cost && cost.length > 1 ? <>₹ {cost}.00</> : <></>}
                  </b>
                </div>
                <div class="col-lg-7 my-2">
                  Subject: <b>{subject}</b>
                </div>
                <div class="col-lg-2 my-2">Description: </div>
                <div class="col-lg-10">
                  <b>
                    <div
                      style={{
                        fontSize: "12px",
                        marginTop: "5px",
                      }}
                      contentEditable="false"
                      dangerouslySetInnerHTML={{
                        __html: `${pDesc}`,
                      }}
                    ></div>
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <Button color="red" type="submit" onClick={handleSubmitReject}>
              Reject
            </Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              color="facebook"
              type="submit"
              onClick={handleSubmitApprove}
            >
              Approve
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalApprove;
