import React, {useState, useEffect} from "react";
import {Alert} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {Button, Form} from "semantic-ui-react";
import {db, auth} from "../Firebase-config";
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import Sidebar from "./Sidebar";

function Profile() {
  const [userBankData, setBankData] = useState({});
  const [bankname, setBankName] = useState("");
  const [bname, setBName] = useState("");
  const [accountno, setAccount] = useState("");
  const [confirmAccount, setConfirmaccount] = useState("");
  const [ifsc, setIFSC] = useState("");
  const [upi, setUPI] = useState("");
  const [baddress, setBaddress] = useState("");
  const [iban, setIban] = useState("");
  const [swift, setSwift] = useState("");
  const [paypal, setPaypal] = useState("");
  const [skrill, setSkrill] = useState("");

  const [sname, setName] = useState("");
  const [scontact, setContact] = useState("");
  const [sdesignation, setDesignation] = useState("");
  const [saddress, setAddress] = useState("");
  const [scountry, setCountry] = useState("");
  const [sabout, setAbout] = useState("");
  const [successForm, setSuccessForm] = useState("");
  const [userData, setUserData] = useState({});
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = () => {
      if (userId) {
        const userDocRef = db.collection("Users").doc(userId);
        const unsubscribe = userDocRef.onSnapshot((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            setUserData(userData);
            setName(userData.name);
            setContact(userData.contact);
            setDesignation(userData.designation);
            setAddress(userData.address);
            setCountry(userData.country);
            setAbout(userData.about);
          } else {
            navigate("/");
          }
        });

        return unsubscribe;
      }
    };

    const fetchOtherData = async () => {
      // Add your additional data fetching logic here
      // Example:
      if (userId) {
        try {
          const otherDocRef = db.collection("Users").doc(userId);
          const otherDoc = await otherDocRef.get();

          if (otherDoc.exists) {
            const otherData = otherDoc.data();
            // Process and store other data as needed
          } else {
            console.log("No other document found!");
          }
        } catch (error) {
          console.log("Error getting other document:", error);
        }
      }
    };

    // Call the functions to fetch the data
    const unsubscribeUserData = fetchUserData();
    fetchOtherData();

    // Clean up subscriptions
    return () => {
      if (unsubscribeUserData) {
        unsubscribeUserData();
      }
    };
  }, [userId]);

  const handleSubmit = async () => {
    try {
      // Create a new document in Firestore collection
      await db.collection("Users").doc(userId).update({
        name: sname,
        contact: scontact,
        designation: sdesignation,
        address: saddress,
        country: scountry,
        about: sabout,
      });

      setSuccessForm("Success! Profile Updated");

      setTimeout(() => {
        setSuccessForm("");
      }, 3000);

      // Reset form fields and close the modal
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  //Bank Details

  useEffect(() => {
    const fetchUserData = () => {
      if (userId) {
        const userDocRef = db.collection("Users").doc(userId);
        const unsubscribe = userDocRef.onSnapshot((doc) => {
          if (doc.exists) {
            const userBankData = doc.data();
            setBankData(userBankData);
            setBankName(userBankData.Bank);
            setBName(userBankData.Beneficiary);
            setAccount(userBankData.AccountNo);
            setConfirmaccount(userBankData.ConfirmAccount);
            setIFSC(userBankData.IFSC);
            setUPI(userBankData.UPI);
            setBaddress(userBankData.Address);
            setIban(userBankData.IBAN);
            setSwift(userBankData.Swift);
            setPaypal(userBankData.PayPal);
            setSkrill(userBankData.Skrill);
          } else {
            console.log("No data found");
          }
        });

        return unsubscribe;
      }
    };

    const fetchOtherData = async () => {
      // Add your additional data fetching logic here
      // Example:
      if (userId) {
        try {
          const otherDocRef = db.collection("Users").doc(userId);
          const otherDoc = await otherDocRef.get();

          if (otherDoc.exists) {
            const otherData = otherDoc.data();
            // Process and store other data as needed
          } else {
            console.log("No other document found!");
          }
        } catch (error) {
          console.log("Error getting other document:", error);
        }
      }
    };

    // Call the functions to fetch the data
    const unsubscribeUserData = fetchUserData();
    fetchOtherData();

    // Clean up subscriptions
    return () => {
      if (unsubscribeUserData) {
        unsubscribeUserData();
      }
    };
  }, [userId]);

  const handleSubmitBankDetails = async () => {
    try {
      // Create a new document in Firestore collection
      await db.collection("Users").doc(userId).update({
        Bank: bankname,
        Beneficiary: bname,
        AccountNo: accountno,
        ConfirmAccount: confirmAccount,
        IFSC: ifsc,
        UPI: upi,
        Address: baddress,
        IBAN: iban,
        Swift: swift,
        PayPal: paypal,
        Skrill: skrill,
      });

      setSuccessForm("Success! Details Updated");

      setTimeout(() => {
        setSuccessForm("");
      }, 3000);

      // Reset form fields and close the modal
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  //Update Profile
  const [imageFile, setImageFile] = useState(null);

  const [uploading, setUploading] = useState("");

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const handleProfilePic = async (event) => {
    setUploading("");
    event.preventDefault();
    if (!imageFile) return;

    const storage = getStorage();
    const storageRef = ref(storage, `profileImages/${userId}/profile`);

    // Set the maximum upload size to 1MB (in bytes)

    try {
      await uploadBytes(storageRef, imageFile);
      setUploading("Uploading Profile...");
      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      const userRef = db.collection("Users").doc(userId);
      userRef.update({img: downloadURL});
      setUploading("Profile Updated!");
      setTimeout(() => {
        setUploading("");
      }, 3000);
    } catch (error) {
      alert("Profile picture size should be less than 1MB");
    }
  };
  return (
    <>
      <Sidebar />

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>
            {" "}
            <i
              style={{color: "grey"}}
              class="fa fa-bars"
              id="sidebarBtn"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas"
            ></i>{" "}
            Profile
          </h1>
        </div>

        <section class="section profile">
          <div class="row">
            <div class="col-xl-4">
              <div class="card">
                <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                  <img
                    src={userData.img}
                    alt="Profile"
                    className="rounded-circle"
                    style={{
                      width: "15vh",
                      height: "15vh",
                      objectFit: "cover",
                    }}
                  />
                  <h2>{userData.name}</h2>
                  <span>
                    Emp ID: <b>{userData.empId}</b>
                  </span>
                  <h3>{userData.designation}</h3>

                  {/* <div class="social-links mt-2">
                    <a href="/" class="twitter">
                      <i class="bi bi-twitter"></i>
                    </a>
                    <a href="/" class="facebook">
                      <i class="bi bi-facebook"></i>
                    </a>
                    <a href="/" class="instagram">
                      <i class="bi bi-instagram"></i>
                    </a>
                    <a href="/" class="linkedin">
                      <i class="bi bi-linkedin"></i>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>

            <div class="col-xl-8">
              <div class="card">
                <div class="card-body pt-3">
                  <ul class="nav nav-tabs nav-tabs-bordered">
                    <li class="nav-item">
                      <button
                        class="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                      >
                        Overview
                      </button>
                    </li>

                    <li class="nav-item">
                      <button
                        class="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-edit"
                      >
                        Edit Profile
                      </button>
                    </li>

                    <li class="nav-item">
                      <button
                        class="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-settings"
                      >
                        Bank Details
                      </button>
                    </li>

                    {/* <li class="nav-item">
                      <button
                        class="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-change-password"
                      >
                        Change Password
                      </button>
                    </li> */}
                  </ul>
                  <div class="tab-content pt-2">
                    <div
                      class="tab-pane fade show active profile-overview"
                      id="profile-overview"
                    >
                      <h5 class="card-title">About</h5>
                      <p class="small fst-italic">{userData.about}</p>

                      <h5 class="card-title">Profile Details</h5>

                      <div class="row">
                        <div class="col-lg-3 col-md-4 label ">Full Name</div>
                        <div class="col-lg-9 col-md-8">{userData.name}</div>
                      </div>

                      {/* <div class="row">
                        <div class="col-lg-3 col-md-4 label">Company</div>
                        <div class="col-lg-9 col-md-8">
                          Lueilwitz, Wisoky and Leuschke
                        </div>
                      </div> */}

                      <div class="row">
                        <div class="col-lg-3 col-md-4 label">Designation</div>
                        <div class="col-lg-9 col-md-8">
                          {userData.designation}
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3 col-md-4 label">Country</div>
                        <div class="col-lg-9 col-md-8">{userData.country}</div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3 col-md-4 label">Address</div>
                        <div class="col-lg-9 col-md-8">{userData.address}</div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3 col-md-4 label">Phone</div>
                        <div class="col-lg-9 col-md-8">{userData.contact}</div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3 col-md-4 label">Email</div>
                        <div class="col-lg-9 col-md-8">{userData.email}</div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade profile-edit pt-3"
                      id="profile-edit"
                    >
                      <Form onSubmit={handleProfilePic}>
                        <div class="row mb-3">
                          <label
                            for="profileImage"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Profile Image
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <img
                              src={userData.img}
                              alt="Profile"
                              className="rounded-circle"
                              style={{
                                width: "15vh",
                                height: "15vh",
                                objectFit: "cover",
                              }}
                            />

                            <div className="row">
                              <div className="col-lg">
                                {uploading && (
                                  <Alert
                                    style={{
                                      fontSize: "13px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {uploading}
                                  </Alert>
                                )}
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageUpload}
                                />
                              </div>
                              <div className="col-lg">
                                <Button
                                  size="small"
                                  color="orange"
                                  type="submit"
                                >
                                  <i class="fa fa-upload"></i> Upload
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                      <Form>
                        <div class="row mb-3">
                          <label
                            for="fullName"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Full Name
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <Form.Input
                              type="text"
                              class="form-control"
                              onChange={(e) => setName(e.target.value)}
                              required
                              value={sname}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="about"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            About
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <textarea
                              name="about"
                              class="form-control"
                              onChange={(e) => setAbout(e.target.value)}
                              value={sabout}
                              style={{height: "100px"}}
                            ></textarea>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Job"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Designation
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              name="job"
                              type="text"
                              class="form-control"
                              id="Job"
                              onChange={(e) => setDesignation(e.target.value)}
                              required
                              value={sdesignation}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Country"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Country
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              name="country"
                              type="text"
                              class="form-control"
                              onChange={(e) => setCountry(e.target.value)}
                              required
                              value={scountry}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Address"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Address
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              name="address"
                              type="text"
                              class="form-control"
                              onChange={(e) => setAddress(e.target.value)}
                              required
                              value={saddress}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Phone"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Phone
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              name="phone"
                              type="text"
                              class="form-control"
                              id="Phone"
                              onChange={(e) => setContact(e.target.value)}
                              required
                              value={scontact}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Email"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Email
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              disabled
                              class="form-control"
                              readOnly
                              value={userData.email}
                            />
                          </div>
                        </div>
                        {successForm && (
                          <Alert
                            variant="success"
                            style={{fontSize: "13px", textAlign: "center"}}
                          >
                            {successForm}
                          </Alert>
                        )}

                        <div class="text-center">
                          <Button
                            onClick={handleSubmit}
                            size="small"
                            type="submit"
                            color="blue"
                          >
                            Save Changes
                          </Button>
                        </div>
                      </Form>
                    </div>

                    <div class="tab-pane fade pt-3" id="profile-settings">
                      <Form>
                        <div class="row mb-3">
                          <label
                            for="fullName"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Bank Name<span style={{color: "red"}}>*</span>
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <select
                              name="bankname"
                              onChange={(e) => setBankName(e.target.value)}
                              value={bankname}
                              class="form-control"
                              required
                            >
                              <option selected="selected" value="0">
                                --Select Bank--
                              </option>
                              <option value="ALLAHABAD BANK">
                                ALLAHABAD BANK
                              </option>
                              <option value="ANDHRA BANK">ANDHRA BANK</option>
                              <option value="AXIS BANK">AXIS BANK</option>
                              <option value="STATE BANK OF INDIA">
                                STATE BANK OF INDIA
                              </option>
                              <option value="BANK OF BARODA">
                                BANK OF BARODA
                              </option>
                              <option value="UCO BANK">UCO BANK</option>
                              <option value="UNION BANK OF INDIA">
                                UNION BANK OF INDIA
                              </option>
                              <option value="BANK OF INDIA">
                                BANK OF INDIA
                              </option>
                              <option value="BANDHAN BANK LIMITED">
                                BANDHAN BANK LIMITED
                              </option>
                              <option value="CANARA BANK">CANARA BANK</option>
                              <option value="GRAMIN VIKASH BANK">
                                GRAMIN VIKASH BANK
                              </option>
                              <option value="CORPORATION BANK">
                                CORPORATION BANK
                              </option>
                              <option value="INDIAN BANK">INDIAN BANK</option>
                              <option value="INDIAN OVERSEAS BANK">
                                INDIAN OVERSEAS BANK
                              </option>
                              <option value="ORIENTAL BANK OF COMMERCE">
                                ORIENTAL BANK OF COMMERCE
                              </option>
                              <option value="PUNJAB AND SIND BANK">
                                PUNJAB AND SIND BANK
                              </option>
                              <option value="PUNJAB NATIONAL BANK">
                                PUNJAB NATIONAL BANK
                              </option>
                              <option value="RESERVE BANK OF INDIA">
                                RESERVE BANK OF INDIA
                              </option>
                              <option value="SOUTH INDIAN BANK">
                                SOUTH INDIAN BANK
                              </option>
                              <option value="UNITED BANK OF INDIA">
                                UNITED BANK OF INDIA
                              </option>
                              <option value="CENTRAL BANK OF INDIA">
                                CENTRAL BANK OF INDIA
                              </option>
                              <option value="VIJAYA BANK">VIJAYA BANK</option>
                              <option value="DENA BANK">DENA BANK</option>
                              <option value="BHARATIYA MAHILA BANK LIMITED">
                                BHARATIYA MAHILA BANK LIMITED
                              </option>
                              <option value="FEDERAL BANK LTD ">
                                FEDERAL BANK LTD
                              </option>
                              <option value="HDFC BANK LTD">
                                HDFC BANK LTD
                              </option>
                              <option value="ICICI BANK LTD">
                                ICICI BANK LTD
                              </option>
                              <option value="IDBI BANK LTD">
                                IDBI BANK LTD
                              </option>
                              <option value="PAYTM BANK">PAYTM BANK</option>
                              <option value="FINO PAYMENT BANK">
                                FINO PAYMENT BANK
                              </option>
                              <option value="INDUSIND BANK LTD">
                                INDUSIND BANK LTD
                              </option>
                              <option value="KARNATAKA BANK LTD">
                                KARNATAKA BANK LTD
                              </option>
                              <option value="KOTAK MAHINDRA BANK">
                                KOTAK MAHINDRA BANK
                              </option>
                              <option value="YES BANK LTD">YES BANK LTD</option>

                              <option value="BANK OF INDIA">
                                BANK OF INDIA
                              </option>
                              <option value="BANK OF MAHARASHTRA">
                                BANK OF MAHARASHTRA
                              </option>
                              <option value="OTHER">OTHERS</option>
                            </select>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="about"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Beneficiary name
                            <span style={{color: "red"}}>*</span>
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              placeholder="Beneficiary name"
                              required
                              class="form-control"
                              onChange={(e) => setBName(e.target.value)}
                              value={bname}
                              requi
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Job"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Account No.<span style={{color: "red"}}>*</span>
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              type="text"
                              required
                              class="form-control"
                              placeholder="Account Number"
                              onChange={(e) => setAccount(e.target.value)}
                              value={accountno}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Country"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Confirm Account no.
                            <span style={{color: "red"}}>*</span>
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              type="text"
                              required
                              placeholder="Confirm Account Number"
                              class="form-control"
                              onChange={(e) =>
                                setConfirmaccount(e.target.value)
                              }
                              value={confirmAccount}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="about"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Beneficiary address
                            <span style={{color: "red"}}>*</span>
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <textarea
                              rows={5}
                              required
                              placeholder="Beneficiary address"
                              class="form-control"
                              onChange={(e) => setBaddress(e.target.value)}
                              value={baddress}
                            ></textarea>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Address"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            IFSC Code<span style={{color: "red"}}>*</span>
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              placeholder="IFSC Code"
                              required
                              type="text"
                              class="form-control"
                              onChange={(e) => setIFSC(e.target.value)}
                              value={ifsc}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Address"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            UPI ID
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              placeholder="UPI ID"
                              type="text"
                              class="form-control"
                              onChange={(e) => setUPI(e.target.value)}
                              value={upi}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Phone"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            IBAN (International)
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              placeholder="IBAN"
                              type="text"
                              class="form-control"
                              onChange={(e) => setIban(e.target.value)}
                              value={iban}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Email"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Swift Code
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              placeholder="Swift Code"
                              type="text"
                              class="form-control"
                              onChange={(e) => setSwift(e.target.value)}
                              value={swift}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Email"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            PayPal ID
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              placeholder="PayPal ID"
                              type="text"
                              class="form-control"
                              onChange={(e) => setPaypal(e.target.value)}
                              value={paypal}
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="Email"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Skrill ID
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              placeholder="Skrill ID"
                              type="text"
                              class="form-control"
                              onChange={(e) => setSkrill(e.target.value)}
                              value={skrill}
                            />
                          </div>
                        </div>
                        {successForm && (
                          <Alert
                            variant="success"
                            style={{fontSize: "13px", textAlign: "center"}}
                          >
                            {successForm}
                          </Alert>
                        )}

                        <div class="text-center">
                          <Button
                            onClick={handleSubmitBankDetails}
                            size="small"
                            type="submit"
                            color="blue"
                          >
                            Save Changes
                          </Button>
                        </div>
                      </Form>
                    </div>

                    <div
                      class="tab-pane fade pt-3"
                      id="profile-change-password"
                    >
                      <form>
                        <div class="row mb-3">
                          <label
                            for="currentPassword"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Current Password
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              name="password"
                              type="password"
                              class="form-control"
                              id="currentPassword"
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="newPassword"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            New Password
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              name="newpassword"
                              type="password"
                              class="form-control"
                              id="newPassword"
                            />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label
                            for="renewPassword"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            Re-enter New Password
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              name="renewpassword"
                              type="password"
                              class="form-control"
                              id="renewPassword"
                            />
                          </div>
                        </div>

                        <div class="text-center">
                          <button type="submit" class="btn btn-primary">
                            Change Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Profile;
