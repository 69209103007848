import React from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate, Link } from "react-router-dom";
import bg from "../assets/img/bg.jpg";
import logoa from "../assets/img/logoa.png";
import { Button } from "semantic-ui-react";
function ForgotPassword() {
  const { sendEmail } = useUserAuth();
  const navigate = useNavigate();

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    try {
      await sendEmail(email);
      console.log("Password reset email sent successfully!");
      alert("Password reset email sent successfully!");
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div
        class="container-fluid"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundImage: `url(${bg})`,
        }}
      >
        <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <div className="d-flex align-items-center w-auto">
                    <img
                      src={logoa}
                      alt="logo"
                      style={{ height: "12vh", width: "auto" }}
                    />
                    {/* <span className="d-lg-block" style={{ fontSize: "38px" }}>
                      FXCTS
                    </span> */}
                  </div>
                </div>

                <div class="card mb-3">
                  <div class="card-body">
                    <div class="pt-4 pb-2">
                      <h5 class="card-title text-center pb-0 fs-4">
                        Forgot your Password?
                      </h5>
                    </div>

                    <form
                      onSubmit={handlePasswordReset}
                      class="row g-3 needs-validation"
                    >
                      <div class="col-12">
                        <label
                          for="yourUsername"
                          name="email"
                          class="form-label"
                        >
                          Email ID
                        </label>
                        <div class="input-group has-validation">
                          <input
                            type="email"
                            name="email"
                            placeholder="Registered Email Id"
                            class="form-control"
                            required
                          />
                          <div class="invalid-feedback">
                            Please enter your emailId.
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <Button
                          color="facebook"
                          className="w-100"
                          type="submit"
                        >
                          Send Link
                        </Button>
                      </div>
                      <div class="col-12">
                        <p class="small mb-0">
                          Back to login <Link to="/">Redirect</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ForgotPassword;
