import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBOSz578Qp_U4HoG4rkbOo9-rfqzJ9uGEI",
  authDomain: "falconxdatabase.firebaseapp.com",
  projectId: "falconxdatabase",
  storageBucket: "falconxdatabase.appspot.com",
  messagingSenderId: "542350249814",
  appId: "1:542350249814:web:73626e3e5f79ffe51610f7",
  measurementId: "G-GCHB41E8Q3",
});

// Initialize Firebase
var db = firebaseApp.firestore();
export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp);
export { db };
