import React, { useState, useEffect, useRef } from "react";
import { db, auth } from "../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import "./chatbox.css";
import { onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import ModalFile from "../Admin/ModalFile";
const ModalComment = ({
  open,
  setOpen,
  subject,
  title,
  OrderCode,
  deadline,
  words,
  cost,
  name,

  id,
}) => {
  const [msgs, setMsgs] = useState([]);
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [userDesc, setUserDesc] = useState("");
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState("");

  const chatRef = useRef(null);

  useEffect(() => {
    // Scroll down to the bottom of the chat
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [msgs]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Fileshow
  const [openFile, setOpenFile] = useState(false);
  const [modalfile, setModalFile] = useState({});

  const handleFile = (item) => {
    setOpenFile(true);
    setModalFile(item);
  };

  const today = new Date();
  const chattime =
    today
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getSeconds()
      .toString()
      .padStart(2, "0");

  const current = new Date();

  const chatdate = current
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const handleSubmit = async () => {
    setLoader("Sending ! Please wait...");
    if (!file) {
      try {
        db.collection("Chats")
          .doc(OrderCode)
          .collection("ChatsCollections")
          .doc(`${chatdate}-${chattime}`)
          .set({
            img: userData.img,
            name: userData.name,
            empId: userData.empId,
            userDesc,
            chattime: serverTimestamp(),

            role: userData.role,
            uid: userId,
            fileURL: "",
          });

        // Reset form fields and close the modal
        setFile(null);
        setLoader("");
        setUserDesc("");
        setOpen(true);
      } catch (error) {
        console.log("Error submitting applied project:", error);
      }
    } else {
      try {
        // Create a storage reference
        const storage = getStorage();
        const storageRef = ref(storage, "Chats/" + chattime + file.name);

        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, file);

        // Get the download URL
        const downloadURL = await getDownloadURL(storageRef);

        db.collection("Chats")
          .doc(OrderCode)
          .collection("ChatsCollections")
          .doc(`${chatdate}-${chattime}`)
          .set({
            img: userData.img,
            name: userData.name,
            empId: userData.empId,
            userDesc,
            chattime: serverTimestamp(),

            role: userData.role,
            uid: userId,
            fileURL: downloadURL,
          });

        // Reset form fields and close the modal
        setFile(null);
        setLoader("");
        setUserDesc("");
        setOpen(true);
      } catch (error) {
        console.log("Error submitting applied project:", error);
      }
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  useEffect(() => {
    const unsub = onSnapshot(
      db
        .collection("Chats")
        .doc(OrderCode)
        .collection("ChatsCollections"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setMsgs(list);
        setOpen(true);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const formatTimestamp3 = (chattime) => {
    const date = chattime.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3b5998", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              <i class="fa fa-comments"></i> Chatbox
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3b5998",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              {/* ///////////// */}

              <div
                class="accordion accordion-flush"
                style={{ marginTop: "-15px" }}
                id="accordionFlushExample"
              >
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Project Code: <b>#{OrderCode}</b>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <div class="container-fluid" style={{ fontSize: "12px" }}>
                        <div class="row" style={{ marginTop: "-5px" }}>
                          <div class="col-lg-12 my-2">
                            Project Title: <b>{title}</b>
                          </div>

                          <div class="col-lg-6 my-2">
                            Category: <b>{subject}</b>
                          </div>

                          <div class="col-lg-6 my-2">
                            Words: <b>{words}</b>
                          </div>

                          <div class="col-lg-6 my-2">
                            Deadline:
                            <b> {deadline && formatTimestamp1(deadline)}</b>
                          </div>

                          <div class="col-lg-6 my-2">
                            Cost: <b>₹ {cost}.00</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ///////////// */}

              <div
                className="container-fluid"
                ref={chatRef}
                style={{
                  border: "1px solid #dbdbdb",
                  borderRadius: "5px",
                  overflowY: "scroll",
                  minHeight: "41vh",
                  maxHeight: "42vh",
                }}
              >
                {/* //ChatBox messages */}
                <section>
                  <main>
                    {msgs.map((item) => (
                      /////////////////////////////////////////////////////////////////////

                      <div
                        class="container-fluid"
                        key={item.id}
                        style={{
                          padding: "0",
                          backgroundColor:
                            item.role === "External Expert"
                              ? "#9BABB8"
                              : item.role === "Freelancer"
                              ? "#9BABB8"
                              : item.role === "Internal Expert"
                              ? "#7FBCD2"
                              : item.role === "Admin"
                              ? "#967E76"
                              : "#ffca1c",
                          float:
                            item.role === "External Expert"
                              ? "right"
                              : item.role === "Freelancer"
                              ? "right"
                              : item.role === "Internal Expert"
                              ? "left"
                              : item.role === "Admin"
                              ? "left"
                              : "right",
                          marginTop: "8px",
                          marginBottom: "6px",
                          maxWidth: "85%",
                          borderRadius:
                            item.role === "External Expert"
                              ? "12px 12px 0px 13px"
                              : item.role === "Freelancer"
                              ? "12px 12px 0px 13px"
                              : item.role === "Internal Expert"
                              ? "0px 0px 13px 0px"
                              : item.role === "Admin"
                              ? "12px 12px 13px 0px"
                              : "12px 12px 0px 13px",
                        }}
                      >
                        <div class="row" style={{ padding: "5px" }}>
                          <div class="col-2">
                            <img
                              alt="."
                              className="rounded-circle"
                              src={item.img}
                              style={{
                                objectFit: "cover",
                                width: "45px",
                                height: "45px",
                                marginTop: "-10px",
                                border: "none",
                              }}
                            />
                          </div>
                          <div
                            class="col-9"
                            style={{
                              color: "white",
                              marginLeft: "8px",
                            }}
                          >
                            <span style={{ fontSize: "12px" }}>
                              {item.name}
                              <br></br>
                              <small style={{ fontSize: "9px" }}>
                                {item.role}
                              </small>
                            </span>
                            <p
                              style={{
                                textAlign: "right",
                                fontSize: "9px",
                                marginTop: "-5px",
                                marginRight: "-14px",
                                color: "#fff",
                              }}
                            >
                              {item.chattime && formatTimestamp3(item.chattime)}
                            </p>
                          </div>
                        </div>

                        <div
                          style={{
                            backgroundColor:
                              item.role === "External Expert"
                                ? "#F8F6F4"
                                : item.role === "Freelancer"
                                ? "#F8F6F4"
                                : item.role === "Internal Expert"
                                ? "#e3f8ff"
                                : item.role === "Admin"
                                ? "#F8F6F4"
                                : "#ffca1c",

                            borderRadius:
                              item.role === "External Expert"
                                ? "0px 0px 0px 12px"
                                : item.role === "Freelancer"
                                ? "0px 0px 0px 12px"
                                : item.role === "Internal Expert"
                                ? "0px 0px 12px 0px"
                                : item.role === "Admin"
                                ? "0px 0px 12px 0px"
                                : "0px 0px 0px 12px",
                            padding: "5px",
                          }}
                        >
                          {item.userDesc && item.userDesc.length > 0 ? (
                            <>
                              <div
                                style={{
                                  marginBottom: "8px",
                                  fontSize: "12px",
                                }}
                                contentEditable="false"
                                dangerouslySetInnerHTML={{
                                  __html: `${item.userDesc}`,
                                }}
                              ></div>
                            </>
                          ) : null}

                          {/* <p style={{ fontSize: "12px" }}>{item.userDesc}</p> */}
                          {item.pDesc && item.pDesc.length > 0 ? (
                            <>
                              <div
                                style={{
                                  marginBottom: "8px",
                                  fontSize: "12px",
                                }}
                                contentEditable="false"
                                dangerouslySetInnerHTML={{
                                  __html: `${item.pDesc}`,
                                }}
                              ></div>
                            </>
                          ) : null}

                          {/* {item.fileURL && item.fileURL.length > 0 ? (
                            <>
                              <div
                                onClick={() => handleFile(item)}
                                className="wrap"
                                style={{
                                  fontSize: "11px",
                                  backgroundColor: "#fff",
                                  padding: "10px",
                                  maxWidth: "15vh",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                <i class="fa fa-file"></i> Download File
                              </div>
                              {openFile && (
                                <ModalFile
                                  open={openFile}
                                  setOpen={setOpenFile}
                                  // handleDelete={handleDelete}
                                  {...modalfile}
                                />
                              )}
                            </>
                          ) : null} */}

                          {item.deliverURL && item.deliverURL.length > 0 ? (
                            <>
                              <div
                                onClick={() => handleFile(item)}
                                className="wrap"
                                style={{
                                  fontSize: "11px",
                                  backgroundColor: "#fff",
                                  padding: "10px",
                                  maxWidth: "12vh",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                <i class="fa fa-file"></i> Download File
                              </div>
                              {openFile && (
                                <ModalFile
                                  open={openFile}
                                  setOpen={setOpenFile}
                                  // handleDelete={handleDelete}
                                  {...modalfile}
                                />
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </main>
                </section>{" "}
                {/* //ChatBox messages */}
              </div>

              {loader && (
                <Alert
                  variant="warning"
                  style={{ fontSize: "11px", textAlign: "center" }}
                >
                  {loader}
                </Alert>
              )}

              {/* <Button size="small" onClick={() => setOpen(false)}>
                Cancel
              </Button> */}
            </div>
          </div>

          <div className="container-fluid" style={{ marginTop: "-15px" }}>
            {" "}
            <hr></hr>
            <Form>
              {userData && (
                <>
                  <Form.Input hidden value={userData.name} />
                  <Form.Input hidden value={userData.empId} />
                  <Form.Input hidden value={userData.img} />
                </>
              )}

              <div
                className="content"
                style={{
                  marginTop: "-8px",
                }}
              >
                <div className="row">
                  <div className="col-10">
                    <ReactQuill
                      theme="snow"
                      style={{ height: "6vh" }}
                      value={userDesc}
                      onChange={setUserDesc}
                    />
                    <br></br> <br></br> <br></br>
                    <br></br>
                    <br></br>
                  </div>

                  <div className="col-2" style={{ marginLeft: "-15px" }}>
                    <div class="upload-btn-wrapper">
                      {!file ? (
                        <>
                          <button class="btnUp">
                            <i class="fa fa-upload"></i>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            class="btnUp"
                            style={{ backgroundColor: "grey" }}
                          >
                            <i class="fa fa-upload"></i>
                          </button>
                        </>
                      )}

                      <input
                        onChange={handleFileChange}
                        type="file"
                        name="myfile"
                      />
                    </div>

                    {(userDesc && userDesc.length >= 1) || file ? (
                      <Button
                        size="small"
                        color="facebook"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        <i class="fa fa-send"></i>
                      </Button>
                    ) : (
                      <Button disabled>
                        <i class="fa fa-send"></i>
                      </Button>
                    )}
                  </div>
                </div>

                {/* <Form.Input
                  name="userDesc"
                  placeholder="Type your message here..."
                  onChange={(e) => setUserDesc(e.target.value)}
                  required
                  value={userDesc}
                  style={{
                    position: "absolute",
                    width: "75%",
                    marginLeft: "10%",
                  }}
                /> */}
              </div>

              {/* <div className="row" style={{padding: "1px"}}>
                <div className="col-8" style={{padding: "1px"}}>
                  <Form.Input
                    name="userDesc"
                    placeholder="Type your comment here..."
                    onChange={(e) => setUserDesc(e.target.value)}
                    required
                    value={userDesc}
                  />
                </div>
                <div className="col" style={{padding: "1px"}}>
                  <Form.Input
                    type="file"
                    style={{width: "5vh", height: "30px", margin: "0px"}}
                    onChange={handleFileChange}
                    id="file-input"
                  />
                </div>
                <div className="col" style={{padding: "1px", margin: "0px"}}>
                  {(userDesc && userDesc.length >= 1) || file ? (
                    <Button
                      size="small"
                      color="green"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      <i class="fa fa-send"></i>
                    </Button>
                  ) : (
                    <Button disabled color="green">
                      <i class="fa fa-send"></i>
                    </Button>
                  )}
                </div>
              </div> */}
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalComment;
