import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Button, Modal } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { onSnapshot } from "firebase/firestore";

const ViewFreelancers = ({
  open,
  setOpen,
  OrderCode,
  title,

  id,
}) => {
  const [msgs, setMsgs] = useState([]);
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  // useEffect(() => {
  //   const unsub = onSnapshot(
  //     db
  //       .collection("PartnersProjects")
  //       .doc(id)
  //       .collection("Freelancers"),
  //     (snapshot) => {
  //       let list = [];
  //       snapshot.docs.forEach((doc) => {
  //         list.push({ id: doc.id, ...doc.data() });
  //       });
  //       setMsgs(list);
  //       setOpen(true);
  //     },

  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  //   return () => {
  //     unsub();
  //   };
  // }, []);

  const [invites, setInvites] = useState([]);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db
        .collection("Invites")
        .where("OrderCode", "==", OrderCode);

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setInvites(documents);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setInvites([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              #{OrderCode} : {title}
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            {/* {msgs.map((item) => (
              <div key={item.id}>{item.name}</div>
            ))} */}

            {invites.map((user, index) => (
              <>
                <div class="card">
                  {/* <img src="..." class="card-img-top" alt="..." /> */}
                  <div class="card-body">
                    <h5 class="card-title">
                      {user.fname}
                      <br></br>
                      <span>{user.empId}</span>
                    </h5>
                    <p class="card-text">
                      Task: <b>{user.task}</b>
                    </p>
                    <hr></hr>
                    <div className="row">
                      <div className="col-lg-5">
                        Fees: <b>₹{user.cost}.00</b>
                      </div>
                      <div className="col-lg">
                        Deadline:{" "}
                        <b style={{ color: "red" }}>
                          {" "}
                          {user.deadline && formatTimestamp1(user.deadline)}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div class="modal-footer">
            <Button size="small" onClick={() => setOpen(false)}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewFreelancers;

{
  /* <Button color="grey" onClick={() => setOpen(false)}>
Cancel
</Button> */
}
