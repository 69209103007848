import React, { useState, useEffect, useRef } from "react";
import { db, auth } from "../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import "../Components/chatbox.css";
import { onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import ModalFile from "../Admin/ModalFile";
const ModalComment = ({
  open,
  setOpen,
  title,
  OrderCode,
  deadline,
  uniqueID,
  cost,
  words,
  budget,

  id,
}) => {
  const [msgs, setMsgs] = useState([]);
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [userDesc, setUserDesc] = useState("");
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const [loader, setLoader] = useState("");

  const chatRef = useRef(null);

  useEffect(() => {
    // Scroll down to the bottom of the chat
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [msgs]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Partners").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Fileshow
  const [openFile, setOpenFile] = useState(false);
  const [modalfile, setModalFile] = useState({});

  const handleFile = (item) => {
    setOpenFile(true);
    setModalFile(item);
  };

  const today = new Date();
  const chattime =
    today
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getSeconds()
      .toString()
      .padStart(2, "0");

  const current = new Date();

  const chatdate = current
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const handleSubmit = async () => {
    setLoader("Sending ! Please wait...");
    if (!file) {
      try {
        db.collection("PartnersChats")
          .doc(uniqueID)
          .collection("ChatsCollections")
          .doc(`${chatdate}-${chattime}`)
          .set({
            img: userData.img,
            name: userData.name,
            empId: userData.empId,
            userDesc,
            chattime: serverTimestamp(),
            image: "",
            role: userData.role,
            uid: userId,
            fileURL: "",
          });

        // Reset form fields and close the modal
        setFile(null);
        setLoader("");
        setImg(null);
        setUserDesc("");
        setOpen(true);
      } catch (error) {
        console.log("Error submitting applied project:", error);
      }
    } else {
      try {
        // Create a storage reference
        const storage = getStorage();
        const storageRef = ref(
          storage,
          "PartnersChats/" + chattime + file.name
        );

        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, file);

        // Get the download URL

        const downloadURL = await getDownloadURL(storageRef);
        db.collection("PartnersChats")
          .doc(OrderCode)
          .collection("ChatsCollections")
          .doc(`${chatdate}-${chattime}`)
          .set({
            img: userData.img,
            name: userData.name,
            empId: userData.empId,
            userDesc,
            chattime: serverTimestamp(),
            fileURL: downloadURL,

            role: userData.role,
            uid: userId,
          });

        // Reset form fields and close the modal
        setFile(null);
        setImg(null);
        setLoader("");
        setUserDesc("");
        setOpen(true);
      } catch (error) {
        console.log("Error submitting applied project:", error);
      }
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleImgChange = (e) => {
    const selectedImg = e.target.files[0];
    setImg(selectedImg);
  };

  useEffect(() => {
    const unsub = onSnapshot(
      db
        .collection("PartnersChats")
        .doc(uniqueID)
        .collection("ChatsCollections"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setMsgs(list);
        setOpen(true);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const formatTimestamp3 = (chattime) => {
    const date = chattime.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3b5998", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              <i class="fa fa-comments"></i> Chatbox
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3b5998",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              {/* ///////////// */}

              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Project Code: <b>#{OrderCode}</b>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <div class="container-fluid" style={{ fontSize: "12px" }}>
                        <div class="row" style={{ marginTop: "-5px" }}>
                          <div class="col-lg-12 my-2">
                            Project Title: <b>{title}</b>
                          </div>

                          <div class="col-lg-6 my-2">
                            Words: <b>{words}</b>
                          </div>

                          <div class="col-lg-6 my-2">
                            Deadline:
                            <b> {deadline && formatTimestamp1(deadline)}</b>
                          </div>

                          <div class="col-lg-6 my-2">
                            Project Budget: <b>₹ {budget}.00</b>
                          </div>

                          <div class="col-lg-6 my-2">
                            Project Cost: <b>₹ {cost}.00</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ///////////// */}

              <div
                className="container-fluid"
                ref={chatRef}
                style={{
                  border: "1px solid #dbdbdb",
                  borderRadius: "5px",
                  overflowY: "scroll",
                  minHeight: "54vh",
                  maxHeight: "55vh",
                }}
              >
                {/* //ChatBox messages */}
                <section>
                  <main>
                    {msgs.map((item) => (
                      /////////////////////////////////////////////////////////////////////

                      <div
                        class="container-fluid"
                        key={item.id}
                        style={{
                          padding: "0",
                          width: "90%",

                          float:
                            item.role === "Admin"
                              ? "left"
                              : item.role === "Partner"
                              ? "right"
                              : "right",
                          marginTop: "4px",
                          marginBottom: "4px",
                          maxWidth: "50vh",
                          borderRadius:
                            (item.role === item.role) === "Partner"
                              ? "12px 12px 0px 13px"
                              : item.role === "Admin"
                              ? "12px 12px 13px 0px"
                              : "12px 12px 0px 13px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:
                              item.role === "Admin"
                                ? "#e6f7ff"
                                : item.role === "Partner"
                                ? "#3ac20044"
                                : "rgb(255 202 28 / 57%)",

                            borderRadius:
                              item.role === "Partner"
                                ? "6px 6px 0px 6px"
                                : "6px 6px 6px 0px",
                            padding: "10px",
                          }}
                        >
                          {item.userDesc && item.userDesc.length > 0 ? (
                            <>
                              <div
                                style={{
                                  marginBottom: "8px",
                                  fontSize: "12px",
                                  color: "black",
                                }}
                                contentEditable="false"
                                dangerouslySetInnerHTML={{
                                  __html: `${item.userDesc}`,
                                }}
                              ></div>
                            </>
                          ) : null}

                          {/* <p style={{ fontSize: "12px" }}>{item.userDesc}</p> */}
                          {item.pDesc && item.pDesc.length > 0 ? (
                            <>
                              <div
                                style={{
                                  marginBottom: "8px",
                                  fontSize: "12px",
                                  color: "black",
                                }}
                                contentEditable="false"
                                dangerouslySetInnerHTML={{
                                  __html: `${item.pDesc}`,
                                }}
                              ></div>
                            </>
                          ) : null}

                          {item.fileURL && item.fileURL.length > 0 ? (
                            <>
                              <div
                                onClick={() => handleFile(item)}
                                className="wrap"
                                style={{
                                  fontSize: "11px",
                                  backgroundColor: "#fff",
                                  padding: "10px",
                                  maxWidth: "15vh",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                <i class="fa fa-file"></i> Download File
                              </div>
                              {openFile && (
                                <ModalFile
                                  open={openFile}
                                  setOpen={setOpenFile}
                                  // handleDelete={handleDelete}
                                  {...modalfile}
                                />
                              )}
                            </>
                          ) : null}

                          <p
                            style={{
                              fontSize: "10px",
                              float: "right",
                              color: "grey",

                              marginTop: "-6px",
                            }}
                          >
                            {item.chattime && formatTimestamp3(item.chattime)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </main>
                </section>{" "}
                {/* //ChatBox messages */}
              </div>

              {loader && (
                <Alert
                  variant="warning"
                  style={{ fontSize: "11px", textAlign: "center" }}
                >
                  {loader}
                </Alert>
              )}

              {/* <Button size="small" onClick={() => setOpen(false)}>
                Cancel
              </Button> */}
            </div>
          </div>

          <div className="container-fluid" style={{ marginTop: "-15px" }}>
            {" "}
            <hr></hr>
            <Form>
              {userData && (
                <>
                  <Form.Input hidden value={userData.name} />
                  <Form.Input hidden value={userData.empId} />
                  <Form.Input hidden value={userData.img} />
                </>
              )}

              <div
                className="content"
                style={{
                  marginTop: "-8px",
                }}
              >
                <div className="row">
                  <div className="col-10">
                    <ReactQuill
                      theme="snow"
                      style={{ height: "10vh" }}
                      value={userDesc}
                      onChange={setUserDesc}
                    />
                    <br></br> <br></br> <br></br> <br></br>
                    <br></br>
                  </div>

                  <div className="col-2">
                    <div className="row">
                      <div className="col">
                        <div
                          class="upload-btn-wrapper"
                          style={{ marginLeft: "-10px" }}
                        >
                          {!file ? (
                            <>
                              <button class="btnUp" style={{ padding: "13px" }}>
                                <i class="fa fa-file"></i>
                                <br></br> Upload
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                class="btnUp"
                                style={{ backgroundColor: "grey" }}
                              >
                                <i class="fa fa-file"></i> File
                              </button>
                            </>
                          )}

                          <input
                            onChange={handleFileChange}
                            type="file"
                            name="myfile"
                          />
                        </div>
                      </div>

                      <div
                        className="col-lg-12"
                        style={{ marginLeft: "-10px" }}
                      >
                        {(userDesc && userDesc.length >= 1) || file ? (
                          <Button
                            size="small"
                            color="green"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            <i class="fa fa-send"></i> Send
                          </Button>
                        ) : (
                          <Button disabled>
                            <i class="fa fa-send"></i> Send
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalComment;
