import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { db, auth } from "../../Firebase-config";
import { Button } from "semantic-ui-react";
import { Alert, Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import ModalAdd from "../ModalAdd";
import ModalStatus from "./ModalStatusHelpP";
import ModalMsgHelp from "./ModalMsgHelpP";
import ModalDeleteHelp from "./ModalHelpDeleteP";
function PartnerHelp() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [reports, setReport] = useState([]);
  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("PartnersHelpNeed");

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setReport(documents);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setReport([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  const [openR, setOpenR] = useState(false);
  const [modalapplyR, setModalApplyR] = useState({});
  const handleMsg = (user) => {
    setOpenR(true);
    setModalApplyR(user);
  };

  const [openS, setOpenS] = useState(false);
  const [modalapplyS, setModalApplyS] = useState({});
  const handleStatus = (user) => {
    setOpenS(true);
    setModalApplyS(user);
  };

  const [openD, setOpenD] = useState(false);
  const [modalDelete, setModalD] = useState({});
  const handleDelete = (user) => {
    setOpenD(true);
    setModalD(user);
  };

  return (
    <>
      <div>
        {/* For Mobile */}
        <div
          class="offcanvas offcanvas-start w-25"
          tabindex="-1"
          id="offcanvas"
          data-bs-keyboard="false"
          data-bs-backdrop="false"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <div className="container-fluid" id="mobileSidebar">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              style={{ float: "right" }}
              aria-label="Close"
            >
              <i class="fa fa-close"></i>
            </button>
            <br></br> <br></br>
            <ul className="sidebar-nav" id="sidebar-nav">
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/home">
                  <i className="bi bi-grid"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link " to="/projects">
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Projects</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#tables-nav"
                  data-bs-toggle="collapse"
                  href="/"
                >
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Reports</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                {/* <ul
              id="tables-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <a href="tables-general.html">
                  <i className="bi bi-circle"></i>
                  <span>General Tables</span>
                </a>
              </li>
              <li>
                <a href="tables-data.html">
                  <i className="bi bi-circle"></i>
                  <span>Data Tables</span>
                </a>
              </li>
            </ul> */}
              </li>
            </ul>
          </div>
        </div>

        {/* For Mobile */}

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              <i
                style={{ color: "grey" }}
                class="fa fa-bars"
                id="sidebarBtn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
              ></i>{" "}
              Support Requests
            </h1>
          </div>

          <hr></hr>

          <div className="container-fluid">
            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "12px" }}
            >
              <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Token</th>
                  <th scope="col">Partner Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact</th>
                  <th scope="col">Help Type</th>
                  <th scope="col">Comment</th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    Status
                  </th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    Message
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {reports.length > 0 ? (
                  reports.map((user, index) => (
                    <tr key={user.id}>
                      <td style={{ width: "3vh" }}>{index + 1}</td>
                      <td style={{ minWidth: "5vh", color: "red" }}>
                        <b>#{user.token}</b>
                      </td>
                      <td style={{ minWidth: "20vh" }}>{user.name}</td>
                      <td
                        style={{
                          minWidth: "20vh",
                        }}
                      >
                        {user.email}
                      </td>
                      <td>{user.contact}</td>
                      <td
                        style={{
                          minWidth: "20vh",
                        }}
                      >
                        {user.type}
                      </td>
                      <td
                        style={{
                          minWidth: "25vh",
                        }}
                      >
                        {user.query}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          color:
                            user.status === "Pending"
                              ? "red"
                              : user.status === "Completed"
                              ? "green"
                              : "orange",
                        }}
                      >
                        <b
                          style={{ cursor: "pointer" }}
                          onClick={() => handleStatus(user)}
                        >
                          {user.status}
                        </b>
                        {openS && (
                          <ModalStatus
                            open={openS}
                            setOpen={setOpenS}
                            // handleDelete={handleDelete}
                            {...modalapplyS}
                          />
                        )}
                      </td>

                      <td
                        onClick={() => handleMsg(user)}
                        style={{
                          minWidth: "20vh",
                          cursor: "pointer",
                        }}
                      >
                        {user.adminMsg !== undefined &&
                        user.adminMsg.length > 1 ? (
                          <>{user.adminMsg}</>
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            <i
                              style={{
                                cursor: "pointer",
                                color: "green",

                                fontSize: "14px",
                              }}
                              className="fa fa-pencil-square-o"
                            ></i>
                          </div>
                        )}
                      </td>
                      {openR && (
                        <ModalMsgHelp
                          open={openR}
                          setOpen={setOpenR}
                          // handleDelete={handleDelete}
                          {...modalapplyR}
                        />
                      )}

                      <td
                        style={{
                          minWidth: "3vh",
                          textAlign: "center",
                          color: "red",
                        }}
                      >
                        <i
                          onClick={() => handleDelete(user)}
                          className="fa fa-trash"
                          style={{ cursor: "pointer" }}
                        ></i>
                        {openD && (
                          <ModalDeleteHelp
                            open={openD}
                            setOpen={setOpenD}
                            // handleDelete={handleDelete}
                            {...modalDelete}
                          />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="10"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      No help message found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </main>
      </div>
    </>
  );
}

export default PartnerHelp;
