import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../../Firebase-config";
import { Button, Form } from "semantic-ui-react";
import { Alert, Table } from "react-bootstrap";
import { onSnapshot } from "firebase/firestore";
import ModalComment from "../ModalComment";
import ModalCancel from "../ModalCancel";
import ModalProcessCancel from "../ModalCancelProcess";
import Sidebar from "../Sidebar";
import ModalAdd from "../ModalAdd";
import "../Table.css";
import ModalFile from "../../Admin/ModalFile";
import ChatBox from "../ChatBox";

function Process() {
  const [isChat, setIsChatDiv] = useState(false);

  const toggleDiv = () => {
    setIsChatDiv(!isChat);
  };

  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  //Fileshow
  const [openFile, setOpenFile] = useState(false);
  const [modalfile, setModalFile] = useState({});

  const handleFile = (item) => {
    setOpenFile(true);
    setModalFile(item);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);
  const [processP, setProcess] = useState([]);

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  //Comment Box

  const [openE, setOpenE] = useState(false);
  const [modaledit, setModalEdit] = useState({});

  const handleEdit = (item) => {
    setOpenE(true);
    setModalEdit(item);
  };

  //InProcess Projects data fetch

  //Cancel Modal
  const [openProcess, setOpenCancelProcess] = useState(false);
  const [modalcancelprocess, setModalProcessCancel] = useState({});

  const handleProcessCancel = (item) => {
    setOpenCancelProcess(true);
    setModalProcessCancel(item);
  };

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("Processing").where("uid", "==", uid);
      // .orderBy("applied", "desc");
      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProcess(documents);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setProcess([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setProcess((prevDeadlines) => {
        return prevDeadlines.map((deadline) => {
          const { days, hours, minutes, seconds } = calculateTimeDifference(
            deadline
          );
          return {
            ...deadline,
            days,
            hours,
            minutes,
            seconds,
          };
        });
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const calculateTimeDifference = (deadline) => {
    const deadlineTime = new Date(deadline.deadline);
    const currentTime = new Date();
    const timeDifference = deadlineTime.getTime() - currentTime.getTime();

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  ///TimeFormats

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp2 = (applied) => {
    const date = applied.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <>
      <div>
        {/* For Mobile */}
        <div
          class="offcanvas offcanvas-start w-25"
          tabindex="-1"
          id="offcanvas"
          data-bs-keyboard="false"
          data-bs-backdrop="false"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <div className="container-fluid" id="mobileSidebar">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              style={{ float: "right" }}
              aria-label="Close"
            >
              <i class="fa fa-close"></i>
            </button>
            <br></br> <br></br>
            <ul className="sidebar-nav" id="sidebar-nav">
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/home">
                  <i className="bi bi-grid"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link " to="/projects">
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Projects</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#tables-nav"
                  data-bs-toggle="collapse"
                  href="/"
                >
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Reports</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                {/* <ul
                id="tables-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <a href="tables-general.html">
                    <i className="bi bi-circle"></i>
                    <span>General Tables</span>
                  </a>
                </li>
                <li>
                  <a href="tables-data.html">
                    <i className="bi bi-circle"></i>
                    <span>Data Tables</span>
                  </a>
                </li>
              </ul> */}
              </li>

              <li className="nav-item my-5">
                <Button
                  color="facebook"
                  onClick={() => handleAddProject()}
                  style={{ marginLeft: "18px" }}
                  className="collapsed"
                >
                  <span>Create Project</span>
                </Button>
                {openP && (
                  <ModalAdd
                    open={openP}
                    setOpen={setOpenP}
                    // handleDelete={handleDelete}
                    {...modalapplyP}
                  />
                )}
              </li>
            </ul>
          </div>
        </div>

        {/* For Mobile */}

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              <i
                style={{ color: "grey" }}
                class="fa fa-bars"
                id="sidebarBtn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
              ></i>{" "}
              Project Dashboard
            </h1>
          </div>

          <hr></hr>

          {userData && userData.role === "Freelancer" && (
            <div
              className="search-bar"
              id="freealert"
              style={{ marginTop: "10px" }}
            >
              <Alert variant="danger">
                Hello buddy! To work as a freelancer, you must contact us @{" "}
                <a href="mailto: hr@falconxx.com">hr@falconxx.com</a> |{" "}
                <a href="tel: 8838173949">+91 8838173949</a>/
                <a href="tel: 8248533577">+91 8248533577</a>
              </Alert>
            </div>
          )}

          <div className="container-fluid">
            <div className="row">
              <div className="col-lg">
                <Link to="/projects">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Available
                  </Button>
                </Link>

                <Link to="/invite_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Invited
                  </Button>
                </Link>
                <Link to="/applied_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Applied
                  </Button>
                </Link>
                <Button
                  size="small"
                  color="facebook"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  In Process
                </Button>
                <Link to="/delivered_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Delivered
                  </Button>
                </Link>

                <Link to="/cancelled_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Cancelled
                  </Button>
                </Link>
              </div>
            </div>

            <br></br>

            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "12px" }}
            >
              <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Project Code</th>
                  <th scope="col">Project Title</th>
                  <th scope="col">Category</th>
                  <th scope="col">Project Deadline</th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    File
                  </th>
                  <th scope="col">AppliedOn</th>

                  <th scope="col">Project Cost</th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Remaining Time
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {processP.length > 0 ? (
                  processP.map((item, index) => (
                    <tr key={item.id}>
                      <th scope="row" style={{ minWidth: "5vh" }}>
                        {index + 1}
                      </th>
                      <td style={{ minWidth: "11vh" }}>#{item.OrderCode}</td>
                      <td
                        style={{
                          minWidth: "19vh",
                        }}
                      >
                        {item.title}
                      </td>
                      <td
                        style={{
                          minWidth: "22vh",
                        }}
                      >
                        {item.subject}
                      </td>
                      <td style={{ color: "red", minWidth: "22vh" }}>
                        <b>
                          {item.deadline && formatTimestamp1(item.deadline)}
                        </b>
                      </td>
                      <td
                        style={{
                          minWidth: "5vh",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {item.fileURL && item.fileURL.length > 0 ? (
                          <a
                            href={item.fileURL}
                            target="_blank"
                            style={{ color: "cornflowerblue" }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>
                      <td style={{ color: "green", minWidth: "22vh" }}>
                        <b>{item.applied && formatTimestamp2(item.applied)}</b>
                      </td>
                      <td style={{ minWidth: "11vh" }}>₹ {item.cost}.00</td>
                      <td
                        style={{
                          textAlign: "center",
                          minWidth: "20vh",
                          fontStyle: "italic",
                        }}
                      >
                        <b
                          style={{
                            color:
                              item.days <= 0 &&
                              item.hours <= 0 &&
                              item.minutes <= 30
                                ? "red"
                                : item.days <= 0 &&
                                  item.hours <= 0 &&
                                  item.minutes <= 50
                                ? "orange"
                                : "green",
                          }}
                        >
                          {item.days} Days, {item.hours}h {item.minutes}m{" "}
                          {item.seconds}s
                        </b>
                      </td>

                      <td style={{ textAlign: "center" }}>
                        <div class="ui buttons">
                          <Button
                            style={{ fontSize: "11px" }}
                            type="button"
                            color="blue"
                            onClick={() =>
                              navigate(`/deliver_project/${item.id}`)
                            }
                          >
                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                          </Button>

                          <Button
                            type="button"
                            onClick={() => handleEdit(item)}
                            // onClick={() => navigate(`/comment/${item.id}`)}
                            style={{ marginLeft: "3px", fontSize: "11px" }}
                          >
                            Comment
                          </Button>
                          {openE && (
                            <ModalComment
                              open={openE}
                              setOpen={setOpenE}
                              // handleDelete={handleDelete}
                              {...modaledit}
                            />
                          )}

                          <Button
                            type="button"
                            color="red"
                            onClick={() => handleProcessCancel(item)}
                            style={{ marginLeft: "3px", fontSize: "11px" }}
                          >
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </Button>
                          {openProcess && (
                            <ModalProcessCancel
                              open={openProcess}
                              setOpen={setOpenCancelProcess}
                              // handleDelete={handleDelete}
                              {...modalcancelprocess}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="10"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </main>

        {/* //ChatBox */}
        {isChat && <ChatBox />}
        <div id="mybutton">
          <button onClick={toggleDiv} class="feedback">
            <i class="fa fa-comments"></i>
          </button>
        </div>
      </div>
    </>
  );
}

export default Process;
