import React, { useState, useEffect, useRef } from "react";
import { db, auth } from "../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { serverTimestamp } from "firebase/firestore";
import "./chatbox.css";
import { onSnapshot } from "firebase/firestore";

const ModalChat = ({ open, setOpen, empId, role, name, uid }) => {
  const [msgs, setMsgs] = useState([]);
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");

  const chatRef = useRef(null);

  const today = new Date();
  const chattime =
    today
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getSeconds()
      .toString()
      .padStart(2, "0");

  const current = new Date();

  const chatdate = current
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");
  useEffect(() => {
    // Scroll down to the bottom of the chat
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [msgs]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const handleSubmitData = async () => {
    try {
      db.collection("ChatBox")
        .doc(uid)
        .collection("Chats")
        .doc(`${chatdate}-${chattime}`)
        .set({
          img: userData.img,
          name: userData.name,
          empId: userData.empId,
          message,
          chattime: serverTimestamp(),
          role: userData.role,
          uid: userId,
        });

      db.collection("ChatBox")
        .doc(uid)
        .update({
          // img: userData.img,
          // name: userData.name,
          // empId: userData.empId,
          message,
          chattime: serverTimestamp(),
          sender: "Usended",
        });

      setMessage("");
      setOpen(true);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  useEffect(() => {
    const unsub = onSnapshot(
      db
        .collection("ChatBox")
        .doc(uid)
        .collection("Chats"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setMsgs(list);
        setOpen(true);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  ///TimeFormats
  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Chatbox
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <p style={{ fontSize: "12px" }}>
                Name: <b style={{ color: "black" }}>{name}</b> | Role:{" "}
                <b style={{ color: "black" }}> {role}</b> | Employee ID:{" "}
                <b style={{ color: "black" }}>{empId}</b>
              </p>

              <div
                className="container-fluid"
                ref={chatRef}
                style={{
                  border: "1px solid #dbdbdb",
                  borderRadius: "5px",
                  overflowY: "scroll",
                  minHeight: "55vh",
                  maxHeight: "36vh",
                }}
              >
                {/* //ChatBox messages */}
                <section class="msger">
                  <main class="msger-chat">
                    {msgs.map((item) =>
                      item.role === "External Expert" ||
                      item.role === "Freelancer" ? (
                        <div class="msg left-msg" key={item.id}>
                          <div class="msg-bubble" style={{ minWidth: "248px" }}>
                            {item.message && item.message.length > 0 ? (
                              <div
                                class="msg-text"
                                style={{ fontSize: "12px", lineHeight: "15px" }}
                              >
                                {item.message}
                              </div>
                            ) : null}
                            <br></br>

                            <p
                              style={{
                                fontSize: "10px",
                                float: "right",

                                marginBottom: "-5px",
                              }}
                            >
                              {item.chattime && formatTimestamp(item.chattime)}
                            </p>

                            <p
                              style={{
                                fontSize: "10px",
                                float: "left",
                                color:
                                  item.role === "Freelancer"
                                    ? "grey"
                                    : "External Expert"
                                    ? "blue"
                                    : "green",
                                marginBottom: "-5px",
                              }}
                            >
                              {item.role}
                            </p>
                          </div>
                        </div>
                      ) : item.role === "Admin" ||
                        item.role === "Internal Expert" ||
                        item.role === "Bot" ? (
                        <div class="msg right-msg" key={item.id}>
                          <div
                            class="msg-bubble"
                            id="rightchat"
                            style={{ minWidth: "248px" }}
                          >
                            {item.message && item.message.length > 0 ? (
                              <div
                                class="msg-text"
                                style={{ fontSize: "12px", lineHeight: "15px" }}
                              >
                                {item.message}
                              </div>
                            ) : null}
                            <br></br>

                            <p
                              style={{
                                fontSize: "10px",
                                float: "right",

                                marginBottom: "-5px",
                              }}
                            >
                              {item.chattime && formatTimestamp(item.chattime)}
                            </p>

                            <p
                              style={{
                                fontSize: "10px",
                                float: "left",
                                color:
                                  item.role === "Admin"
                                    ? "red"
                                    : "Internal Expert"
                                    ? "orange"
                                    : "grey",
                                marginBottom: "-5px",
                              }}
                            >
                              {item.role}
                            </p>
                          </div>
                        </div>
                      ) : null
                    )}
                  </main>
                </section>
                {/* //ChatBox messages */}
              </div>
              {loader && (
                <Alert
                  variant="warning"
                  style={{ fontSize: "11px", textAlign: "center" }}
                >
                  {loader}
                </Alert>
              )}

              <Form>
                {userData && (
                  <>
                    <Form.Input hidden value={userData.name} />
                    <Form.Input hidden value={userData.empId} />
                    <Form.Input hidden value={userData.img} />
                  </>
                )}

                <div className="row my-2">
                  <div className="col-10">
                    <Form.Input
                      name="userDesc"
                      placeholder="Type a message..."
                      onChange={(e) => setMessage(e.target.value)}
                      required
                      value={message}
                    />
                  </div>
                  <div className="col-2">
                    {message && message.length >= 1 ? (
                      <Button
                        size="small"
                        color="facebook"
                        type="submit"
                        onClick={handleSubmitData}
                      >
                        <i class="fa fa-send" style={{ fontSize: "16px" }}></i>
                      </Button>
                    ) : (
                      <Button disabled color="facebook">
                        <i class="fa fa-send" style={{ fontSize: "16px" }}></i>
                      </Button>
                    )}
                  </div>
                </div>
              </Form>

              {/* <Button size="small" onClick={() => setOpen(false)}>
                Cancel
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalChat;
