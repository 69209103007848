import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../../Firebase-config";
import { Button } from "semantic-ui-react";
import { Alert, Table } from "react-bootstrap";

import ModalComment from "../ModalComment";
import ModalCancel from "../ModalCancel";
import Sidebar from "../Sidebar";
import ModalAdd from "../ModalAdd";
import "../Table.css";
import ModalFile from "../../Admin/ModalFile";

import ChatBox from "../ChatBox";
function Applied() {
  const [isChat, setIsChatDiv] = useState(false);

  const toggleDiv = () => {
    setIsChatDiv(!isChat);
  };

  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  //Fileshow
  const [openFile, setOpenFile] = useState(false);
  const [modalfile, setModalFile] = useState({});

  const handleFile = (item) => {
    setOpenFile(true);
    setModalFile(item);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);
  const [appliedP, setApplied] = useState([]);

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  //Comment Box

  const [openE, setOpenE] = useState(false);
  const [modaledit, setModalEdit] = useState({});

  const handleEdit = (item) => {
    setOpenE(true);
    setModalEdit(item);
  };

  // getting Applied projects from firestore collection and updating the state

  const [openc, setOpenC] = useState(false);
  const [modalCancel, setModalCancel] = useState({});
  const handleCancel = (user) => {
    setOpenC(true);
    setModalCancel(user);
  };

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db.collection("Applied").where("uid", "==", uid);

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setApplied(documents);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setApplied([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  ///TimeFormats
  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp2 = (applied) => {
    const date = applied.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <>
      <div>
        {/* For Mobile */}
        <div
          class="offcanvas offcanvas-start w-25"
          tabindex="-1"
          id="offcanvas"
          data-bs-keyboard="false"
          data-bs-backdrop="false"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <div className="container-fluid" id="mobileSidebar">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              style={{ float: "right" }}
              aria-label="Close"
            >
              <i class="fa fa-close"></i>
            </button>
            <br></br> <br></br>
            <ul className="sidebar-nav" id="sidebar-nav">
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/home">
                  <i className="bi bi-grid"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link " to="/projects">
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Projects</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#tables-nav"
                  data-bs-toggle="collapse"
                  href="/"
                >
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Reports</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                {/* <ul
                id="tables-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <a href="tables-general.html">
                    <i className="bi bi-circle"></i>
                    <span>General Tables</span>
                  </a>
                </li>
                <li>
                  <a href="tables-data.html">
                    <i className="bi bi-circle"></i>
                    <span>Data Tables</span>
                  </a>
                </li>
              </ul> */}
              </li>

              <li className="nav-item my-5">
                <Button
                  color="facebook"
                  onClick={() => handleAddProject()}
                  style={{ marginLeft: "18px" }}
                  className="collapsed"
                >
                  <span>Create Project</span>
                </Button>
                {openP && (
                  <ModalAdd
                    open={openP}
                    setOpen={setOpenP}
                    // handleDelete={handleDelete}
                    {...modalapplyP}
                  />
                )}
              </li>
            </ul>
          </div>
        </div>

        {/* For Mobile */}

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              <i
                style={{ color: "grey" }}
                class="fa fa-bars"
                id="sidebarBtn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
              ></i>{" "}
              Project Dashboard
            </h1>
          </div>

          <hr></hr>

          {userData && userData.role === "Freelancer" && (
            <div
              className="search-bar"
              id="freealert"
              style={{ marginTop: "10px" }}
            >
              <Alert variant="danger">
                Hello buddy! To work as a freelancer, you must contact us @{" "}
                <a href="mailto: hr@falconxx.com">hr@falconxx.com</a> |{" "}
                <a href="tel: 8838173949">+91 8838173949</a>/
                <a href="tel: 8248533577">+91 8248533577</a>
              </Alert>
            </div>
          )}

          <div className="container-fluid">
            <div className="row">
              <div className="col-lg">
                <Link to="/projects">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Available
                  </Button>
                </Link>

                <Link to="/invite_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Invited
                  </Button>
                </Link>
                <Button
                  color="facebook"
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Applied
                </Button>

                <Link to="/in_process_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    In Process
                  </Button>
                </Link>

                <Link to="/delivered_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Delivered
                  </Button>
                </Link>

                <Link to="/cancelled_">
                  <Button
                    size="small"
                    style={{
                      margin: "1px",
                      fontSize: "11px",
                    }}
                  >
                    Cancelled
                  </Button>
                </Link>
              </div>
            </div>

            <br></br>

            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "12px" }}
            >
              <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Project Code</th>
                  <th scope="col">Project Title</th>
                  <th scope="col">Category</th>
                  <th scope="col">Posted Date</th>
                  <th scope="col">Project Deadline</th>
                  <th scope="col">Project Cost</th>
                  <th scope="col">Applied On</th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    File
                  </th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {appliedP.length > 0 ? (
                  appliedP.map((user, index) => (
                    <tr key={user.id}>
                      <td style={{ width: "5vh" }}>{index + 1}</td>
                      <td style={{ minWidth: "13vh" }}>#{user.OrderCode}</td>
                      <td
                        style={{
                          minWidth: "20vh",
                        }}
                      >
                        {user.title}
                      </td>
                      <td
                        style={{
                          minWidth: "20vh",
                        }}
                      >
                        {user.subject}
                      </td>
                      <td style={{ minWidth: "22vh" }}>
                        {user.posted && formatTimestamp(user.posted)}
                      </td>
                      <td style={{ color: "red", minWidth: "22vh" }}>
                        <b>
                          {user.deadline && formatTimestamp1(user.deadline)}
                        </b>
                      </td>
                      <td style={{ minWidth: "12vh" }}>
                        {user.cost && user.cost.length > 1 ? (
                          <>₹ {user.cost}.00</>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td style={{ color: "green", minWidth: "22vh" }}>
                        <b>{user.applied && formatTimestamp2(user.applied)}</b>
                      </td>
                      <td
                        style={{
                          minWidth: "5vh",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {user.fileURL && user.fileURL.length > 0 ? (
                          <a
                            href={user.fileURL}
                            target="_blank"
                            style={{ color: "cornflowerblue" }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>

                      <td style={{ textAlign: "center" }}>
                        <div class="ui buttons">
                          <Button
                            type="button"
                            onClick={() => handleEdit(user)}
                            // onClick={() => navigate(`/comment/${item.id}`)}
                            style={{ marginLeft: "3px", fontSize: "11px" }}
                          >
                            Comment
                          </Button>
                          {openE && (
                            <ModalComment
                              open={openE}
                              setOpen={setOpenE}
                              // handleDelete={handleDelete}
                              {...modaledit}
                            />
                          )}

                          <Button
                            onClick={() => handleCancel(user)}
                            style={{ fontSize: "11px" }}
                            color="red"
                            type="button"
                          >
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </Button>
                          {openc && (
                            <ModalCancel
                              open={openc}
                              setOpen={setOpenC}
                              // handleDelete={handleDelete}
                              {...modalCancel}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="10"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </main>

        {/* //ChatBox */}
        {isChat && <ChatBox />}
        <div id="mybutton">
          <button onClick={toggleDiv} class="feedback">
            <i class="fa fa-comments"></i>
          </button>
        </div>
      </div>
    </>
  );
}

export default Applied;
