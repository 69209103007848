import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { db } from "../Firebase-config";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import imagebg from "./addproject.jpg";
import "./design.css";
const AddProjects = () => {
  const [fileF, setFileF] = useState(null);
  const [pDesc, setPdesc] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [words, setWords] = useState("");
  const [cost, setCost] = useState("");
  const [deadline, setDeadline] = useState("");
  const [loader, setLoader] = useState("");
  const [success, setsuccessLoader] = useState("");
  const isFinalDisabled = !fileF;
  const [isSubmitting, setIsSubmitting] = useState(false);

  function generateUniqueId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uniqueId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters.charAt(randomIndex);
    }

    return uniqueId;
  }

  const uniqueId = generateUniqueId(5);

  const today = new Date();
  const chattime =
    today
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getSeconds()
      .toString()
      .padStart(2, "0");
  const current = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const chatdate = current.toLocaleString("en-US", options);

  const handleSubmitComplete = async () => {
    setIsSubmitting(true);
    setLoader("Adding Project ! Please wait...");
    setsuccessLoader("");

    if (!fileF) {
      try {
        // Get the download URL

        db.collection("ClientsProjects")
          .doc(`${chatdate}-${chattime}`)
          .set({
            name,
            email,
            contact,
            title,
            words,
            idd: `${chatdate}-${chattime}`,
            subject,
            cost,
            deadline,
            pDesc,
            status: "Pending",
            posted: serverTimestamp(),
            empId: "--",
            role: "Website Client",
          });

        // Reset form fields and close the modal
        setFileF(null);
        setPdesc("");
        setName("");
        setEmail("");
        setContact("");
        setSubject("");
        setTitle("");
        setCost("");
        setDeadline("");
        setWords("");
        setLoader("");
        setsuccessLoader("Project added successfully!");
        setTimeout(() => {
          setLoader("");
          setsuccessLoader("");
          setIsSubmitting(false);
        }, 5000);
      } catch (error) {
        console.log("Error submitting applied project:", error);
        setIsSubmitting(false);
      }
    } else {
      try {
        // Create a storage reference
        const storage = getStorage();
        const storageRef = ref(
          storage,
          "ClientsProjects/" + chattime + fileF.name
        );

        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, fileF);

        // Get the download URL
        const downloadURL = await getDownloadURL(storageRef);

        db.collection("ClientsProjects")
          .doc(`${chatdate}-${chattime}`)
          .set({
            name,
            email,
            contact,
            title,
            words,
            fileURL: downloadURL,
            subject,
            cost,
            deadline,
            pDesc,
            idd: `${chatdate}-${chattime}`,
            status: "Pending",
            posted: serverTimestamp(),
            empId: "--",
            role: "Website Client",
          });

        // Reset form fields and close the modal
        setFileF(null);
        setPdesc("");
        setName("");
        setEmail("");
        setContact("");
        setSubject("");
        setTitle("");
        setCost("");
        setDeadline("");
        setWords("");
        setLoader("");
        setsuccessLoader("Project added successfully!");
        setTimeout(() => {
          setLoader("");
          setsuccessLoader("");
          setIsSubmitting(false);
        }, 5000);
      } catch (error) {
        console.log("Error submitting applied project:", error);
        setIsSubmitting(false);
      }
    }
  };

  const handleFileChangeF = (e) => {
    const selectedFile = e.target.files[0];
    setFileF(selectedFile);
  };

  return (
    <>
      <img
        id="addprojectImg"
        src={imagebg}
        alt=""
        style={{ width: "100%", objectFit: "cover" }}
      />
      <div className="container my-2">
        <div className="row my-3">
          <h1
            className="animation a1"
            style={{
              backgroundColor: "#3E6399",
              color: "white",
              maxWidth: "32vh",
              margin: "10px",
              padding: "5px 6px 6px 12px",
              borderRadius: "5px",
            }}
          >
            Order Now
          </h1>
          <Form className="form">
            <div className="row">
              <div className="col-lg-4 my-2">
                {" "}
                <Form.Input
                  label="Full Name"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  name="name"
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="col-lg-4 my-2">
                <Form.Input
                  type="email"
                  label="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  name="email"
                  placeholder="Enter your email address"
                  required
                />
              </div>
              <div className="col-lg-4 my-2">
                {" "}
                <Form.Input
                  type="tel"
                  label="Phone Number"
                  onChange={(e) => setContact(e.target.value)}
                  value={contact}
                  name="phone"
                  placeholder="Enter your phone number"
                  pattern="[0-9]{10}"
                  required
                />
              </div>
              <div className="col-lg-4 my-2">
                <Form.Field
                  label="Category"
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                  control="select"
                  name="assignment_type"
                  required
                >
                  <option value=" ">--Select Category--</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Website Design">Website Design</option>
                  <option value="Website Re-design">Website Re-design</option>
                  <option value="ReactJs Website">ReactJs Website</option>
                  <option value="WordPress Website">WordPress Website</option>

                  <option value="Woo Commerce Website">
                    Woo Commerce Website
                  </option>
                  <option value="Landing Page Webiste">
                    Landing Page Website
                  </option>
                  <option value="Static Website">Static Website</option>
                  <option value="Dynamic Website">Dynamic Website</option>
                  <option value="Sourcing">Sourcing</option>
                  <option value="Data Entry Work">Data Entry Work</option>
                  <option value="Meta Data">Meta Data</option>
                  <option value="Machine Learning">Machine Learning</option>
                  <option value="AI tools Designing">AI tools Designing</option>
                  <option value="Dashboard Development Project">
                    Dashboard Development Project
                  </option>
                  <option value="Python Projects">Python Projects</option>

                  <option value="ERP">ERP</option>
                  <option value="SAP">SAP</option>
                  <option value="Web Scrapping">Web Scrapping</option>
                  <option value="Software Testing">Software Testing</option>
                  <option value="Accounting and Finance">
                    Accounting and Finance
                  </option>
                  <option value="Medical Sciences">Medical Sciences</option>
                  <option value="Design">Design</option>
                  <option value="Video & Audio Editing">
                    Video & Audio Editing
                  </option>
                  <option value="Lifestyle">Lifestyle</option>
                  <option value="Writing & Transalation">
                    Writing & Transalation
                  </option>
                  <option value="Consulting & HR">Consulting & HR</option>

                  <option value="IT Computer Science">
                    IT Computer Science
                  </option>

                  <option value="Engineering">Engineering</option>
                  <option value="Science and Maths">Science and Maths</option>
                  <option value="Law">Law</option>
                  <option value="Statistics">Statistics</option>
                  <option value="General Subjects">General Subjects</option>
                  <option value="Arts and Humanities">
                    Arts and Humanities
                  </option>
                  <option value="Economics">Economics</option>
                  <option value="Management">Management</option>
                  <option value="Graphic Designing">Graphic Designing</option>
                  <option value="Mechanical Design">Mechanical Design</option>
                  <option value="Architectural Design">
                    Architectural Design
                  </option>
                  <option value="Simulation">Simulation</option>
                  <option value="Mechatronics">Mechatronics</option>
                  <option value="Aeronautical Design">
                    Aeronautical Design
                  </option>
                  <option value="ChatGPT/GPT Works">ChatGPT/GPT Works</option>

                  <option value="Music">Music</option>
                  <option value="Other">Other</option>
                </Form.Field>
              </div>
              <div className="col-lg-6 my-2">
                {" "}
                <Form.Input
                  type="text"
                  label="Title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  name="subject"
                  placeholder="Enter the title of the assignment"
                  required
                />
              </div>
              <div className="col-lg-2 my-2">
                <Form.Input
                  label="Word Count"
                  type="number"
                  onChange={(e) => setWords(e.target.value)}
                  value={words}
                  name="pages"
                  placeholder="Enter the word count required"
                />
              </div>
              <div className="col-lg-8 my-2">
                <label>
                  <b>Project Description</b>
                </label>
                <ReactQuill
                  theme="snow"
                  style={{ height: "20vh" }}
                  value={pDesc}
                  onChange={setPdesc}
                />
                <br></br> <br></br> <br></br>
              </div>

              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-6 my-2">
                    <Form.Input
                      label="Estimated Budget"
                      type="number"
                      id="budget"
                      onChange={(e) => setCost(e.target.value)}
                      value={cost}
                      placeholder="Enter the budget"
                    />
                  </div>
                  <div className="col-lg-6 my-2">
                    <Form.Input
                      label="Deadline"
                      type="datetime-local"
                      value={deadline}
                      onChange={(e) => setDeadline(e.target.value)}
                      name="deadline"
                      required
                    />
                  </div>
                  <div className="col-lg-12 my-2">
                    <small style={{ color: "red" }}>
                      *File size should be less than 10MB
                    </small>
                    <Form.Input
                      onChange={handleFileChangeF}
                      label="File Upload"
                      type="file"
                      id="file"
                      name="file"
                    />
                  </div>
                  <div className="col-lg-12 my-2">
                    {loader && (
                      <Alert
                        variant="warning"
                        style={{ fontSize: "11px", textAlign: "center" }}
                      >
                        {loader}
                      </Alert>
                    )}
                    {success && (
                      <Alert
                        variant="success"
                        style={{ fontSize: "11px", textAlign: "center" }}
                      >
                        {success}
                      </Alert>
                    )}
                  </div>
                  <div className="col-lg-8 my-2"></div>
                  <div className="col-lg-4 my-3 text-center">
                    {title.length > 0 &&
                    deadline.length > 0 &&
                    subject.length > 0 &&
                    email.length > 0 &&
                    contact.length > 0 &&
                    pDesc.length > 0 &&
                    name.length > 0 ? (
                      <>
                        <Button
                          disabled={isSubmitting}
                          onClick={handleSubmitComplete}
                          type="button"
                          color="facebook"
                        >
                          Place Order
                        </Button>
                      </>
                    ) : (
                      <Button disabled type="button" color="facebook">
                        Place Order
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddProjects;
