import React, { useState, useEffect } from "react";
import { Button, Form } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../Firebase-config";
import { onSnapshot } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
function ChatBox() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);
  // ChatBOx

  const today = new Date();
  const chattime =
    today
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    today
      .getSeconds()
      .toString()
      .padStart(2, "0");

  const current = new Date();

  const chatdate = current
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const [chatsMsg, setChat] = useState([]);
  const [message, setMessage] = useState(null);

  const handleSubmitData = async () => {
    try {
      db.collection("ChatBox")
        .doc(userId)
        .collection("Chats")
        .doc(`${chatdate}-${chattime}`)
        .set({
          empId: userData.empId,
          img: userData.img,
          message,
          chattime: serverTimestamp(),
          role: userData.role,
          uid: userId,
        })
        .then(() => {
          db.collection("ChatBox")
            .doc(userId)
            .set({
              img: userData.img,
              name: userData.name,
              empId: userData.empId,
              message,
              chattime: serverTimestamp(),
              sender: "Anew",
              role: userData.role,
              uid: userId,
            });
        });

      setMessage("");
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      const unsub = onSnapshot(
        db
          .collection("ChatBox")
          .doc(userId)
          .collection("Chats"),
        (snapshot) => {
          let list = [];
          snapshot.docs.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
          });
          setChat(list);
        },

        (error) => {
          console.error(error);
        }
      );
      return () => {
        unsub();
      };
    } else {
      console.error("User ID is missing or invalid");
    }
  }, []);

  const formatTimestamp3 = (chattime) => {
    const date = chattime.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div>
      <div class="WorldChat">
        <header>
          <Button
            size="tiny"
            color="primary"
            style={{
              border: "1px solid white",
              marginTop: "9px",
              borderRadius: "15px",
              width: "100px",
            }}
          >
            <i class="fa fa-comment"></i> <i class="fa fa-commet"></i>{" "}
            <i class="fa fa-commet"></i>Chat
          </Button>
          <h1>How can we help? Chat with us live!</h1>
          <p style={{ fontSize: "12px", marginTop: "-22px" }}>
            FXCTS Chat Support
          </p>
        </header>
        <section
          id="message-list"
          style={{ padding: "5px", overflowY: "scroll", height: "39.7vh" }}
        >
          {/* <Alert
                style={{margin: "5px", textAlign: "center"}}
                variant="danger"
              >
                <i class="fa fa-power-off" aria-hidden="true"></i> Chat Offline
              </Alert> */}

          {chatsMsg.map((item) =>
            item.role === "Admin" ||
            item.role === "Internal Expert" ||
            item.role === "Bot" ? (
              <div class="msg left-msg" key={item.id}>
                <img
                  alt="admin"
                  class="msg-img"
                  src={item.img}
                  style={{ objectFit: "cover" }}
                />

                <div class="msg-bubble">
                  <div class="msg-info">
                    <div
                      class="msg-info-name"
                      style={{ fontSize: "13px" }}
                    ></div>
                    <div
                      class="msg-info-time"
                      style={{
                        fontSize: "10px",
                        marginTop: "-12px",
                        color: "grey",
                      }}
                    >
                      {item.chattime && formatTimestamp3(item.chattime)}
                    </div>
                  </div>

                  {item.message && item.message.length > 0 ? (
                    <div
                      class="msg-text"
                      style={{ fontSize: "12px", lineHeight: "15px" }}
                    >
                      {item.message}
                    </div>
                  ) : null}

                  <p
                    style={{
                      fontSize: "10px",
                      float: "right",
                      color: item.role === "Admin" ? "red" : "orange",
                      marginBottom: "-10px",
                      marginTop: "12px",
                    }}
                  >
                    {item.role}
                  </p>
                </div>
              </div>
            ) : item.role !== "Admin" || item.role !== "Internal Expert" ? (
              <div class="msg right-msg" key={item.id}>
                <img
                  alt="admin"
                  class="msg-img"
                  src={item.img}
                  style={{ objectFit: "cover" }}
                />

                <div class="msg-bubble" id="rightchat">
                  <div class="msg-info">
                    <div
                      class="msg-info-name"
                      style={{ fontSize: "13px" }}
                    ></div>
                    <div
                      class="msg-info-time"
                      style={{
                        fontSize: "10px",
                        marginTop: "-12px",
                        color: "grey",
                      }}
                    >
                      {item.chattime && formatTimestamp3(item.chattime)}
                    </div>
                  </div>

                  {item.message && item.message.length > 0 ? (
                    <div
                      class="msg-text"
                      style={{ fontSize: "12px", lineHeight: "15px" }}
                    >
                      {item.message}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null
          )}
        </section>
        <footer>
          <Form>
            {userData && (
              <>
                <Form.Input hidden value={userData.name} />
                <Form.Input hidden value={userData.empId} />
                <Form.Input hidden value={userData.img} />
              </>
            )}
          </Form>
          <input
            id="message-input"
            type="text"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            placeholder="Type a message..."
          />

          {message && message.length >= 2 ? (
            <Button color="green" onClick={handleSubmitData} id="sendbtnchat">
              <i class="fa fa-send"></i>
            </Button>
          ) : (
            <>
              <Button color="green" disabled id="sendbtnchat">
                <i class="fa fa-send"></i>
              </Button>
            </>
          )}
        </footer>
      </div>
    </div>
  );
}

export default ChatBox;
