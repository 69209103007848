import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import { db, auth } from "../../Firebase-config";
import AdditionalBtns from "./AdditionalBtns";
import ModalAdd from "../ModalAdd";
import ModalComment from "../ModalComment";
import ModalPayment from "../ModalPayment";
import ModalReopen from "../ModalReopen";
function Delivered() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  // getting Delivered projects from firestore collection and updating the state

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("OrderCode");

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await db
        .collection("Delivered")
        .orderBy("chattime", "desc")
        .get();
      const fetchedData = snapshot.docs.map((doc) => doc.data());
      setData(fetchedData);
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item[searchBy].toLowerCase().includes(searchTerm.toLowerCase())
  );

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  //Coment Box
  //Comment Box

  const [openC, setOpenC] = useState(false);
  const [modalcomment, setModalComment] = useState({});

  const handleComment = (item) => {
    setOpenC(true);
    setModalComment(item);
  };

  const [openRe, setOpenRe] = useState(false);
  const [modalreopen, setModalReopen] = useState({});

  const handleReopen = (item) => {
    setOpenRe(true);
    setModalReopen(item);
  };

  const [openPay, setOpenPay] = useState(false);
  const [modalpay, setModalPay] = useState({});

  const handlePay = (item) => {
    setOpenPay(true);
    setModalPay(item);
  };

  ///TimeFormats
  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp5 = (ndeadline) => {
    const date = new Date(ndeadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp3 = (applied) => {
    const date = applied.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp4 = (delivered) => {
    const date = delivered.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div>
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Project Dashboard</h1>
        </div>

        <hr></hr>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg">
              <Link to="/adminPanel">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Available
                </Button>
              </Link>
              <Link to="/adminPanel_invite">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Invitee
                </Button>
              </Link>

              <Link to="/adminPanel_applied">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Applied
                </Button>
              </Link>

              <Link to="/adminPanel_process">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  In Process
                </Button>
              </Link>

              <Button
                size="small"
                color="facebook"
                style={{
                  margin: "1px",
                  fontSize: "11px",
                }}
              >
                Delivered
              </Button>

              <Link to="/adminPanel_cancelled">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Cancelled
                </Button>
              </Link>

              <Link to="/adminPanel_requests">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Requests
                </Button>
              </Link>

              <Button
                size="small"
                onClick={() => handleAddProject()}
                color="blue"
                style={{ fontSize: "11px", margin: "1px" }}
              >
                Add Project
              </Button>
              {openP && (
                <ModalAdd
                  open={openP}
                  setOpen={setOpenP}
                  // handleDelete={handleDelete}
                  {...modalapplyP}
                />
              )}

              <AdditionalBtns />

              <div className="row" style={{ float: "right" }}>
                <div className="col">
                  <select
                    style={{ height: "4vh" }}
                    className="form-control"
                    value={searchBy}
                    onChange={handleSearchByChange}
                  >
                    <option value="OrderCode">Search by Project Code</option>

                    <option value="subject">Search by Category</option>
                    <option value="name">Search by Name</option>
                  </select>
                </div>
                <div className="col">
                  <div class="ui icon input">
                    <i class="search icon"></i>
                    <input
                      value={searchTerm}
                      onChange={handleSearch}
                      type="text"
                      placeholder="Search..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br></br>

          {/* Applied Projects*/}

          <Table
            responsive
            striped
            bordered
            hover
            size="sm"
            style={{ fontSize: "12px" }}
          >
            <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
              <tr>
                <th scope="col">#</th>

                <th scope="col">Order Code</th>

                <th scope="col">Subject</th>
                <th scope="col">Cost</th>
                <th scope="col" style={{ textAlign: "center" }}>
                  File
                </th>
                <th scope="col">Name</th>
                <th scope="col">Contact</th>
                <th scope="col">PostedOn</th>
                <th scope="col">AppliedOn</th>
                <th scope="col">Project Deadline</th>
                <th scope="col">DeliveredOn</th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Delivered
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Payment
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                <>
                  {filteredData.map((item, index) => (
                    <tr key={item.id}>
                      <th scope="row" style={{ width: "5vh" }}>
                        {index + 1}
                      </th>

                      <td
                        style={{
                          minWidth: "11vh",
                          textTransform: "uppercase",
                        }}
                      >
                        #{item.OrderCode}
                      </td>
                      <td
                        style={{
                          minWidth: "20vh",
                        }}
                      >
                        {item.subject}
                      </td>

                      <td
                        style={{
                          minWidth: "10vh",
                        }}
                      >
                        ₹{item.cost}.00
                      </td>
                      <td
                        style={{
                          minWidth: "5vh",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {item.fileURL && item.fileURL.length > 0 ? (
                          <a
                            href={item.fileURL}
                            target="_blank"
                            style={{ color: "cornflowerblue" }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: "20vh",
                          textTransform: "uppercase",
                        }}
                      >
                        <b>{item.name}</b>
                      </td>
                      <td
                        style={{
                          minWidth: "12vh",
                          textTransform: "uppercase",
                        }}
                      >
                        {item.contact}
                      </td>
                      <td
                        style={{
                          minWidth: "22vh",
                        }}
                      >
                        {item.posted && formatTimestamp(item.posted)}
                      </td>
                      <td style={{ minWidth: "22vh" }}>
                        <b>{item.applied && formatTimestamp3(item.applied)}</b>
                      </td>
                      <td style={{ minWidth: "22vh" }}>
                        {item.ndeadline && item.ndeadline.length > 0 ? (
                          <>
                            <b style={{ color: "black" }}>
                              {item.deadline && formatTimestamp1(item.deadline)}
                            </b>
                          </>
                        ) : (
                          <>
                            <b style={{ color: "red" }}>
                              {item.deadline && formatTimestamp1(item.deadline)}
                            </b>
                          </>
                        )}

                        <br></br>
                        <b style={{ color: "green" }}>
                          {item.ndeadline && formatTimestamp5(item.ndeadline)}
                        </b>
                      </td>

                      <td style={{ color: "green", minWidth: "22vh" }}>
                        <b>
                          {item.delivered && formatTimestamp4(item.delivered)}
                        </b>
                      </td>

                      <td
                        style={{
                          color: "green",
                          minWidth: "7vh",
                          textAlign: "center",
                        }}
                      >
                        {item.deliverURL && item.deliverURL.length > 0 ? (
                          <a
                            href={item.deliverURL}
                            target="_blank"
                            style={{ color: "cornflowerblue" }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>

                      <td
                        style={{
                          minWidth: "8vh",
                          cursor: "pointer",
                          textAlign: "center",
                          textTransform: "uppercase",
                          color:
                            item.paymentStatus === "On Hold"
                              ? "#ff841f"
                              : item.paymentStatus === "Cancelled"
                              ? "#FF4842"
                              : item.paymentStatus === "Paid"
                              ? "#00AB55"
                              : "orange",
                        }}
                      >
                        <b onClick={() => handlePay(item)}>
                          {item.paymentStatus}
                        </b>
                      </td>
                      {openPay && (
                        <ModalPayment
                          open={openPay}
                          setOpen={setOpenPay}
                          // handleDelete={handleDelete}
                          {...modalpay}
                        />
                      )}

                      <td style={{ textAlign: "center" }}>
                        <div class="ui buttons">
                          <Button
                            type="button"
                            onClick={() => handleComment(item)}
                            style={{ marginLeft: "3px", fontSize: "11px" }}
                          >
                            Comment
                          </Button>
                          {openC && (
                            <ModalComment
                              open={openC}
                              setOpen={setOpenC}
                              // handleDelete={handleDelete}
                              {...modalcomment}
                            />
                          )}

                          {item.DeliveredStatus &&
                          item.DeliveredStatus === "ReOpened" ? (
                            <>
                              <Button
                                style={{ fontSize: "11px" }}
                                type="button"
                                color="red"
                                disabled
                              >
                                ReOpen
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                style={{ fontSize: "11px" }}
                                type="button"
                                color="red"
                                onClick={() => handleReopen(item)}
                                // onClick={() => handleApprove(item)}
                              >
                                ReOpen
                              </Button>
                              {openRe && (
                                <ModalReopen
                                  open={openRe}
                                  setOpen={setOpenRe}
                                  // handleDelete={handleDelete}
                                  {...modalreopen}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td
                    colSpan="14"
                    style={{ textAlign: "center", color: "red" }}
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </main>
    </div>
  );
}

export default Delivered;
