import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import Sidebar from "../Sidebar";
import { Table } from "react-bootstrap";
import { Button } from "semantic-ui-react";
import ModalReport from "./ModalReport";
import ModalAddReport from "./ModalAddInvoice";
import ModalDelete from "./ModalDeleteInvoice";
const initialState = {};
const Profiles = () => {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Partners").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);
  const [data, setData] = useState(initialState);

  const {
    name,
    email,
    img,
    designation,
    empId,
    contact,
    about,
    company,
    compCate,
    country,
    address,
    Bank,
    Beneficiary,
    ConfirmAccount,
    Address,
    IFSC,
    UPI,
    IBAN,
    Swift,
    PayPal,
    Skrill,
  } = data;

  const { id } = useParams();

  useEffect(() => {
    id && getSinglePhoto();
  }, [id]);

  const getSinglePhoto = async () => {
    const docRef = doc(db, "Partners", id);
    const snapshot = await getDoc(docRef, "Users");
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const [reports, setReport] = useState([]);
  useEffect(() => {
    const fetchUserData = () => {
      const query = db.collection("PartnersReport").where("uid", "==", id);

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setReport(documents);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setReport([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const [openA, setOpenA] = useState(false);
  const [modalapplyA, setModalApplyA] = useState({});
  const handleAddReport = () => {
    setOpenA(true);
    setModalApplyA();
  };

  const [openDe, setOpenDe] = useState(false);
  const [modaldelete, setModalDelete] = useState({});

  const handleDelete = (item) => {
    setOpenDe(true);
    setModalDelete(item);
  };

  const [openR, setOpenR] = useState(false);
  const [modalapplyR, setModalApplyR] = useState({});
  const handleReport = (user) => {
    setOpenR(true);
    setModalApplyR(user);
  };

  const formatTimestamp = (updateOn) => {
    const date = updateOn.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div>
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Partner Profile</h1>
          <hr style={{ width: "22vh", height: "1px" }}></hr>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10">
              <div className="container-fluid">
                {/* Top Profile */}
                <div
                  className="row"
                  style={{
                    padding: "10px",
                    border: "1px solid #cfcfcf",
                    borderRadius: "5px",
                  }}
                >
                  <div className="col-lg-2" style={{ textAlign: "center" }}>
                    <img
                      src={img}
                      className="rounded-circle"
                      style={{
                        width: "12vh",
                        height: "12vh",
                        objectFit: "cover",
                      }}
                    />
                    <br></br> <br></br>{" "}
                    <p
                      style={{
                        fontSize: "10px",
                        color: "grey",
                        marginTop: "-10px",
                      }}
                    >
                      Partner ID: <br></br>
                      <b style={{ color: "red" }}>{empId}</b>
                    </p>
                  </div>
                  <div
                    className="col-lg-10 my-3"
                    style={{
                      borderLeft: "1px solid grey",
                      paddingLeft: "10px",
                    }}
                  >
                    <h4 style={{ textTransform: "uppercase" }}>
                      <b>{name}</b>
                    </h4>
                    <p
                      style={{
                        fontSize: "10px",
                        marginTop: "-12px",
                        color: "grey",
                      }}
                    >
                      {designation}
                    </p>
                    <p style={{ fontSize: "11px", marginTop: "-5px" }}>
                      {about}
                    </p>

                    <div className="row" style={{ fontSize: "12px" }}>
                      <div className="col-lg-6">
                        <i class="fa fa-phone"></i> {contact}
                      </div>
                      <div className="col-lg-6">
                        <i class="fa fa-envelope" aria-hidden="true"></i>{" "}
                        {email}
                      </div>

                      <div className="col-lg-6">
                        <i class="fa fa-building" aria-hidden="true"></i>{" "}
                        {company}
                      </div>

                      <div className="col-lg-6">
                        <i class="fa fa-building-o" aria-hidden="true"></i>{" "}
                        {compCate}
                      </div>

                      <div className="col-lg-6">
                        <i class="fa fa-globe" aria-hidden="true"></i> {country}
                      </div>
                      <div className="col-lg-6">
                        <i class="fa fa-address-card" aria-hidden="true"></i>{" "}
                        {address}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Top Profile */}

                {/* Bank Details */}
                <div className="my-4">
                  <div className="row">
                    <div className="col-lg">
                      <div
                        className="container-fluid"
                        style={{ border: "1px solid #cfcfcf", padding: "10px" }}
                      >
                        <h5>Bank Details</h5>
                        <hr></hr>
                        <div className="row">
                          <div className="col-4">Bank Name: </div>
                          <div className="col-8">{Bank}</div>
                          <br></br> <br></br>
                          <div className="col-4">Benificiary Name: </div>
                          <div className="col-8">{Beneficiary}</div>
                          <br></br> <br></br>
                          <div className="col-4">Account No.: </div>
                          <div className="col-8">{ConfirmAccount}</div>
                          <br></br> <br></br>
                          <div className="col-4">IFSC Code: </div>
                          <div className="col-8">{IFSC}</div>
                          <br></br> <br></br>
                          <div className="col-4">UPI Id: </div>
                          <div className="col-8">{UPI}</div>
                          <br></br> <br></br>
                          <div className="col-4">Address: </div>
                          <div className="col-8">{Address}</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg">
                      <div
                        className="container-fluid"
                        style={{ border: "1px solid #cfcfcf", padding: "10px" }}
                      >
                        <h5>International Bank Details</h5>
                        <hr></hr>
                        <div className="row">
                          <div className="col-4">IBAN: </div>
                          <div className="col-8">{IBAN}</div>
                          <br></br> <br></br>
                          <div className="col-4">Swift Code: </div>
                          <div className="col-8">{Swift}</div>
                          <br></br> <br></br>
                          <div className="col-4">PayPal ID: </div>
                          <div className="col-8">{PayPal}</div>
                          <br></br> <br></br>
                          <div className="col-4">Skrill ID: </div>
                          <div className="col-8">{Skrill}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Bank Details */}
              </div>
            </div>
            {/* <div className="col-lg-4"></div> */}
          </div>

          <div className="container-fluid my-3">
            <div className="pagetitle">
              <h1>Partner Report</h1>
            </div>
            <Button
              onClick={() => handleAddReport()}
              size="tiny"
              color="orange"
            >
              Generate Invoice
            </Button>
            {openA && (
              <ModalAddReport
                open={openA}
                setOpen={setOpenA}
                // handleDelete={handleDelete}
                {...modalapplyA}
              />
            )}
            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "12px", marginTop: "2px" }}
            >
              <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Invoice</th>
                  <th scope="col">Purpose</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Updated On</th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    FXCTS Agreement
                  </th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    Partner's Agreement
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Upload
                  </th>
                </tr>
              </thead>

              <tbody>
                {reports.length > 0 ? (
                  reports.map((user, index) => (
                    <tr key={user.id}>
                      <td style={{ width: "5vh" }}>{index + 1}</td>
                      <td style={{ minWidth: "13vh" }}>#{user.invoice}</td>
                      <td
                        style={{
                          minWidth: "20vh",
                        }}
                      >
                        {user.purpose}
                      </td>

                      <td style={{ minWidth: "12vh", fontWeight: "bold" }}>
                        ₹ {user.amount}.00
                      </td>

                      <td
                        style={{
                          minWidth: "20vh",
                          color: "green",
                        }}
                      >
                        <b>{user.updateOn && formatTimestamp(user.updateOn)}</b>
                      </td>

                      <td
                        style={{
                          color: "green",
                          minWidth: "10vh",
                          textAlign: "center",
                        }}
                      >
                        {user.adminInvoice && user.adminInvoice.length > 0 ? (
                          <a
                            href={user.adminInvoice}
                            target="_blank"
                            style={{ color: "cornflowerblue" }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: "10vh",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {user.Invoice && user.Invoice.length > 0 ? (
                          <a
                            href={user.Invoice}
                            target="_blank"
                            style={{
                              color: "cornflowerblue",
                              marginRight: "10px",
                            }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red", marginRight: "10px" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>

                      <td style={{ textAlign: "center" }}>
                        <i
                          style={{ cursor: "pointer", color: "green" }}
                          onClick={() => handleReport(user)}
                          className="fa fa-upload"
                        ></i>
                        {openR && (
                          <ModalReport
                            open={openR}
                            setOpen={setOpenR}
                            // handleDelete={handleDelete}
                            {...modalapplyR}
                          />
                        )}

                        <i
                          style={{
                            cursor: "pointer",
                            color: "red",
                            marginLeft: "10px",
                          }}
                          onClick={() => handleDelete(user)}
                          className="fa fa-trash"
                        ></i>
                        {openDe && (
                          <ModalDelete
                            open={openDe}
                            setOpen={setOpenDe}
                            // handleDelete={handleDelete}
                            {...modaldelete}
                          />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      No invoice found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Profiles;
