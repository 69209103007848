import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import { db, auth } from "../../Firebase-config";
import ModalDelete from "../ModalDelete";
import AdditionalBtns from "./AdditionalBtns";
import ModalFile from "../ModalFile";
import { onSnapshot } from "firebase/firestore";
import ModalEdit from "../ModalEdit";
import ModalAdd from "../ModalAdd";
import ModalComment from "../ModalComment";
function Cancelled() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [cancelledP, setCancelled] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const [openE, setOpenE] = useState(false);
  const [modaledit, setModalEdit] = useState({});

  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("Cancelled").orderBy("cancelled", "desc"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setCancelled(list);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  ///Delete Modal
  const [openDe, setOpenDe] = useState(false);
  const [modaldelete, setModalDelete] = useState({});

  const handleDelete = (item) => {
    setOpenDe(true);
    setModalDelete(item);
  };

  const handleEdit = (item) => {
    setOpenE(true);
    setModalEdit(item);
  };

  //Add Project Modal
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  //Coment Box
  //Comment Box

  const [openC, setOpenC] = useState(false);
  const [modalcomment, setModalComment] = useState({});

  const handleComment = (item) => {
    setOpenC(true);
    setModalComment(item);
  };

  ///TimeFormats
  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp2 = (applied) => {
    const date = applied.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp4 = (cancelled) => {
    const date = cancelled.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div>
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Project Dashboard</h1>
        </div>

        <hr></hr>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg">
              <Link to="/adminPanel">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Available
                </Button>
              </Link>
              <Link to="/adminPanel_invite">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Invitee
                </Button>
              </Link>

              <Link to="/adminPanel_applied">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Applied
                </Button>
              </Link>

              <Link to="/adminPanel_process">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  In Process
                </Button>
              </Link>

              <Link to="/adminPanel_delivered">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Delivered
                </Button>
              </Link>

              <Button
                color="facebook"
                size="small"
                style={{
                  fontSize: "11px",
                  margin: "1px",
                }}
              >
                Cancelled
              </Button>

              <Link to="/adminPanel_requests">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Requests
                </Button>
              </Link>

              <Button
                size="small"
                onClick={() => handleAddProject()}
                color="blue"
                style={{ fontSize: "11px", margin: "1px" }}
              >
                Add Project
              </Button>
              {openP && (
                <ModalAdd
                  open={openP}
                  setOpen={setOpenP}
                  // handleDelete={handleDelete}
                  {...modalapplyP}
                />
              )}

              <AdditionalBtns />
            </div>
          </div>

          <br></br>

          {/* Available Projects*/}

          <Table
            responsive
            striped
            bordered
            hover
            size="sm"
            style={{ fontSize: "12px" }}
          >
            <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Project Code</th>
                <th scope="col">Project Title</th>
                <th scope="col">Project Category</th>

                <th scope="col">Project Cost</th>
                <th scope="col" style={{ textAlign: "center" }}>
                  File
                </th>
                <th scope="col">Project Deadline</th>

                <th scope="col">PostedOn</th>
                <th scope="col">AppliedOn</th>
                <th scope="col">CancelledOn</th>
              </tr>
            </thead>
            <tbody>
              {cancelledP.length > 0 ? (
                <>
                  {cancelledP.map((cancel, index) => (
                    <tr key={cancel.id}>
                      <td>{index + 1}</td>
                      <td style={{ minWidth: "11vh" }}>#{cancel.OrderCode}</td>
                      <td style={{ minWidth: "15vh" }}>{cancel.title}</td>
                      <td style={{ minWidth: "13vh" }}>{cancel.subject}</td>

                      <td style={{ minWidth: "11vh" }}>₹ {cancel.cost}.00</td>
                      <td
                        style={{
                          minWidth: "5vh",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {cancel.fileURL && cancel.fileURL.length > 0 ? (
                          <a
                            href={cancel.fileURL}
                            target="_blank"
                            style={{ color: "cornflowerblue" }}
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        ) : (
                          <>
                            <i
                              class="fa fa-times"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        )}
                      </td>
                      <td style={{ minWidth: "20vh" }}>
                        <b>
                          {cancel.deadline && formatTimestamp1(cancel.deadline)}
                        </b>
                      </td>

                      <td style={{ minWidth: "20vh" }}>
                        {cancel.posted && formatTimestamp(cancel.posted)}
                      </td>

                      <td style={{ color: "green", minWidth: "20vh" }}>
                        <b>
                          {cancel.applied && formatTimestamp2(cancel.applied)}
                        </b>
                      </td>

                      <td style={{ color: "red", minWidth: "20vh" }}>
                        <b>
                          {cancel.cancelled &&
                            formatTimestamp4(cancel.cancelled)}
                        </b>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td
                    colSpan="10"
                    style={{ textAlign: "center", color: "red" }}
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </main>
    </div>
  );
}

export default Cancelled;
