import React, { useState, useEffect } from "react";
import { db, auth } from "../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { serverTimestamp } from "firebase/firestore";
import { useParams } from "react-router-dom";
const ModalAddInvoice = ({ open, setOpen }) => {
  const [invoice, setInvoice] = useState("");
  const [purpose, setPurpose] = useState("");
  const [amount, setAmount] = useState("");
  const [loader, setLoader] = useState("");
  const [success, setsuccessLoader] = useState("");
  const [userData, setUserData] = useState(null);
  const { id } = useParams();
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const handleSubmit = async () => {
    setLoader("Adding Invoice ! Please wait...");
    setsuccessLoader("");

    try {
      db.collection("Reports").add({
        invoice,
        purpose,
        amount,

        uid: id,
        updateOn: serverTimestamp(),
      });

      // Reset form fields and close the modal
      // Reset form fields and close the modal
      setLoader("");
      setsuccessLoader("Project added successfully!");
      setInvoice("");
      setPurpose("");
      setAmount("");

      setTimeout(() => {
        setLoader("");
        setsuccessLoader("");
        setOpen(false);
      }, 2000);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "#3E6399", color: "white" }}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Generate New Invoice
            </h5>
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#3E6399",
                border: "1px solid White",
              }}
              onClick={() => setOpen(false)}
              data-bs-dismiss="modal"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>
              <Form>
                {loader && (
                  <Alert
                    variant="warning"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {loader}
                  </Alert>
                )}
                {success && (
                  <Alert
                    variant="success"
                    style={{ fontSize: "11px", textAlign: "center" }}
                  >
                    {success}
                  </Alert>
                )}

                <Form.Input
                  placeholder="Invoice Purpose"
                  label="Invoice Purpose"
                  required
                  onChange={(e) => setPurpose(e.target.value)}
                  value={purpose}
                />
                <div className="row">
                  <div className="col-lg">
                    <br></br>
                    <Form.Input
                      placeholder="Invoice"
                      label="Invoice"
                      required
                      onChange={(e) => setInvoice(e.target.value)}
                      value={invoice}
                    />
                  </div>
                  <div className="col-lg ">
                    <br></br>
                    <Form.Input
                      placeholder="Amount"
                      label="Amount"
                      type="number"
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button size="small" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              color="facebook"
              ize="small"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddInvoice;

{
  /* <Button color="grey" onClick={() => setOpen(false)}>
Cancel
</Button> */
}
