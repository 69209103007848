import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import { db, auth } from "../../Firebase-config";

import AdditionalBtns from "./AdditionalBtns";
import ModalDelete from "./ModalDelete";
import ModalAdd from "./ModalInvite";
import ModalComment from "../ModalComment";
import ModalTask from "./ModalTask";
function Invite() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Users").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Add Project Modal
  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const [openD, setOpenD] = useState(false);
  const [modaldelete, setModalDelete] = useState({});

  const handleDelete = (item) => {
    setOpenD(true);
    setModalDelete(item);
  };

  const [openT, setOpenT] = useState(false);
  const [modaltask, setModalTask] = useState({});

  const handleTask = (item) => {
    setOpenT(true);
    setModalTask(item);
  };

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("OrderCode");

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await db
        .collection("Invites")
        .orderBy("posted", "desc")
        .get();
      const fetchedData = snapshot.docs.map((doc) => doc.data());
      setData(fetchedData);
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item[searchBy].toLowerCase().includes(searchTerm.toLowerCase())
  );

  //Add Project Modal
  const handleAddProject = () => {
    setOpenP(true);
    setModalApplyP();
  };

  //Coment Box
  //Comment Box

  const [openC, setOpenC] = useState(false);
  const [modalcomment, setModalComment] = useState({});

  const handleComment = (item) => {
    setOpenC(true);
    setModalComment(item);
  };

  ///TimeFormats
  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (deadline) => {
    const date = new Date(deadline);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div>
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Project Dashboard</h1>
        </div>

        <hr></hr>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg">
              <Link to="/adminPanel">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Available
                </Button>
              </Link>
              <Button
                color="facebook"
                size="small"
                style={{
                  margin: "1px",
                  fontSize: "11px",
                }}
              >
                Invitee
              </Button>

              <Link to="/adminPanel_applied">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Applied
                </Button>
              </Link>

              <Link to="/adminPanel_process">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  In Process
                </Button>
              </Link>

              <Link to="/adminPanel_delivered">
                <Button
                  size="small"
                  style={{
                    margin: "1px",
                    fontSize: "11px",
                  }}
                >
                  Delivered
                </Button>
              </Link>

              <Link to="/adminPanel_cancelled">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Cancelled
                </Button>
              </Link>

              <Link to="/adminPanel_requests">
                <Button
                  size="small"
                  style={{
                    fontSize: "11px",
                    margin: "1px",
                  }}
                >
                  Requests
                </Button>
              </Link>

              <AdditionalBtns />

              <div className="row" style={{ float: "right" }}>
                <div className="col">
                  <select
                    style={{ height: "4vh" }}
                    className="form-control"
                    value={searchBy}
                    onChange={handleSearchByChange}
                  >
                    <option value="OrderCode">Search by Project Code</option>
                    <option value="subject">Search by Category</option>
                    <option value="fname">Search by Freelancer</option>
                    <option value="empId">Search by Freelancer ID</option>
                  </select>
                </div>
                <div className="col">
                  <div class="ui icon input">
                    <i class="search icon"></i>
                    <input
                      value={searchTerm}
                      onChange={handleSearch}
                      type="text"
                      placeholder="Search..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br></br>

          {/* Available Projects*/}

          <Table
            responsive
            striped
            bordered
            hover
            size="sm"
            style={{ fontSize: "12px" }}
          >
            <thead style={{ backgroundColor: "#3E6399", color: "white" }}>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Order Code</th>
                <th scope="col">Project Title</th>
                <th scope="col">Category</th>

                <th scope="col">Posted On</th>
                <th scope="col">Project Deadline</th>
                <th scope="col">Project Cost</th>
                <th scope="col" style={{ textAlign: "center" }}>
                  File
                </th>
                <th scope="col">Name/EmpIds</th>

                <th scope="col" style={{ textAlign: "center" }}>
                  Status
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                <>
                  {filteredData.map((item, index) => (
                    <tr key={item.id}>
                      <th scope="row" style={{ width: "5vh" }}>
                        {index + 1}
                      </th>
                      <td
                        style={{
                          minWidth: "11vh",
                          textTransform: "uppercase",
                        }}
                      >
                        #{item.OrderCode}
                      </td>
                      <td style={{ minWidth: "13vh" }}>{item.title}</td>
                      <td style={{ minWidth: "13vh" }}>
                        {item.subject.length <= 0 ? (
                          <>
                            <p
                              onClick={() => handleTask(item)}
                              style={{
                                color: "cornflowerblue",
                                cursor: "pointer",
                              }}
                            >
                              <i class="fa fa-eye"></i> Read Details
                            </p>
                            {openT && (
                              <ModalTask
                                open={openT}
                                setOpen={setOpenT}
                                // handleDelete={handleDelete}
                                {...modaltask}
                              />
                            )}
                          </>
                        ) : (
                          <>{item.subject}</>
                        )}
                      </td>

                      <td style={{ minWidth: "21vh" }}>
                        {item.posted && formatTimestamp(item.posted)}
                      </td>
                      <td style={{ minWidth: "21vh", color: "red" }}>
                        <b>
                          {item.deadline && formatTimestamp1(item.deadline)}
                        </b>
                      </td>
                      <td style={{ minWidth: "12vh" }}>
                        {item.cost && item.cost.length > 1 ? (
                          <>₹ {item.cost}.00</>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: "5vh",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        <a
                          href={item.fileURL}
                          target="_blank"
                          style={{ color: "cornflowerblue" }}
                        >
                          <i class="fa fa-download"></i>
                        </a>
                      </td>

                      <td style={{ minWidth: "13vh" }}>
                        {item.fname} [{item.empId}]
                      </td>

                      <td
                        style={{
                          minWidth: "10vh",
                          textAlign: "center",
                          color: item.applied === "Applied" ? "green" : "red",
                        }}
                      >
                        <b>{item.applied}</b>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <div class="ui buttons">
                          <Button
                            type="button"
                            onClick={() => handleComment(item)}
                            style={{ marginLeft: "3px", fontSize: "11px" }}
                          >
                            Comment
                          </Button>
                          {openC && (
                            <ModalComment
                              open={openC}
                              setOpen={setOpenC}
                              // handleDelete={handleDelete}
                              {...modalcomment}
                            />
                          )}

                          <Button
                            type="button"
                            color="red"
                            onClick={() => handleDelete(item)}
                            style={{ marginLeft: "3px", fontSize: "11px" }}
                          >
                            <i class="fa fa-trash"></i>
                          </Button>
                          {openD && (
                            <ModalDelete
                              open={openD}
                              setOpen={setOpenD}
                              // handleDelete={handleDelete}
                              {...modaldelete}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td
                    colSpan="10"
                    style={{ textAlign: "center", color: "red" }}
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="text-center">
            <Button
              size="small"
              onClick={() => handleAddProject()}
              color="blue"
            >
              Invite Freelancer
            </Button>
            {openP && (
              <ModalAdd
                open={openP}
                setOpen={setOpenP}
                // handleDelete={handleDelete}
                {...modalapplyP}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Invite;
