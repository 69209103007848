import { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./Authentication/Login";
import PartnerLogin from "./Authentication/PartnerLogin";
import Register from "./Authentication/Register";
import Home from "./Components/Home";
import HomeAdmin from "./Admin/Home";
import ForgotPassword from "./Authentication/ForgotPassword";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import ProtectedRoute from "./Authentication/ProtectedRoute";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import Navbar from "./Authentication/Navbar";
import AdminNavbar from "./Admin/Navbar";

import Profile from "./Components/Profile";
import AdminProfile from "./Admin/Profile";
import DeliverProject from "./Components/DeliverProject";
import DeliveredProject from "./Admin/DeliverProject";
import Wallet from "./Components/Wallet";
import PartnerDashboard from "./Partners/PartnerDashboard";
import PartnerNavbar from "./Partners/PartnerNavbar";
import AddProjects from "./Authentication/AddProjects";
import Profiles from "./Admin/Profiles";
import Employees from "./Admin/Employees";
import PProfiles from "./Admin/Partners/Profiles";

import Partners from "./Admin/Partners/Partners";
import AddProjectsClients from "./Authentication/AddProjects";
import PartnerHome from "./Partners/PartnerHome";
import PartnerWallet from "./Partners/Wallet";
//Pages
import Available from "./Components/Pages/Available";
import Applied from "./Components/Pages/Applied";
import Process from "./Components/Pages/Process";
import Cancelled from "./Components/Pages/Cancelled";
import Delivered from "./Components/Pages/Delivered";
import Invite from "./Components/Pages/Invite";
//Admin
import AdminAvailable from "./Admin/Pages/Available";
import AdminApplied from "./Admin/Pages/Applied";
import AdminInvite from "./Admin/Pages/Invite.js";
import AdminProcess from "./Admin/Pages/Proccess";
import AdminRequests from "./Admin/Pages/Requests";
import AdminDelivered from "./Admin/Pages/Delivered";
import AdminCancelled from "./Admin/Pages/Cancelled";
import AddPartner from "./Authentication/AddPartner";
import PProjects from "./Admin/Partners/PartnerDashboard";
import Chats from "./Admin/Pages/Chats";
import Reports from "./Components/Pages/Reports";
import ReportsDetails from "./Admin/Pages/Reports";
import NeedHelp from "./Components/NeedHelp";
import PartnerHelp from "./Partners/NeedHelp";
import PartnerProfile from "./Partners/Profile";
import PartnerReport from "./Partners/Reports";
import PartnerAdminHelp from "./Admin/Pages/PartnerHelp";
import Help from "./Admin/Pages/Help";

import PartnerRequests from "./Partners/Requests";
import PartnerAdminInvite from "./Admin/Partners/Invite.js";
function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isPartner, setIsPartner] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const firestore = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(firestore, "Users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();

        // Check if user has the "admin" role
        if (
          userData ||
          userData.role === "Admin" ||
          userData.role === "Internal Expert"
        ) {
          setIsAdmin(true);
          setIsMember(false);
        } else {
          setIsAdmin(false);
          setIsMember(true);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const firestore = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(firestore, "Partners", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();

        // Check if user has the "admin" role
        if (userData && userData.role === "Partner") {
          setIsPartner(true);
          setIsMember(false);
          setIsAdmin(false);
        } else {
          setIsAdmin(true);
          setIsPartner(false);
          setIsMember(true);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className="admin">
        <UserAuthContextProvider>
          <Routes>
            <Route
              path="/add_projects_clients"
              element={
                <>
                  <AddProjectsClients />
                </>
              }
            />

            <Route
              path="/partner_registration"
              element={
                <>
                  <AddPartner />
                </>
              }
            />

            <Route
              path="/register_auth"
              element={
                <>
                  <Register />
                </>
              }
            />
            <Route
              path="/"
              element={
                <>
                  <Login />
                </>
              }
            />

            <Route
              path="/partnerLogin"
              element={
                <>
                  <PartnerLogin />
                </>
              }
            />

            <Route
              path="/fpassword_auth"
              element={
                <>
                  <ForgotPassword />
                </>
              }
            />

            {/* <Route
              path="/projects"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <Orders />
                  </ProtectedRoute>
                </>
              }
            /> */}

            <Route
              path="/wallet"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <Wallet />
                  </ProtectedRoute>
                </>
              }
            />

            <Route
              path="/projects"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <Available />
                  </ProtectedRoute>
                </>
              }
            />

            <Route
              path="/needSupport_"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <NeedHelp />
                  </ProtectedRoute>
                </>
              }
            />

            <Route
              path="/applied_"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <Applied />
                  </ProtectedRoute>
                </>
              }
            />

            <Route
              path="/invite_"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <Invite />
                  </ProtectedRoute>
                </>
              }
            />

            <Route
              path="/in_process_"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <Process />
                  </ProtectedRoute>
                </>
              }
            />

            <Route
              path="/delivered_"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <Delivered />
                  </ProtectedRoute>
                </>
              }
            />

            <Route
              path="/cancelled_"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <Cancelled />
                  </ProtectedRoute>
                </>
              }
            />

            <Route
              path="/reports_"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <Reports />
                  </ProtectedRoute>
                </>
              }
            />

            <Route
              path="/home"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <Home />
                  </ProtectedRoute>
                </>
              }
            />
            <Route
              path="/deliver_project/:id"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <DeliverProject />
                  </ProtectedRoute>
                </>
              }
            />

            <Route
              path="/my_profile"
              element={
                <>
                  <ProtectedRoute>
                    <Navbar />
                    <Profile />
                  </ProtectedRoute>
                </>
              }
            />

            {isAdmin && (
              <>
                <Route
                  path="/profile"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <AdminProfile />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/help_support"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <Help />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/partner_support"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <PartnerAdminHelp />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/reports_details_"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <ReportsDetails />
                      </ProtectedRoute>
                    </>
                  }
                />
                <Route
                  path="/adminPanel"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <AdminAvailable />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/adminPanel_chats"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <Chats />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/adminPanel_applied"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <AdminApplied />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/adminPanel_invite"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <AdminInvite />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/partner_invite"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <PartnerAdminInvite />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/adminPanel_process"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <AdminProcess />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/adminPanel_requests"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <AdminRequests />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/adminPanel_cancelled"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <AdminCancelled />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/adminPanel_delivered"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <AdminDelivered />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/delivered_project/:id"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <DeliveredProject />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/view_profile_details/:id"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <Profiles />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/partnerDetails_details/:id"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <PProfiles />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/partner_projects"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <PProjects />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/home_admin"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <HomeAdmin />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/employee_list"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <Employees />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/partners_list"
                  element={
                    <>
                      <ProtectedRoute>
                        <AdminNavbar />
                        <Partners />
                      </ProtectedRoute>
                    </>
                  }
                />
              </>
            )}

            {isPartner && (
              <>
                <Route
                  path="/partnerDashboard"
                  element={
                    <>
                      <ProtectedRoute>
                        <PartnerNavbar />
                        <PartnerDashboard />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/partner_profile_update"
                  element={
                    <>
                      <ProtectedRoute>
                        <PartnerNavbar />
                        <PartnerProfile />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/partner_requests"
                  element={
                    <>
                      <ProtectedRoute>
                        <PartnerNavbar />
                        <PartnerRequests />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/partner_report"
                  element={
                    <>
                      <ProtectedRoute>
                        <PartnerNavbar />
                        <PartnerReport />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/help_support_p"
                  element={
                    <>
                      <ProtectedRoute>
                        <PartnerNavbar />
                        <PartnerHelp />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/partner_home"
                  element={
                    <>
                      <ProtectedRoute>
                        <PartnerNavbar />
                        <PartnerHome />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/partner_wallet"
                  element={
                    <>
                      <ProtectedRoute>
                        <PartnerNavbar />
                        <PartnerWallet />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/add_project"
                  element={
                    <>
                      <ProtectedRoute>
                        <PartnerNavbar />
                        <AddProjects />
                      </ProtectedRoute>
                    </>
                  }
                />

                <Route
                  path="/edit_project/:id"
                  element={
                    <>
                      <ProtectedRoute>
                        <PartnerNavbar />
                        <AddProjects />
                      </ProtectedRoute>
                    </>
                  }
                />
              </>
            )}
          </Routes>
        </UserAuthContextProvider>
      </div>
    </>
  );
}

export default App;
