import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/partner_home">
              <i class="fa fa-home" aria-hidden="true"></i>
              <span>Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/partnerDashboard">
              <i class="fa fa-briefcase" aria-hidden="true"></i>
              <span>Projects</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/partner_report">
              <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
              <span>Reports</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
          </li>

          <li
            style={{ bottom: "20px", position: "absolute", fontSize: "12.5px" }}
          >
            <span style={{ color: "grey", fontSize: "11px" }}>VERSION:</span>{" "}
            <b>FXCTS-ADS-v1.02.00</b>
            <br></br>
            <span style={{ fontSize: "11px" }}>
              Copyright <i class="fa fa-copyright" aria-hidden="true"></i> FXCTS
              2023
            </span>
          </li>

          {/* <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#tables-nav"
              data-bs-toggle="collapse"
              href="/"
            >
              <i className="bi bi-layout-text-window-reverse"></i>
              <span>Reports</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="tables-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <a href="tables-general.html">
                  <i className="bi bi-circle"></i>
                  <span>General Tables</span>
                </a>
              </li>
              <li>
                <a href="tables-data.html">
                  <i className="bi bi-circle"></i>
                  <span>Data Tables</span>
                </a>
              </li>
            </ul>
          </li> */}
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
